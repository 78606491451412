import React, { useState } from 'react';

import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';

import { resetServiceFees } from '~/services/BusinessTraveller/BusinessTravellerService';

interface Props {
  businessId: string;
  serviceFeeCount: number;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export default function BusinessServiceFeesCreateEditModal({
  businessId,
  isOpen,
  onClose,
  onSuccess,
  serviceFeeCount,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  async function handleResetClick() {
    setIsLoading(true);

    try {
      await resetServiceFees(businessId);

      enqueueSnackbar(`Service fees reset successfully`, {
        variant: 'success',
      });
      onSuccess();
      onClose();
    } catch (error) {
      const message = error instanceof Error ? error.message : JSON.stringify(error, null, 2);
      enqueueSnackbar(`Error resetting service fee [${message}]`, {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Dialog open={isOpen} onClose={isLoading ? undefined : onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{serviceFeeCount > 0 ? 'Reset To Default' : 'Add Default Fees'}</DialogTitle>
      <DialogContent>
        {serviceFeeCount === 0 && (
          <Stack gap={2} alignItems="start">
            <Typography variant="body2" gutterBottom>
              Do you want to add default service fees?
            </Typography>
          </Stack>
        )}
        {serviceFeeCount > 0 && (
          <Stack gap={2} alignItems="start">
            <Typography variant="body2" gutterBottom>
              Do you want to reset to the default service fees?
            </Typography>
            <Typography variant="body2">
              This will delete all existing service fees and create new ones with default values.
            </Typography>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} variant="text" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton onClick={handleResetClick} loading={isLoading} variant="contained" color="primary">
          {serviceFeeCount > 0 ? 'Reset' : 'Add'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
