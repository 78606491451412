import React from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { Box, Card, CardContent, Grid, Typography, useTheme } from '@mui/material';

import { Rate } from '~/services/AccommodationService';

interface RateInclusionsModalProps {
  inclusions: Rate['inclusions'];
}

export default function RateInclusionsModal({ inclusions }: RateInclusionsModalProps) {
  const theme = useTheme();

  if (!inclusions || inclusions.length === 0) {
    return <Typography>No inclusions available for this rate.</Typography>;
  }

  return (
    <Box>
      <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
        Included in this rate:
      </Typography>
      <Grid container spacing={2}>
        {inclusions.map((inclusion, index: number) => (
          <Grid item xs={12} key={index}>
            <Card variant="outlined" sx={{ mb: 1 }}>
              <CardContent sx={{ bgcolor: theme.palette.success.light, py: 1 }}>
                <Typography variant="body1">
                  <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                    <InfoIcon sx={{ mr: 1, color: theme.palette.success.main }} />
                    {inclusion.text}
                  </Box>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
