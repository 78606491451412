import React, { useState } from 'react';

import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';

import { Body } from '~/components/Experiences/Home/Content/styles';
import { Button, Text } from '~/components/Experiences/components';
import { useUpdateExperienceValues } from '~/components/Experiences/hooks';
import useBrands from '~/components/Experiences/hooks/useBrands';
import UserReviewsTable from '~/components/Reviews/UserReviewsTable';

import { ORIGINAL_EXPERIENCE_CONTENT_TAB, themes } from '~/consts/experiences';

import {
  CategoryItem,
  CurateExperienceParams,
  CurationFeatureManager,
  ExperienceOffer,
} from '~/services/ExperiencesService';

import {
  ExperienceCurationAdditionalInformation,
  ExperienceCurationCategories,
  ExperienceCurationFeatures,
  ExperienceCurationOptions,
  ExperienceCurationTickets,
} from './ExperienceCuration';
import ExperienceCurationBookingFields from './ExperienceCuration/ExperienceCurationBookingFields';
import ExperienceCurationTargetAudiences from './ExperienceCuration/ExperienceCurationTargetAudiences';

interface Props {
  experience: ExperienceOffer;
  creatingExperience: boolean;
  tenant: App.Tenant;
  createExperience: ({ curationStatus }: CurateExperienceParams) => Promise<void>;
  categories: CategoryItem[];
  curationFeatures?: CurationFeatureManager;
}

export const ExperienceItemContent: React.FC<Props> = (props) => {
  const { experience, creatingExperience, createExperience, tenant, categories, curationFeatures } = props;
  const [tab, setTab] = useState('experience');
  const { updateValues, values, contentTab, payload } = useUpdateExperienceValues();
  const { data: allBrands, loading, error } = useBrands();

  const areOriginalValues = contentTab === ORIGINAL_EXPERIENCE_CONTENT_TAB;

  const hasImage = !!experience?.images.length || !!payload?.imagesToUpload?.length;

  return (
    <div>
      <Tabs sx={{ mb: 2 }} value={tab} onChange={(_, value) => setTab(value)} id="experience-tabs">
        <Tab value="experience" label="Experience" />
        <Tab value="deal-options" label="Deal Options" />
        <Tab value="additional-information" label="Additional Information" />
        <Tab value="categories" label="Categories" />
        <Tab value="curation-options" label="Curation options" />
        <Tab value="user-reviews" label="Reviews" />
      </Tabs>

      <Box mt={4}>
        {tab === 'experience' && (
          <ExperienceCurationOptions
            key={experience?.id}
            createExperience={createExperience}
            loading={creatingExperience}
            experience={experience}
            tenant={tenant}
          />
        )}

        {tab === 'additional-information' && (
          <>
            <Box my={2}>
              <Button
                variant="contained"
                title="Save"
                disabled={!hasImage || areOriginalValues}
                loading={creatingExperience}
                onClick={() =>
                  createExperience({
                    curationStatus: values?.curationStatus,
                  })
                }
              />
            </Box>
            <ExperienceCurationAdditionalInformation />
          </>
        )}

        {tab === 'curation-options' && (
          <>
            <Box my={2}>
              <Button
                variant="contained"
                title="Save"
                loading={creatingExperience}
                disabled={!hasImage || areOriginalValues}
                onClick={() =>
                  createExperience({
                    curationStatus: values?.curationStatus,
                  })
                }
              />
            </Box>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.showPostPurchase ?? false}
                    onChange={(e) => updateValues({ showPostPurchase: e.target.checked })}
                    disabled={areOriginalValues}
                  />
                }
                label="Show Post Purchase"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.isFlash ?? false}
                    onChange={(e) => updateValues({ isFlash: e.target.checked })}
                    disabled={areOriginalValues}
                  />
                }
                label="Flash Deal"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.searchEngineMarketingEnabled ?? false}
                    onChange={(e) => {
                      return updateValues({
                        searchEngineMarketingEnabled: e.target.checked,
                      });
                    }}
                    disabled={areOriginalValues}
                  />
                }
                label="Enable Search Engine Marketing"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.unlisted ?? false}
                    onChange={(e) => updateValues({ unlisted: e.target.checked })}
                    disabled={areOriginalValues}
                  />
                }
                label="Show this experience only during inflow (not search) if it has been curated with a specific hotel"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.showInJourneys ?? false}
                    onChange={(e) => updateValues({ showInJourneys: e.target.checked })}
                    disabled={areOriginalValues}
                  />
                }
                label="Show in journeys"
              />
            </FormGroup>

            {curationFeatures?.idSalesforceExternal?.viewIdSalesforceExternal && (
              <Box my={2}>
                <TextField
                  disabled={areOriginalValues || !curationFeatures.idSalesforceExternal.editIdSalesforceExternal}
                  label="Salesforce External ID"
                  variant="outlined"
                  value={values?.idSalesforceExternal || ''}
                  onChange={(e) =>
                    updateValues({
                      idSalesforceExternal: e.target.value !== '' ? e.target.value : null,
                    })
                  }
                  fullWidth
                />
              </Box>
            )}

            {curationFeatures?.brands?.viewBrands && values?.brands && allBrands && (
              <Box my={2}>
                Available in brands:
                <FormGroup row>
                  {allBrands.map((brand) => (
                    <FormControlLabel
                      key={brand.id}
                      control={
                        <Checkbox
                          checked={values.brands.includes(brand.id)}
                          onChange={() =>
                            updateValues({
                              brands: values.brands.includes(brand.id)
                                ? values.brands.filter((selectedBrand) => selectedBrand !== brand.id)
                                : values.brands.concat(brand.id),
                            })
                          }
                          disabled={areOriginalValues || !curationFeatures?.brands?.editBrands}
                        />
                      }
                      label={brand.name}
                    />
                  ))}
                </FormGroup>
              </Box>
            )}
            {loading && <CircularProgress />}
            {error && <Text>{error.message}</Text>}
          </>
        )}

        {tab === 'deal-options' && (
          <>
            <Box my={2}>
              <Button
                variant="contained"
                title="Save"
                loading={creatingExperience}
                disabled={!hasImage || areOriginalValues}
                onClick={() =>
                  createExperience({
                    curationStatus: values?.curationStatus,
                  })
                }
              />
            </Box>

            <Box mt={2}>
              <Box>
                <Accordion variant="outlined">
                  <AccordionSummary expandIcon={<ExpandMore />}>DEAL OPTIONS</AccordionSummary>
                  <AccordionDetails>
                    <ExperienceCurationTickets experience={experience} tenant={tenant} />
                  </AccordionDetails>
                </Accordion>
                <ExperienceCurationBookingFields />
              </Box>

              <ExperienceCurationFeatures />
            </Box>
          </>
        )}

        {tab === 'categories' && (
          <>
            <Box my={2}>
              <Button
                title="Save"
                loading={creatingExperience}
                disabled={!hasImage || areOriginalValues}
                onClick={() =>
                  createExperience({
                    curationStatus: values?.curationStatus,
                  })
                }
              />
            </Box>
            <Box mt="20px">
              {categories && (
                <Accordion variant="outlined" id="categories">
                  <AccordionSummary expandIcon={<ExpandMore />}>Categories</AccordionSummary>
                  <AccordionDetails>
                    <Body height="600px">
                      <ExperienceCurationCategories
                        categories={categories}
                        experience={experience}
                        curationFeatures={curationFeatures}
                      />
                    </Body>
                  </AccordionDetails>
                </Accordion>
              )}
              <Accordion variant="outlined" id="target-audience">
                <AccordionSummary expandIcon={<ExpandMore />}>Target Audiences</AccordionSummary>
                <AccordionDetails>
                  <Body style={{ height: 'auto', overflowY: 'auto' }}>
                    <ExperienceCurationTargetAudiences experience={experience} />
                  </Body>
                </AccordionDetails>
              </Accordion>
            </Box>
          </>
        )}

        {tab === 'user-reviews' && (
          <>
            <Box my={2}>
              <Button
                title="Save"
                loading={creatingExperience}
                disabled={!hasImage || areOriginalValues}
                onClick={() =>
                  createExperience({
                    curationStatus: values?.curationStatus,
                  })
                }
              />
            </Box>

            <Box>
              <Text weight={700} lineHeight="32px" color={themes.primaryBlue}>
                User Reviews
              </Text>

              <UserReviewsTable experienceId={experience?.id} />
            </Box>
          </>
        )}
      </Box>
    </div>
  );
};
