/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { WithSnackbarProps, withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import fileDownload from 'react-file-download';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { FlightOutlined, VisibilityOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material';

import OrderDetailAccountingData from '~/components/Purchases/OrderDetail/OrderDetailAccountingData';
import { selectShowDisputeProcess } from '~/components/Purchases/utils/getDisputeProcess';
import CancellationPolicyWarningModal from '~/components/Refund/CancellationPolicyWarningModal';
import NewRefundModal from '~/components/Refund/NewModal';
import RefundItemsReminderModal from '~/components/Refund/RefundItemsReminderModal';
import OrderDetailVirtualCreditCards from '~/components/VirtualCreditCards/OrderDetail/OrderDetailPage';
import { withTenant } from '~/components/hoc';

import { ITEM_STATUS_CANCELLED } from '~/consts/order';
import { ROLE_ADMIN_USER } from '~/consts/roles';

import BedbankService from '~/services/BedbankService';
import { getBusiness, getEmployee } from '~/services/BusinessTraveller/BusinessTravellerService';
import { determineLoyaltyStatus, getEarnOrder, getPartnerships } from '~/services/LoyaltyService';
import { getFlightDetailsByOrderAndItem, getReservationByOrder, getTaxInvoiceDetails } from '~/services/OrdersService';
import { getTaxInvoicePDF } from '~/services/PDFService';
import {
  PaymentScheduleDetailsResponse,
  getDepositDetails,
  getDisputeDetails,
  getInstalmentDetails,
  getPaymentPlans,
  getPaymentScheduleDetails,
  getReserveForZeroDetails,
} from '~/services/PaymentsService';
import * as TourService from '~/services/ToursService';
import UsersService from '~/services/UsersService';

import { isAdminOrEmployee } from '~/utils/adminPermission';
import featureToggle from '~/utils/featureToggle';
import getEarnOrderPartnerships from '~/utils/getEarnOrderPartnerships';
import isOrderRefundable from '~/utils/isOrderRefundable';
import { getCustomerPhoneNumberFromOrder, isAllCancelled } from '~/utils/order';
import { calcOrderPayments } from '~/utils/refund';
import { reportError } from '~/utils/reportError';

import AddonsList from './AddonsList/list';
import BookingProtectionItem from './BookingProtection/BookingProtectionItem';
import BookingRequests from './BookingRequests/BookingRequests';
import BusinessInfo from './BusinessInfo';
import OrderItemCredit from './BusinessTraveller/OrderItemBusinessCredit';
import CarHireOrderListDetails from './CarHire/CarHireOrderListDetails';
import CruiseOrderListDetails from './Cruises/CruiseOrderListDetails';
import CustomOfferOrderList from './CustomOffer/CustomOfferOrderList';
import CustomerInfo from './CustomerInfo';
import ExperiencesList from './ExperiencesList/ExperiencesList';
import OrderInstalmentDetails from './Instalments/OrderInstalmentDetails';
import OrderDepositDetails from './OrderDepositDetails';
import OrderDetailBedbank from './OrderDetailBedbank';
import OrderDetailBusinessCredits from './OrderDetailBusinessCredits';
import OrderDetailNotes from './OrderDetailNotes';
import OrderDetailOffer from './OrderDetailOffer';
import OrderDetailPartnerships from './OrderDetailPartnerships';
import OrderDetailPaymentErrorLogs from './OrderDetailPaymentErrorLogs';
import OrderDetailPaymentPlanLogs from './OrderDetailPaymentPlanLogs';
import OrderDetailPayments from './OrderDetailPayments';
import OrderDetailRewards from './OrderDetailRewards';
import OrderDetailTour from './OrderDetailTour';
import OrderFlightItemContainer from './OrderFlightItems';
import OrderFlightFees from './OrderFlightItems/OrderFlightFees';
import OrderGift from './OrderGift';
import OrderHistoricalLogs from './OrderHistoricalLogs';
import OrderInfo from './OrderInfo';
import OrderInsuranceItemList from './OrderInsuranceItemList';
import OrderMerchantFeeDetails from './OrderMerchantFeeDetails';
import OrderOfflineFlightItemContainer from './OrderOfflineFlightItemContainer';
import OrderPaymentScheduleDetails from './OrderPaymentScheduleDetails';
import OrderServiceFeeItem from './OrderServiceFeeItem';
import OrderTermsAndConditions from './OrderTermsAndConditions';
import PaymentChargeSimulation from './PaymentChargeSimulation';
import RebookModal from './RebookModal';
import ReconfirmModal from './ReconfirmModal';
import OrderReserveForZeroDetails from './ReserveForZero/OrderReserveForZeroDetails';
import SubscriptionItem from './SubscriptionOffer/SubscriptionItem';
import TermsAndConditionsInfo from './TermsAndConditionsInfo';

interface Props {
  addOfferToCart: (pkg?: App.Package, currencyCode?: string, offer?: App.Offer, orderItem?: App.OrderItem) => void;
  allowToggleReservationType: (allow: boolean) => void;
  customer: App.User;
  customerEmailToAgentState: any;
  emailToCustomerState: any;
  emailToVendorState: any;
  flightsCreditReservationId: string;
  getTravellers: () => void;
  history: Array<string>;
  isBookingDates: boolean;
  isChangingDates: boolean;
  isConvertingToBNBL: boolean;
  offers: Array<{ items: Array<App.OrderItem>; offer: App.AccommodationOffer }>;
  onEmptyCart: () => void;
  order: App.Order;
  refreshData: () => void;
  refunds: any;
  resendCustomerEmail: () => void;
  resendCustomerEmailToAgent: () => void;
  resendOrderGiftDeliveryEmail: () => void;
  resendVendorEmail: () => void;
  resendVendorEmailToAgent: () => void;
  selectOffer: (offerId: string) => void;
  setIsBookingDates: (isBookingDates: boolean) => void;
  setIsChangingDates: (isChangingDates: boolean) => void;
  setIsConvertingToBNBL: (isConvertingToBNBL: boolean) => void;
  setIsPuttingOnHold: (isPuttingOnHold: boolean) => void;
  setOrderId: (orderId: string) => void;
  setReservationType: (reservationType: string) => void;
  showDisputeProcess: boolean;
  setShowDisputeProcess: (show: boolean) => void;
  travellers: Array<App.OrderTourTraveller>;
  vendorEmailToAgentState: any;
  tenant: App.Tenant;
  subscriptions: App.MembershipSubscriptions | null;
  disputeDetails: App.DisputeDetails | null;
}

interface IReservations {
  [key: string]: {
    vcc_reservation: IVCCReservation;
  };
}

interface IVCCReservation {
  card_id: string;
  currency: string;
  deposit_amount: number;
  deposit_percent: number;
  deposit_state: string;
  total: number;
  type_of_pay: string;
}

interface State {
  // App state
  showingRefundDetail: boolean;
  showingRebookDetail: string | null;
  showingReconfirmDetail: string | null;
  loyaltyStatus: any;
  bedbankRoomsInfo: {
    [reservationRoomId: string]: {
      id: string;
      status: string;
      reservationStatus: string;
      reservationRoomStatus: string;
      rebook?: App.Bedbank.ReservationRebookInfo;
      info?: App.Bedbank.ReservationRoomInfo;
    };
  };
  tourRoomsInfo: any;
  hasAllowedRefund: boolean;
  paymentPlanLoaded: boolean;
  paymentPlanData: any;
  pointsRequestStatus: string;
  purchaser: string;
  partnerships: any;
  invoiceDownloading: boolean;
  downloadFailed: boolean;
  showingRefundReminderModal: boolean;
  depositDetails: any;
  instalmentDetails: any;
  reserveForZeroDetails: any;
  paymentScheduleDetails: PaymentScheduleDetailsResponse;
  disputeDetails?: App.DisputeDetails;
  itemType: string;
  accountingData: Array<any>;
  refundItemId: any;
  reservationsFetched: boolean;
  reservations: IReservations;
  earnOrder: any;
  refundAutomatic: any;
  refundRoomId: any;
  expendedTransactionHistory: boolean;
  isForceBundleAlertVisible: boolean;
  isAfterChangeDate: boolean;
  orderCreditItem: any;
  businessInfo: {
    businessName: string;
    businessCreditCustomerId: string;
    employeePersonalAccountInfo: string;
    personalName: string;
    personalEmail: string;
  };
  isAccommodationRefundable: boolean;
  shouldShowRefundWarningModal: boolean;
  refundApprover: string;
  rebookableLoading: boolean;
  bookingJourneysByItemId: Record<string, App.BookingJourney>;
  showDisputeProcess: boolean;
}

class OrderDetailPage extends React.Component<Props & WithSnackbarProps, State> {
  static contextTypes = {
    user: PropTypes.object,
  };

  static defaultProps: Partial<Props> = {
    emailToCustomerState: {},
    emailToVendorState: {},
    customerEmailToAgentState: {},
    vendorEmailToAgentState: {},
  };

  constructor(props) {
    super(props);

    const hasAllowedRefund =
      calcOrderPayments(props.order.payments).sum > 0 ||
      props.order.payments[0]?.deferredPaymentDetails?.is_active ||
      props.order.car_hire_items.length > 0;

    this.state = {
      showingRefundDetail: false,
      showingRebookDetail: null,
      showingReconfirmDetail: null,
      loyaltyStatus: {},
      bedbankRoomsInfo: {},
      tourRoomsInfo: {},
      hasAllowedRefund,
      paymentPlanLoaded: false,
      paymentPlanData: null,
      pointsRequestStatus: '',
      purchaser: '',
      partnerships: null,
      invoiceDownloading: false,
      downloadFailed: false,
      showingRefundReminderModal: false,
      depositDetails: {},
      instalmentDetails: {},
      disputeDetails: null,
      reserveForZeroDetails: {},
      paymentScheduleDetails: {},
      itemType: null,
      accountingData: [],
      refundItemId: null,
      reservations: null,
      reservationsFetched: false,
      earnOrder: null,
      refundAutomatic: null,
      refundRoomId: null,
      expendedTransactionHistory: true,
      isForceBundleAlertVisible: this.props.offers.some((offer) => offer.offer.bundled_with_flights_only),
      isAfterChangeDate: false,
      orderCreditItem: null,
      businessInfo: {
        businessName: undefined,
        businessCreditCustomerId: undefined,
        employeePersonalAccountInfo: undefined,
        personalName: undefined,
        personalEmail: undefined,
      },
      isAccommodationRefundable: true,
      shouldShowRefundWarningModal: false,
      refundApprover: undefined,
      rebookableLoading: false,
      bookingJourneysByItemId: {},
      showDisputeProcess: false,
    };
  }

  async componentDidMount() {
    const purchaser = await this.fetchPurchaserData();
    this.setState({
      purchaser: purchaser.fullName,
    });
    try {
      getReservationByOrder(this.props.order).then((reservations) => {
        const isAccommodationRefundable = isOrderRefundable(this.props.order, reservations);

        this.setState({
          reservationsFetched: true,
          reservations,
          isAccommodationRefundable: isAccommodationRefundable,
        });
      });

      let data = await getPaymentPlans(this.props.order.id_orders);

      if (data.plans.length == 0) {
        data = null;
      }

      let bedbankRoomsInfo = {};

      // `BedbankService.getBookingInfo` only allows admin users
      if (this.props.order.bedbank_items.length && this.context.user.roles.includes(ROLE_ADMIN_USER)) {
        const bedbankItemsInfoData = await Promise.all(
          this.props.order.bedbank_items.flatMap((bedbankItem) =>
            BedbankService.getBookingInfo(bedbankItem.id_reservation),
          ),
        );

        const bedbankItemsRebookInfoData = await Promise.all(
          this.props.order.bedbank_items.map((bedbankItem) =>
            BedbankService.getRebookableInfo([bedbankItem.id_reservation]),
          ),
        );

        const bedbankItemsRoomsInfoData = (
          await Promise.all(
            this.props.order.bedbank_items.map((bedbankItem) =>
              BedbankService.getReservationsRoomsInfo([bedbankItem.id_reservation]),
            ),
          )
        ).flatMap(({ result }) => result);

        bedbankRoomsInfo = bedbankItemsInfoData.reduce((acc, { result }) => {
          const rooms = result.rooms;

          for (const room of rooms) {
            acc[room.id] = {
              id: room.id,
              status: room.status,
              reservationStatus: result.reservationStatus,
              reservationRoomStatus: room.reservationRoomStatus,
              info: (bedbankItemsRoomsInfoData ?? []).find((x) => x.id === room.id),
            };
          }

          return acc;
        }, {});

        bedbankRoomsInfo = bedbankItemsRebookInfoData.reduce((acc, { result }) => {
          const rooms = result;

          for (const room of rooms) {
            if (acc[room.reservationRoomId] && !room.newReservationId) {
              acc[room.reservationRoomId] = {
                ...acc[room.reservationRoomId],
                rebook: room,
              };
            }
          }

          return acc;
        }, bedbankRoomsInfo);
      }

      let tourRoomsInfo = {};
      if (this.props.order.tour_items?.length) {
        const tourItemsInfoData = await Promise.all(
          this.props.order.tour_items.map((tourItem) => TourService.getBookingInfo(tourItem.reservation_id)),
        );

        tourRoomsInfo = tourItemsInfoData.reduce((acc, { result }) => {
          acc[result.reservationId] = result;

          return acc;
        }, {});
      }

      const partnershipsData = await getPartnerships(this.props.order.region_code, this.props.order.brand);
      const allPartnerships = partnershipsData?.result || [];
      const loyaltyStatus = determineLoyaltyStatus(this.props.order, allPartnerships);

      const [depositDetails, instalmentDetails, reserveForZeroDetails, paymentScheduleDetails] = await Promise.all([
        getDepositDetails(this.props.order.id),
        getInstalmentDetails(this.props.order.id),
        getReserveForZeroDetails(this.props.order.id),
        getPaymentScheduleDetails(this.props.order.id),
      ]);

      let disputeDetails = null;
      if (isAdminOrEmployee(this.context.user)) {
        disputeDetails = await getDisputeDetails(this.props.order.id);
      }

      if (this.props.order.business_id !== null) {
        const business = await getBusiness(this.props.order.business_id);
        this.setState({
          businessInfo: { ...this.state.businessInfo, businessName: business.name },
        });
        const employeeInfo = await getEmployee(this.props.order.fk_customer_id);
        this.setState({
          businessInfo: {
            ...this.state.businessInfo,
            businessCreditCustomerId: employeeInfo.creditCustomerId ?? employeeInfo.customerId,
          },
        });
        const employeeCreditCustomerInfo: App.User = await UsersService.getUser(
          employeeInfo.creditCustomerId ?? employeeInfo.customerId,
        );
        this.setState({
          businessInfo: {
            ...this.state.businessInfo,
            personalName: employeeCreditCustomerInfo.fullName,
            personalEmail: employeeCreditCustomerInfo.email,
          },
        });
      }

      const bookingJourneys = await Promise.all(
        this.props.order.flight_items.map(async (item) => {
          const flightDetails = await getFlightDetailsByOrderAndItem(item.fk_order_id, item.id);
          return [item.id, flightDetails.booking_journey];
        }),
      );
      const bookingJourneysByItemId = Object.fromEntries(bookingJourneys);

      const earnOrder = await Promise.all(getEarnOrder(this.props.order, getEarnOrderPartnerships(allPartnerships)));
      this.setState({
        paymentPlanLoaded: true,
        paymentPlanData: data,
        partnerships: allPartnerships,
        loyaltyStatus: loyaltyStatus,
        bedbankRoomsInfo,
        tourRoomsInfo,
        depositDetails,
        instalmentDetails,
        disputeDetails,
        reserveForZeroDetails,
        paymentScheduleDetails,
        earnOrder,
        bookingJourneysByItemId,
      });

      // Add the order to recent orders for quick future access
      const saved = localStorage.getItem('recentlyViewedOrders');
      const recentOrders = saved ? JSON.parse(saved) : [];
      const orderId = this.props.order.id_orders;
      const orderEntry = { id: orderId, order: this.props.order, date: new Date().toISOString() };
      const updatedOrders = [orderEntry, ...recentOrders.filter((o) => o.id !== orderId)].slice(0, 30);
      localStorage.setItem('recentlyViewedOrders', JSON.stringify(updatedOrders));
    } catch (error) {
      reportError(error);
    }
  }

  debugFormatter(cell) {
    return JSON.stringify(cell);
  }

  fetchPurchaserData = () => {
    const { order } = this.props;

    return UsersService.getUser(order.fk_purchaser_id);
  };

  triggerRefund = () => {
    this.setState({
      showingRefundDetail: true,
    });
    window.hj =
      window.hj ||
      function () {
        // I honestly have no idea what this line does.
        // eslint-disable-next-line prefer-rest-params
        (window.hj.q = window.hj.q || []).push(arguments);
      };
    //hotjar recording trigger
    window.hj('trigger', 'refund');
  };

  setRefundComment = (comment: string) => {
    this.setState({ refundApprover: comment });
  };

  closeRefundWarningModal = () => {
    this.setState({
      shouldShowRefundWarningModal: false,
    });
  };

  showRefundModal = async ({ itemId, roomId = null, auto = null, itemType = null }) => {
    this.setState({
      refundItemId: itemId,
      refundRoomId: roomId,
      refundAutomatic: auto,
      itemType: itemType,
    });
    if (!this.state.isAccommodationRefundable) {
      this.setState({
        shouldShowRefundWarningModal: true,
      });
    } else {
      this.triggerRefund();
    }
  };

  checkAndShowRebookingModal = async (itemId: string) => {
    this.setState({ rebookableLoading: true });
    const bedbankItem = this.props.order.bedbank_items.find((item) => item.id === itemId);

    if (!bedbankItem) {
      this.props.enqueueSnackbar(`No bedbank item with ID ${itemId} found`);
      return;
    }

    const response = await BedbankService.checkRebookable(bedbankItem.id_reservation);

    if (!response.result?.isRebookable) {
      this.props.enqueueSnackbar('This Margin Max Opportunity is no longer available.');

      const newBedbankRoomsInfo = bedbankItem.rooms.reduce(
        (acc, room) => {
          delete acc[room.id_reservation_room];
          return acc;
        },
        { ...this.state.bedbankRoomsInfo },
      );

      this.setState({ bedbankRoomsInfo: newBedbankRoomsInfo, rebookableLoading: false });

      return;
    }

    const rebookableInfo = await BedbankService.getRebookableInfo([bedbankItem.id_reservation]);

    const newBedbankRoomsInfo = rebookableInfo.result.reduce(
      (acc, room) => {
        if (acc[room.reservationRoomId] && !room.newReservationId) {
          acc[room.reservationRoomId] = {
            ...acc[room.reservationRoomId],
            rebook: room,
          };
        }

        return acc;
      },
      { ...this.state.bedbankRoomsInfo },
    );

    this.setState({ bedbankRoomsInfo: newBedbankRoomsInfo, showingRebookDetail: itemId, rebookableLoading: false });
  };

  showRebookingModal = ({ itemId }) => {
    this.setState({
      showingRebookDetail: itemId,
    });
  };

  showReconfirmModal = ({ itemId }) => {
    this.setState({
      showingReconfirmDetail: itemId,
    });
  };

  hasRemindingItem = (key) => {
    const { order } = this.props;
    return !!order[key]?.some((item) => item.status !== ITEM_STATUS_CANCELLED);
  };

  showWarningModal = () => {
    const hasExperiencesToBeReminded =
      this.hasRemindingItem('addon_items') || this.hasRemindingItem('experience_items');
    const hasCruiseToBeReminded = this.hasRemindingItem('cruise_items');
    const hasInsuranceToBeReminded = this.hasRemindingItem('insurance_items');
    const hasFlightsToBeReminded = this.hasRemindingItem('flight_items');

    if (hasExperiencesToBeReminded || hasInsuranceToBeReminded || hasFlightsToBeReminded || hasCruiseToBeReminded) {
      // postpones refreshData to when modal is dismissed
      this.setState({
        showingRefundReminderModal: true,
        showingRefundDetail: false,
      });
    } else {
      this.props.refreshData();
    }
  };

  onRefund = () => {
    const { order } = this.props;
    const isAccommodationItem = order.accommodation_items.find((item) => item.id === this.state.refundItemId);

    if (!isAccommodationItem) {
      this.props.refreshData();
      return;
    }

    this.showWarningModal();
  };

  hideRefundDetail = () => {
    if (window.confirm(`Are you sure? Data will be wiped out`)) {
      this.setState({
        showingRefundDetail: false,
      });

      this.props.refreshData();
    }
  };

  downloadTax = async () => {
    this.setState({
      invoiceDownloading: true,
      downloadFailed: false,
    });

    try {
      const invoiceList = await this.createTaxInvoiceDetails();
      const pdf = await getTaxInvoicePDF(invoiceList);
      const fileName = `LuxuryEscapes-Tax-Invoice-${this.props.order.id}.pdf`;
      await fileDownload(pdf, fileName);
    } catch (error) {
      console.warn(error);
      this.setState({
        downloadFailed: true,
        invoiceDownloading: false,
      });
    } finally {
      this.setState({
        invoiceDownloading: false,
      });
    }
  };

  newPointsRequest = (status) => {
    this.setState({
      pointsRequestStatus: status,
    });
  };

  createTaxInvoiceDetails = async () => {
    const { order } = this.props;
    const result = getTaxInvoiceDetails(order.id_orders);
    return result;
  };

  showForceBundleAlert = () => {
    this.setState({ isForceBundleAlertVisible: true });
  };

  render() {
    const {
      order,
      customer,
      offers,
      refreshData,
      isBookingDates,
      isChangingDates,
      emailToCustomerState,
      emailToVendorState,
      resendOrderGiftDeliveryEmail,
      customerEmailToAgentState,
      vendorEmailToAgentState,
      flightsCreditReservationId,
      subscriptions,
      disputeDetails,
      showDisputeProcess,
    } = this.props;
    const { showingRefundDetail, loyaltyStatus } = this.state;
    const earnOrderPartnerships = getEarnOrderPartnerships(this.state.partnerships);
    const {
      depositDetails,
      paymentScheduleDetails,
      instalmentDetails,
      reserveForZeroDetails,
      reservationsFetched,
      reservations,
      isForceBundleAlertVisible,
    } = this.state;

    const showVirtualCreditCardsDetails =
      order.items.length > 0 || (order.bedbank_items && order.bedbank_items.length > 0);

    // In case of refund we need to pass an offer to refund modal
    const getAccommodationOfferFromItemId = (itemId) => {
      let offerSalesforceId = null;
      let item: any = order.items.find((i) => i.id === itemId);
      if (item) {
        offerSalesforceId = item.offer.id_salesforce_external;
      } else {
        item = order.addon_items.find((i) => i.id === itemId);
        if (item) {
          offerSalesforceId = item.offer_salesforce_id;
        }
      }

      // If not an accommodation or addon item, i.e. flights etc - this will just return the first offer
      const result = this.props.offers.find(
        ({ offer }) => !offerSalesforceId || offer.id_salesforce_external == offerSalesforceId,
      );
      return result?.offer;
    };

    const hasRemindingExperiences = this.hasRemindingItem('addon_items') || this.hasRemindingItem('experience_items');
    const hasRemindingInsurances = this.hasRemindingItem('insurance_items');
    const hasRemindingFlights = this.hasRemindingItem('flight_items');
    const hasRemindingCruises = this.hasRemindingItem('cruise_items');
    const hasRemindingCarHire = this.hasRemindingItem('car_hire_items');

    const hasCruisesToShow = featureToggle.availableToShow('SHOW_CRUISES') && order.cruise_items?.length > 0;

    const hasCarhireToShow = featureToggle.availableToShow('SHOW_CAR_HIRE') && order.car_hire_items?.length > 0;

    const hasExperiencesToShow = window.configs.SHOW_EXPERIENCES === 'true' && order.experience_items?.length > 0;

    const flightItem = order?.flight_items?.[0];
    const showBookingDetails = flightItem?.provider === 'amadeus' && flightItem?.pnr_id !== null;

    // We need to get the last flight item for LEBT.
    // The credits and budget for a flight is stored on the last flight_item in svc-business
    const lastFlightItem = order?.flight_items?.[order?.flight_items?.length - 1];

    const showAtolDetails = order?.region_code === 'GB' && flightItem?.pnr_id !== null;

    const showCreditReservation = !!flightsCreditReservationId;

    const allAccommodationsCancelled =
      order.items.length > 0 || order.bedbank_items.length > 0
        ? isAllCancelled(order.items.concat(order.bedbank_items))
        : false;

    const hasServiceFee = Array.isArray(order?.service_fee_items) && order.service_fee_items.length > 0;
    const hasSubscriptions = Array.isArray(order?.subscription_items) && order.subscription_items.length > 0;

    // For OpenJaw flights, we want to display a single flight item twice
    // with each element representing a leg of the journey
    const sortedFlightItems = order.flight_items?.sort((a, b) => a.leg_number - b.leg_number);
    const journeysByItemId = this.state.bookingJourneysByItemId;

    return (
      <Stack px={2} className="order-detail">
        <Helmet>
          <title>Orders |{customer.fullName ? ` ${customer.fullName} |` : ''} Order Summary</title>
        </Helmet>

        <Box mt={2}>
          {emailToVendorState.successSending && <Alert severity="success">{emailToVendorState.successSending}</Alert>}
          {emailToVendorState.errorSending && <Alert severity="error">{emailToVendorState.errorSending}</Alert>}
          {emailToCustomerState.successSending && (
            <Alert severity="success">{emailToCustomerState.successSending}</Alert>
          )}
          {emailToCustomerState.errorSending && <Alert severity="error">{emailToCustomerState.errorSending}</Alert>}
          {customerEmailToAgentState.successSending && (
            <Alert severity="success">{customerEmailToAgentState.successSending}</Alert>
          )}
          {customerEmailToAgentState.errorSending && (
            <Alert severity="error">{customerEmailToAgentState.errorSending}</Alert>
          )}
          {vendorEmailToAgentState.successSending && (
            <Alert severity="success">{vendorEmailToAgentState.successSending}</Alert>
          )}
          {vendorEmailToAgentState.errorSending && (
            <Alert severity="error">{vendorEmailToAgentState.errorSending}</Alert>
          )}

          {allAccommodationsCancelled && hasRemindingExperiences && (
            <Alert severity="warning">
              This order has all accommodation items cancelled but some addons/experiences still active. Please check
              them below and cancel if needed.
            </Alert>
          )}

          {isForceBundleAlertVisible && (
            <Alert severity="warning">
              <p>This is a force bundle purchase</p>
            </Alert>
          )}

          <Stack spacing={2} mb={2}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" alignItems="center">
                  <VisibilityOutlined sx={{ mr: 1 }} />
                  <Typography variant="h6">Overview</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  <CustomerInfo
                    customer={customer}
                    phoneNumber={getCustomerPhoneNumberFromOrder(order)}
                    postcode={customer.postcode}
                    businessInfo={this.state.businessInfo}
                    subscriptions={subscriptions}
                    businessId={order.business_id}
                    showDisputeProcess={showDisputeProcess}
                  />
                  {order.business_id && (
                    <BusinessInfo customer={customer} businessInfo={this.state.businessInfo} order={order} />
                  )}
                  <OrderInfo
                    order={order}
                    purchaser={this.state.purchaser}
                    businessInfo={this.state.businessInfo}
                    disputeDetails={this.state.disputeDetails}
                    showDisputeProcess={showDisputeProcess}
                  />
                  <TermsAndConditionsInfo orderId={order.id_orders} />
                </Stack>
              </AccordionDetails>
            </Accordion>

            {!showDisputeProcess && (
              <OrderDetailNotes orderId={order.id_orders} refreshData={this.props.refreshData} customer={customer} />
            )}
          </Stack>

          {/* Order details section */}
          {offers && (
            <OrderDetailOffer
              addOfferToCart={this.props.addOfferToCart}
              allowToggleReservationType={this.props.allowToggleReservationType}
              customer={this.props.customer}
              flightsCreditReservationId={this.props.flightsCreditReservationId}
              history={this.props.history}
              isBookingDates={this.props.isBookingDates}
              isChangingDates={this.props.isChangingDates}
              offers={this.props.offers}
              onEmptyCart={this.props.onEmptyCart}
              order={this.props.order}
              refreshData={this.props.refreshData}
              refunds={this.props.refunds}
              customerEmailToAgentState={this.props.customerEmailToAgentState}
              emailToCustomerState={this.props.emailToCustomerState}
              resendCustomerEmail={this.props.resendCustomerEmail}
              resendCustomerEmailToAgent={this.props.resendCustomerEmailToAgent}
              vendorEmailToAgentState={this.props.vendorEmailToAgentState}
              emailToVendorState={this.props.emailToVendorState}
              resendVendorEmail={this.props.resendVendorEmail}
              resendVendorEmailToAgent={this.props.resendVendorEmailToAgent}
              selectOffer={this.props.selectOffer}
              setOrderId={this.props.setOrderId}
              setReservationType={this.props.setReservationType}
              tenant={this.props.tenant}
              travellers={this.props.travellers}
              showRefundModal={this.showRefundModal}
              hasAllowedRefund={this.state.hasAllowedRefund}
              showWarningModal={this.showWarningModal}
              getTravellers={this.props.getTravellers}
              isConvertingToBNBL={this.props.isConvertingToBNBL}
              setIsBookingDates={this.props.setIsBookingDates}
              setIsChangingDates={this.props.setIsChangingDates}
              setIsConvertingToBNBL={this.props.setIsConvertingToBNBL}
              setIsPuttingOnHold={this.props.setIsPuttingOnHold}
            />
          )}

          {order.custom_offer_items &&
            order.custom_offer_items.length > 0 &&
            order.custom_offer_items.map((customItem) => (
              <Accordion defaultExpanded key={customItem.id} variant="outlined">
                <AccordionSummary>Custom Offer item</AccordionSummary>
                <AccordionDetails>
                  <CustomOfferOrderList customOfferItem={customItem} showRefundModal={this.showRefundModal} />
                </AccordionDetails>
              </Accordion>
            ))}

          {order.has_booking_protection && (
            <BookingProtectionItem
              order={order}
              bookingProtectionItems={order.booking_protection_items}
              showRefundModal={this.showRefundModal}
              hasAllowedRefund={this.state.hasAllowedRefund}
            />
          )}

          {hasSubscriptions && <SubscriptionItem order={order} />}

          {order.bedbank_items &&
            order.bedbank_items.length > 0 &&
            order.bedbank_items.map((bedbankItem, index) => (
              <OrderDetailBedbank
                key={bedbankItem.id}
                item={bedbankItem}
                order={order}
                count={index + 1}
                checkAndShowRebookingModal={this.checkAndShowRebookingModal}
                rebookableLoading={this.state.rebookableLoading}
                showReconfirmModal={this.showReconfirmModal}
                showRefundModal={this.showRefundModal}
                hasAllowedRefund={this.state.hasAllowedRefund}
                bedbankRoomsInfo={this.state.bedbankRoomsInfo}
                customerEmailToAgentState={this.props.customerEmailToAgentState}
                emailToCustomerState={this.props.emailToCustomerState}
                resendCustomerEmail={this.props.resendCustomerEmail}
                resendCustomerEmailToAgent={this.props.resendCustomerEmailToAgent}
                shouldShowCancellationWarning={!this.state.isAccommodationRefundable}
              />
            ))}

          {order.tour_items?.map((tourItem) => (
            <OrderDetailTour
              key={tourItem.id}
              item={tourItem}
              order={order}
              tourRoomInfo={this.state.tourRoomsInfo[tourItem.reservation_id]}
              customerEmailToAgentState={this.props.customerEmailToAgentState}
              emailToCustomerState={this.props.emailToCustomerState}
              resendCustomerEmail={this.props.resendCustomerEmail}
              resendCustomerEmailToAgent={this.props.resendCustomerEmailToAgent}
              vendorEmailToAgentState={this.props.vendorEmailToAgentState}
              emailToVendorState={this.props.emailToVendorState}
              resendVendorEmail={this.props.resendVendorEmail}
              resendVendorEmailToAgent={this.props.resendVendorEmailToAgent}
              showRefundModal={this.showRefundModal}
            />
          ))}

          {order.has_flight && (
            <Accordion defaultExpanded id="flights" sx={{ mt: 2 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                  <Box display="flex" alignItems="center">
                    <FlightOutlined sx={{ mr: 1 }} />
                    <Typography variant="h6">Flights ({order.flight_items.length})</Typography>
                  </Box>
                  {showBookingDetails && (
                    <Link
                      href={`/purchases/${order.id_orders}/booking-details`}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      underline="hover"
                      target="_blank"
                      rel="noreferrer"
                    >
                      PNR
                    </Link>
                  )}
                  {showAtolDetails && (
                    <Link
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      underline="hover"
                      target="_blank"
                      rel="noreferrer"
                      href={`/purchases/${order.id_orders}/atol-details`}
                    >
                      ATOL
                    </Link>
                  )}
                  {showCreditReservation && (
                    <Link
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      underline="hover"
                      target="_blank"
                      rel="noreferrer"
                      href={`/flights/credit-reservation/${flightsCreditReservationId}/details`}
                    >
                      Credit Reservation
                    </Link>
                  )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                {sortedFlightItems.map((flightItem) => (
                  <OrderFlightItemContainer
                    key={flightItem.id}
                    item={flightItem}
                    refreshData={refreshData}
                    showForceBundleAlert={this.showForceBundleAlert}
                  />
                ))}

                <OrderFlightFees
                  flightItems={order.flight_items}
                  journeysByItemId={journeysByItemId}
                  currencyCode={order.currency_code}
                />

                {this.props.tenant.brand === 'lebusinesstraveller' && order.status !== 'abandoned' && (
                  <>
                    <Divider>
                      <Typography fontWeight="bold">LEBT Credits</Typography>
                    </Divider>
                    <OrderItemCredit
                      orderId={order.id_orders}
                      orderItemId={lastFlightItem.id}
                      customerId={order.fk_customer_id}
                      location={
                        journeysByItemId[flightItem.id]?.legs[0]?.flights[
                          journeysByItemId[flightItem.id]?.legs[0]?.flights?.length - 1
                        ]?.arrivalAirportName
                      }
                    />
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          )}

          {order.has_offline_flight && (
            <OrderOfflineFlightItemContainer item={order.offline_flight_items[0]} refreshData={refreshData} />
          )}

          {order.insurance_items && order.insurance_items.length > 0 && (
            <OrderInsuranceItemList
              order={order}
              items={order.insurance_items}
              showRefundModal={this.showRefundModal}
              hasAllowedRefund={this.state.hasAllowedRefund}
              purchaseDate={order.created_at}
              refreshData={refreshData}
              refunds={this.props.refunds}
            />
          )}

          {hasCruisesToShow && (
            <CruiseOrderListDetails
              order={order}
              cruiseItems={order.cruise_items}
              currencyCode={order.currency_code}
              showRefundModal={this.showRefundModal}
              hasAllowedRefund={this.state.hasAllowedRefund}
              depositDetails={
                depositDetails && {
                  depositAmount: depositDetails?.paid_amount,
                  balanceAmount: depositDetails?.balance_amount,
                  balanceDueDate: depositDetails?.balance_due_date,
                  depositCurrencyCode: depositDetails?.currency,
                }
              }
            />
          )}

          {hasCarhireToShow && (
            <CarHireOrderListDetails
              carHireItems={order.car_hire_items}
              currencyCode={order.currency_code}
              showRefundModal={this.showRefundModal}
              refreshData={refreshData}
              hasAllowedRefund={this.state.hasAllowedRefund}
            />
          )}

          {hasExperiencesToShow && (
            <ExperiencesList
              hasAllowedRefund={this.state.hasAllowedRefund}
              order={order}
              showRefundModal={this.showRefundModal}
              refreshData={this.props.refreshData}
            />
          )}

          {order.addon_items?.length > 0 && (
            <AddonsList
              payments={order.payments}
              addons={order.addon_items}
              refunds={this.props.refunds}
              isBookingDates={isBookingDates}
              isChangingDates={isChangingDates}
              showRefundModal={this.showRefundModal}
              checkInStatuses={offers.reduce(
                (acc, { items }) => acc.concat(items.map((i) => i?.checkInStatus?.addons)),
                [],
              )}
            />
          )}

          {hasServiceFee && (
            <OrderServiceFeeItem
              order={order}
              paymentScheduleDetails={paymentScheduleDetails}
              showRefundModal={this.showRefundModal}
              refunds={this.props.refunds}
            />
          )}

          {depositDetails?.id_deposit && <OrderDepositDetails depositDetails={depositDetails} />}
          {paymentScheduleDetails?.result?.id && (
            <OrderPaymentScheduleDetails paymentScheduleDetails={paymentScheduleDetails} payments={order.payments} />
          )}
          {instalmentDetails?.id_instalments && (
            <Accordion defaultExpanded>
              <AccordionSummary
                sx={{
                  backgroundColor: 'grey.200',
                  height: '60px',
                }}
              >
                Instalment Details
              </AccordionSummary>
              <AccordionDetails>
                <OrderInstalmentDetails instalmentDetails={instalmentDetails} payments={order.payments} />
              </AccordionDetails>
            </Accordion>
          )}

          {reserveForZeroDetails?.id_deferred_payment && (
            <OrderReserveForZeroDetails reserveForZeroDetails={reserveForZeroDetails} />
          )}

          {order.merchant_fees?.length > 0 && !showDisputeProcess && <OrderMerchantFeeDetails order={order} />}

          {this.state.showingRebookDetail && (
            <RebookModal
              order={order}
              refreshData={this.props.refreshData}
              itemId={this.state.showingRebookDetail}
              bedbankRoomsInfo={this.state.bedbankRoomsInfo}
              onClose={() => this.showRebookingModal({ itemId: null })}
            />
          )}

          {this.state.showingReconfirmDetail && (
            <ReconfirmModal
              order={order}
              refreshData={this.props.refreshData}
              itemId={this.state.showingReconfirmDetail}
              onClose={() => this.showReconfirmModal({ itemId: null })}
            />
          )}

          {order.payments.length > 0 && !showDisputeProcess && (
            <Box mt={2}>
              <OrderDetailPayments payments={order.payments} />

              <NewRefundModal
                show={showingRefundDetail}
                onHide={this.hideRefundDetail}
                refreshData={this.onRefund}
                order={order}
                offer={getAccommodationOfferFromItemId(this.state.refundItemId)}
                itemId={this.state.refundItemId}
                roomId={this.state.refundRoomId}
                automatic={this.state.refundAutomatic}
                itemType={this.state.itemType}
                isAfterChangeDate={this.state.isAfterChangeDate}
                refundApprover={this.state.refundApprover}
                isRefundable={this.state.isAccommodationRefundable}
              />

              <RefundItemsReminderModal
                show={this.state.showingRefundReminderModal}
                hasCruises={hasRemindingCruises}
                hasExperiences={hasRemindingExperiences}
                hasInsurance={hasRemindingInsurances}
                hasFlights={hasRemindingFlights}
                hasCarHire={hasRemindingCarHire}
                onClose={this.props.refreshData}
              />
            </Box>
          )}

          <PaymentChargeSimulation order={order} paymentScheduleDetails={this.state.paymentScheduleDetails.result} />

          <BookingRequests order={order} />

          <OrderTermsAndConditions order={order} />

          {this.props.refunds?.result && this.props.refunds.result.length > 0 && (
            <OrderDetailAccountingData
              order={order}
              offers={offers}
              refunds={this.props.refunds}
              refreshData={this.props.refreshData}
              reservationsFetched={reservationsFetched}
              reservations={reservations as unknown as Array<App.OrderItemReservation>}
            />
          )}

          {this.state.partnerships && (
            <OrderDetailPartnerships customer={customer} partnerships={this.state.partnerships} order={order} />
          )}

          {earnOrderPartnerships && earnOrderPartnerships.length > 0 && this.state.earnOrder.length > 0 && (
            <Accordion defaultExpanded>
              <AccordionSummary
                sx={{
                  backgroundColor: 'grey.200',
                  height: '60px',
                }}
              >
                <Typography> Rewards History</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {this.state.earnOrder?.map((reward) => (
                  <OrderDetailRewards
                    key={reward.prefix}
                    newPointsRequest={this.newPointsRequest}
                    customer={customer}
                    partnership={this.state.partnerships.find((partnership) => partnership.prefix === reward.prefix)}
                    order={order}
                    data={reward}
                    status={loyaltyStatus[reward.prefix]}
                    showForm={
                      order.items.length &&
                      loyaltyStatus[reward.prefix].isPostThirtyDays &&
                      loyaltyStatus[reward.prefix].isEligible
                    }
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          )}

          {showVirtualCreditCardsDetails && (
            <OrderDetailVirtualCreditCards
              items={order.items}
              reservationsFetched={reservationsFetched}
              reservations={reservations}
            />
          )}
        </Box>

        {this.state.paymentPlanLoaded && this.state.paymentPlanData && !showDisputeProcess && (
          <>
            <OrderDetailPaymentPlanLogs data={this.state.paymentPlanData.plans} />

            {this.state.paymentPlanData.error_logs?.length > 0 && (
              <OrderDetailPaymentErrorLogs data={this.state.paymentPlanData.error_logs} />
            )}
          </>
        )}

        {order.order_gift && <OrderGift order={order} resendRedemptionEmail={resendOrderGiftDeliveryEmail} />}

        {this.props.order.business_id !== null && (
          <OrderDetailBusinessCredits businessCreditItems={order.business_credit_items} />
        )}
        <Box pt={2}>
          <OrderHistoricalLogs order={order} />
        </Box>
        <CancellationPolicyWarningModal
          showModal={this.state.shouldShowRefundWarningModal}
          closeModal={this.closeRefundWarningModal}
          showRefundModal={this.triggerRefund}
          setRefundComment={this.setRefundComment}
        />
      </Stack>
    );
  }
}

export const OrderDetailPageComponent = OrderDetailPage;

const mapStateToProps = (state) => ({
  showDisputeProcess: selectShowDisputeProcess(state),
});

export default compose(withRouter, withTenant, withSnackbar, connect(mapStateToProps))(OrderDetailPage);

OrderDetailPage.contextTypes = {
  user: PropTypes.object,
};
