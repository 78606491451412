import React from 'react';

import pluralize from 'pluralize';

import { Box, Stack, Typography } from '@mui/material';

import { LUX_LOYALTY_PROGRAM_CONFIG, LUX_LOYALTY_TIERS_CONFIG } from '@luxuryescapes/lib-lux-loyalty';

import CopyableField from '~/components/Common/CopyableField';

import { formatDateSlashes } from '~/services/TimeService';

import formatDecimal from '~/utils/formatDecimal';
import { titleCase } from '~/utils/stringUtils';

interface Props {
  account: App.LuxLoyaltyAccount;
}

export default function LuxLoyaltyAccountOverview({ account }: Props) {
  const formattedStatusCreditsName = titleCase(pluralize(LUX_LOYALTY_PROGRAM_CONFIG.statusCreditsUnit));
  const formattedCurrencyName = titleCase(pluralize(LUX_LOYALTY_PROGRAM_CONFIG.pointsUnit));

  return (
    <Stack direction="column" gap={4} flex={1}>
      {account.accountNumber ? (
        <Box>
          <Typography variant="h6">{LUX_LOYALTY_PROGRAM_CONFIG.programDisplayName} LE Member ID</Typography>
          <CopyableField variant="subtitle1" value={account.accountNumber} />
        </Box>
      ) : null}
      <Box>
        <Typography variant="h6">{LUX_LOYALTY_PROGRAM_CONFIG.programDisplayName} External Provider ID</Typography>
        <CopyableField variant="subtitle1" value={account.ibsMemberId} />
      </Box>
      <Box>
        <Typography variant="h6">Tier</Typography>
        <Typography variant="subtitle1">{LUX_LOYALTY_TIERS_CONFIG[account.tier].displayName}</Typography>
      </Box>
      <Box>
        <Typography variant="h6">{formattedStatusCreditsName}</Typography>
        <Typography variant="subtitle1">{formatDecimal(account.statusCredits)}</Typography>
      </Box>
      <Box>
        <Typography variant="h6">{formattedCurrencyName}</Typography>
        <Typography variant="subtitle1">{formatDecimal(account.points)}</Typography>
      </Box>
      {!!account.statusReviewDate && (
        <Box>
          <Typography variant="h6">Membership Renewal Date</Typography>
          <Typography variant="subtitle1">{formatDateSlashes(account.statusReviewDate)}</Typography>
        </Box>
      )}
      {!!account.totalCreditsForTierRetention && (
        <Box>
          <Typography variant="h6">{formattedStatusCreditsName} Required for Tier Retention</Typography>
          <Typography variant="subtitle1">{account.totalCreditsForTierRetention}</Typography>
        </Box>
      )}
      {!!account.totalCreditsForTierUpgrade && (
        <Box>
          <Typography variant="h6">{formattedStatusCreditsName} Required for Tier Upgrade</Typography>
          <Typography variant="subtitle1">{account.totalCreditsForTierUpgrade}</Typography>
        </Box>
      )}
      <Box>
        <Typography variant="h6">Number of Nights</Typography>
        <Typography variant="subtitle1">{account.numberOfNights}</Typography>
      </Box>
      {!!account.numberOfNightsForTierRetention && (
        <Box>
          <Typography variant="h6">Number of Nights Required for Tier Retention</Typography>
          <Typography variant="subtitle1">{account.numberOfNightsForTierRetention}</Typography>
        </Box>
      )}
      {!!account.numberOfNightsForTierUpgrade && (
        <Box>
          <Typography variant="h6">Number of Nights Required for Tier Upgrade</Typography>
          <Typography variant="subtitle1">{account.numberOfNightsForTierUpgrade}</Typography>
        </Box>
      )}
    </Stack>
  );
}
