import React, { useEffect, useState } from 'react';

import { Autocomplete, TextField, Typography } from '@mui/material';

import { TypeaheadSearchResult } from '~/services/SearchService';

interface Props {
  onSelect: (selection: TypeaheadSearchResult) => void;
  setValue: (query: string) => void;
  value: string;
  setOpen: (open: boolean) => void;
  open: boolean;
  results: Array<TypeaheadSearchResult>;
  required?: boolean;
  label?: string;
  onCurrentValueChange?: (value: TypeaheadSearchResult | null) => void;
}

export default function TypeaheadSearchForm({
  onSelect,
  value,
  setValue,
  results,
  label = 'Search place',
  onCurrentValueChange,
  required = false,
}: Props) {
  const [currentValue, setCurrentValue] = useState<TypeaheadSearchResult | null>(null);

  // Pre populate when there is a value in form
  useEffect(() => {
    if (value) {
      setCurrentValue({
        id: '',
        format: { mainText: value, secondaryText: '' },
        searchType: '',
        name: value,
      });
    }
  }, [value]);

  const select = (result: TypeaheadSearchResult | null) => {
    setCurrentValue(result);

    if (onCurrentValueChange) {
      onCurrentValueChange(result);
    }

    if (result !== null) {
      onSelect(result);
    }
  };

  return (
    <Autocomplete
      options={results ?? []}
      filterOptions={(x) => x}
      value={currentValue}
      inputValue={value}
      noOptionsText="No locations"
      placeholder="Start typing the name of the place"
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => option.format.mainText ?? 'Anywhere'}
      renderInput={(params) => (
        <TextField label={label} required={required} name="searchString" data-testid="PlaceSearch" {...params} />
      )}
      renderOption={(props, option: TypeaheadSearchResult) => (
        <li {...props}>
          <Typography>
            {option.format.mainText} <small>{`${option.searchType} | ${option.format.secondaryText}`}</small>
          </Typography>
        </li>
      )}
      onInputChange={(_, value: string) => {
        setValue(value);
      }}
      onChange={(_, value: TypeaheadSearchResult | null) => select(value)}
      fullWidth
    />
  );
}
