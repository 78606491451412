import React from 'react';

import { Chip, ChipProps, Link, Stack, Typography } from '@mui/material';

import { formatDateSlashesWithClock } from '~/services/TimeService';

import { PriceSummaryJobStatus } from '~/types/services/priceParrot';

import { extractUsernameFromEmail } from '~/utils/stringUtils';

type Props = {
  status: PriceSummaryJobStatus;
  progress: number;
  days: number;
  cancelledBy?: string;
  cancelledByEmail?: string;
  updatedAt: string;
  retries: number;
};

export default function GridColStatus({
  status,
  progress,
  days,
  cancelledBy,
  cancelledByEmail,
  updatedAt,
  retries,
}: Props) {
  let color: ChipProps['color'] = 'default';
  let label = '';
  let showCancelledBy = false;
  switch (status) {
    case PriceSummaryJobStatus.COMPLETED:
      color = 'success';
      break;
    case PriceSummaryJobStatus.FAILED:
      color = 'error';
      break;
    case PriceSummaryJobStatus.PENDING:
      color = 'warning';
      break;
    case PriceSummaryJobStatus.PROCESSING:
      color = 'info';
      label = `(${Math.floor((progress / days) * 100)}%) `;
      break;
    case PriceSummaryJobStatus.CANCELLED:
      color = 'error';
      showCancelledBy = true;
      break;
    case PriceSummaryJobStatus.RETRYING:
      color = 'warning';
      label = `(${retries}/3) `;
      break;
  }

  return (
    <Stack direction="row" gap={0.5} flexWrap="wrap">
      <Chip
        size="small"
        color={color}
        label={label + status.toUpperCase()}
        style={{ transition: 'background-color 0.5s ease' }}
      />
      <Typography component="div">at {formatDateSlashesWithClock(updatedAt)}</Typography>
      {showCancelledBy && cancelledBy && cancelledByEmail && (
        <Typography sx={{ wordBreak: 'break-word' }}>
          by <Link href={`/users/${cancelledBy}`}>{extractUsernameFromEmail(cancelledByEmail)}</Link>
        </Typography>
      )}
    </Stack>
  );
}
