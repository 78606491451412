import qs from 'qs';

import { paths } from '@luxuryescapes/contract-svc-lux-loyalty';

import { arrayToObject } from '~/utils/arrayUtils';
import { isLuxLoyaltyEnabled } from '~/utils/luxLoyalty';

import { request } from './common';

type GetLuxLoyaltyAccountResponse =
  paths['/api/lux-loyalty/account/me']['get']['responses']['200']['content']['application/json']['result']['accountSummary'];

type GetLuxLoyaltyProgramConfigResponse =
  paths['/api/lux-loyalty/program-configuration']['get']['responses']['200']['content']['application/json']['result'];

type GetLuxLoyaltyActivityLogResponse =
  paths['/api/lux-loyalty/account/activities']['get']['responses']['200']['content']['application/json'];

type GetLuxLoyaltyPendingPointsResponse =
  paths['/api/lux-loyalty/customer/{customerId}/order/{orderId}/pending-points']['get']['responses']['200']['content']['application/json'];

const BASE_PATH = `${window.configs.API_HOST}/api/lux-loyalty`;

async function getLuxLoyaltyAccount(customerId: string): Promise<App.LuxLoyaltyAccount | null> {
  if (!customerId || !isLuxLoyaltyEnabled()) {
    return null;
  }

  const queryParams = qs.stringify({ customerId });
  const uri = `${BASE_PATH}/account?${queryParams}`;

  const { result } = await request(uri, { method: 'GET' });
  const accountSummary: GetLuxLoyaltyAccountResponse = result.accountSummary;

  if (!accountSummary) {
    return null;
  }

  return {
    customerId: accountSummary.customerId,
    points: accountSummary.points,
    tier: accountSummary.tier,
    statusCredits: accountSummary.statusCredits,
    ibsMemberId: accountSummary.ibsMemberId,
    accountNumber: accountSummary.accountNumber ?? undefined,
    statusReviewDate: accountSummary.statusReviewDate ?? undefined,
    totalCreditsForTierRetention: accountSummary.totalCreditsForTierRetention ?? undefined,
    totalCreditsForTierUpgrade: accountSummary.totalCreditsForTierUpgrade ?? undefined,
    numberOfNights: accountSummary.numberOfNights,
    numberOfNightsForTierUpgrade: accountSummary.numberOfNightsForTierUpgrade ?? undefined,
    numberOfNightsForTierRetention: accountSummary.numberOfNightsForTierRetention ?? undefined,
    benefits: accountSummary.benefits.map(
      (benefit): App.LuxLoyaltyBenefit => ({
        code: benefit.code,
        description: benefit.description,
        details: {
          // convert 'unlimited' to infinity as it's a lot easier to work with numbers, not number | string
          remaining: benefit.details.remaining === 'unlimited' ? Infinity : benefit.details.remaining,
          limit: benefit.details.limit === 'unlimited' ? Infinity : benefit.details.limit,
          value: benefit.details.value
            ? {
                amount: benefit.details.value.amount,
                unit: benefit.details.value.unit,
                currency: benefit.details.value.currency,
              }
            : undefined,
        },
      }),
    ),
  };
}

function mapLuxLoyaltyProgramConfig(response: GetLuxLoyaltyProgramConfigResponse): App.LuxLoyaltyProgramConfig {
  return {
    programName: response.programName,
    currencyName: response.currencyName,
    statusCreditsName: response.statusCreditsName,
    tierLabel: response.tierLabel,
    tiers: arrayToObject(response.tiers, (tier) => tier.id),
  };
}

async function getLuxLoyaltyProgramConfig(): Promise<GetLuxLoyaltyProgramConfigResponse | null> {
  if (!isLuxLoyaltyEnabled()) {
    return null;
  }

  const uri = `${BASE_PATH}/program-configuration`;

  const { result } = await request(uri, { method: 'GET' });
  return result;
}

async function getLuxLoyaltyActivityLog(
  customerId: string,
  fromDate: string,
  toDate: string,
): Promise<App.LuxLoyaltyActivityLog | null> {
  const queryParams = qs.stringify({ customerId, fromDate, toDate });
  const uri = `${BASE_PATH}/account/activities?${queryParams}`;

  const response: GetLuxLoyaltyActivityLogResponse = await request(uri, { method: 'GET' });
  const { result } = response;

  return result.activities?.map((activity) => ({
    id: activity.id,
    activityDate: activity.activityDate,
    activityType: activity.activityType,
    activityDescription: activity.activityDescription,
    statusCredits: activity.statusCredits,
    points: activity.basePoints,
    numberOfNights: activity.numNights,
    currencyAmount: activity.currencyAmount,
    currency: activity.currency,
    orderId: activity.orderId,
    itemId: activity.itemId,
  }));
}

async function getLuxLoyaltyCustomerPendingPoints(customerId: string): Promise<Array<App.LuxLoyaltyPendingPoint>> {
  const uri = `${BASE_PATH}/customer/${customerId}/pending-points`;
  const response: GetLuxLoyaltyPendingPointsResponse = await request(uri, { method: 'GET' });
  const { result } = response;

  return result.pendingPoints?.map((pendingPoint) => ({
    id: pendingPoint.id,
    createdAt: pendingPoint.createdAt,
    itemId: pendingPoint.itemId,
    orderId: pendingPoint.orderId,
    points: pendingPoint.points,
    statusCredits: pendingPoint.statusCredits,
    pointsEarnDate: pendingPoint.pointsEarnDate ?? undefined,
    status: pendingPoint.status,
  }));
}

export {
  BASE_PATH,
  getLuxLoyaltyAccount,
  getLuxLoyaltyProgramConfig,
  mapLuxLoyaltyProgramConfig,
  getLuxLoyaltyActivityLog,
  getLuxLoyaltyCustomerPendingPoints,
};
