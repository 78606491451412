import React, { useMemo } from 'react';

import { Box, Chip, Typography } from '@mui/material';

import currencyFormatter from '~/utils/currencyFormatter';

import { ConfidenceScoreTier, SurchargeSuggestionBQ } from '../types';

export default function StatisticsFormatter({ row }: { row: SurchargeSuggestionBQ }) {
  const [score, color] = useMemo(() => {
    let score: ConfidenceScoreTier | undefined;
    let color: 'success' | 'warning' | 'error' | undefined;

    if (row.score >= 0.95) {
      score = ConfidenceScoreTier.HIGH;
      color = 'success';
    } else if (row.score >= 0.7) {
      score = ConfidenceScoreTier.MEDIUM;
      color = 'warning';
    } else if (row.score >= 0) {
      score = ConfidenceScoreTier.LOW;
      color = 'error';
    }

    return [score, color];
  }, [row.score]);

  return (
    <Box>
      {score && (
        <Typography variant="body1">
          <Typography component="span" fontWeight="bold">
            Confidence Score:
          </Typography>{' '}
          <Chip component="span" label={score} size="small" color={color} />
        </Typography>
      )}
      <Typography variant="body1">
        <Typography component="span" fontWeight="bold">
          Sales last 3 days:
        </Typography>{' '}
        {row.sales_last_3_days}
      </Typography>
      <Typography variant="body1">
        <Typography component="span" fontWeight="bold">
          Booked / Allocated:
        </Typography>{' '}
        {row.booked + ' / ' + row.allocated}
      </Typography>
      <Typography variant="body1">
        <Typography component="span" fontWeight="bold">
          Current Vendor Surcharge:
        </Typography>{' '}
        {currencyFormatter('AUD', row.vendor_surcharge_total, 2)}
      </Typography>
      <Typography variant="body1">
        <Typography component="span" fontWeight="bold">
          Current Internal Surcharge:
        </Typography>{' '}
        {currencyFormatter('AUD', row.internal_surcharge_total, 2)}
      </Typography>
      <Typography variant="body1">
        <Typography component="span" fontWeight="bold">
          Current Base Nightly Price:
        </Typography>{' '}
        {currencyFormatter('AUD', row.nightly_price_aud, 2)}
      </Typography>
      <Typography variant="body1">
        <Typography component="span" fontWeight="bold">
          Suggested Multiplier:
        </Typography>{' '}
        {row.multiplier}
      </Typography>
      <Typography variant="body1">
        <Typography component="span" fontWeight="bold">
          Current Nightly Price:
        </Typography>{' '}
        {currencyFormatter('AUD', row.before_price, 2)}
      </Typography>
      <Typography variant="body1">
        <Typography component="span" fontWeight="bold">
          Suggested Nightly Price:
        </Typography>{' '}
        {currencyFormatter('AUD', row.after_price, 2)}
      </Typography>
      <Typography variant="body1">
        <Typography component="span" fontWeight="bold">
          Suggested Surcharge:
        </Typography>{' '}
        {currencyFormatter('AUD', row.after_price - row.before_price, 2)}
      </Typography>
    </Box>
  );
}
