import React, { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import { createBannerCampaign } from '~/services/SupportService';

import BannerEditForm from './BannerEditForm';
import { FormInput, Target } from './common';

export function SiteBannerCampaignCreate() {
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm<FormInput>({
    defaultValues: {
      campaignTitle: '',
      startDate: undefined,
      endDate: undefined,
      bannerTitle: '',
      bannerOverview: '',
      bannerAppearance: {
        icon: 'check',
        color: 'info',
      },
      modalTitle: '',
      modalDescription: '',
      targets: [],
    },
  });

  const {
    fields: criteria,
    append,
    remove,
  } = useFieldArray({
    control: methods.control,
    name: 'targets',
  });

  const appendCriterion = useCallback(
    (criterion: '' | Target) => {
      if (criterion !== '') {
        append(criterion);
      }
    },
    [append],
  );

  const history = useHistory();

  const onSubmit = useCallback(
    async (data: FormInput, status: 'DRAFT' | 'ACTIVE') => {
      const formattedData = {
        campaignTitle: data.campaignTitle,
        bannerTitle: data.bannerTitle,
        bannerOverview: data.bannerOverview,
        startDate: data.startDate,
        endDate: data.endDate,
        modalTitle: data.modalTitle,
        modalDescription: data.modalDescription,
        targets: data.targets,
        status,
        settings: {
          bannerColor: data.bannerAppearance.color,
          bannerIcon: data.bannerAppearance.icon,
        },
      };

      try {
        await createBannerCampaign(formattedData);
        enqueueSnackbar('Successfully created template banner.', { variant: 'success' });
        history.push('/system/targeted-banner-campaigns');
      } catch (error) {
        enqueueSnackbar('Failed to create template banner. Please try again!', { variant: 'error' });
      }
    },
    [enqueueSnackbar, history],
  );

  const onSubmitCreate: SubmitHandler<FormInput> = useCallback(
    (data: FormInput) => {
      return onSubmit(data, 'ACTIVE');
    },
    [onSubmit],
  );

  const onSubmitDraft: SubmitHandler<FormInput> = useCallback(
    (data: FormInput) => {
      return onSubmit(data, 'DRAFT');
    },
    [onSubmit],
  );

  return (
    <>
      <Helmet>
        <title>Create a New Personalised Banner</title>
      </Helmet>

      <Container
        maxWidth="xl"
        sx={{
          paddingBottom: 0,
        }}
      >
        <PageHeader
          title=""
          subtitle="Create a New Personalised Banner"
          backButton="/system/targeted-banner-campaigns"
        ></PageHeader>

        <FormProvider {...methods}>
          <BannerEditForm
            mode="create"
            onSubmit={onSubmitCreate}
            onSubmitDraft={onSubmitDraft}
            criteria={criteria}
            appendCriterion={appendCriterion}
            removeCriterion={remove}
          />
        </FormProvider>
      </Container>
    </>
  );
}
