import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import uniqBy from 'lodash/uniqBy';
import { useSelector } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';

import { Alert, Autocomplete, Box, Button, Paper, Stack, TextField, Typography, debounce } from '@mui/material';

import { Experiences } from '@luxuryescapes/contract-svc-experience';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import Spinner from '~/components/Common/Spinner';
import { useDismissible } from '~/components/Experiences/hooks';

import {
  UpdateRegionHeroesPayload,
  getExperienceOfferById,
  getHeroesByRegion,
  updateRegionHeroes,
} from '~/services/ExperiencesService';
import SearchService, { TypeaheadBrand } from '~/services/SearchService';

import HeroesListItem from './HeroesListItem';

type ContentProps = {
  selectedExperiences: Experiences.Offer[];
  onRemoveExperience: (experience: Experiences.Offer) => void;
  onLoadSelectedExperiences: (experiences: Experiences.Offer[]) => void;
};

type DismissibleAlert = {
  type: 'success' | 'error';
  message: string;
};

type CityOption = { label: string; placeId: string; region: UpdateRegionHeroesPayload['region'] };

export type HeroesListRef = {
  validate: () => boolean;
};

const HeroesList = forwardRef((props: ContentProps, ref: React.Ref<HeroesListRef>) => {
  const { selectedExperiences, onRemoveExperience, onLoadSelectedExperiences } = props;

  const brand = useSelector((state: App.State) => state.tenant.brand);

  const [selectedCity, setSelectedCity] = useState<CityOption | undefined>();
  const [cities, setCities] = useState<CityOption[]>([]);

  const [isLoading, setLoading] = useState(false);
  const [isSearchingCities, setIsSearchingCities] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [alert, setAlert, dismissAlert] = useDismissible<DismissibleAlert>({
    autoDismissAfterMs: 3000,
  });

  useImperativeHandle(ref, () => ({
    validate: () => {
      if (!selectedCity) {
        setErrorMessage('This field is required');
        return false;
      }
      return true;
    },
  }));

  const editHero = async () => {
    if (!selectedCity) return;
    dismissAlert();
    setLoading(true);
    try {
      const payload = {
        experiencesOffersIds: selectedExperiences.map((item) => item.id),
        // Do a type assertion cause the brands list here in admin has more brands that svc-experience contract
        brand: brand as UpdateRegionHeroesPayload['brand'],
        region: selectedCity.region,
      };

      await updateRegionHeroes(selectedCity.placeId, payload);

      setAlert({ type: 'success', message: 'Changed heroes with success!' });
    } catch (error) {
      console.error('ERROR ON UPDATE HERO: ', error);
      setAlert({
        type: 'error',
        message: `${error.status}: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchHeroes = async (): Promise<void> => {
    dismissAlert();

    if (!selectedCity) return setLoading(false);

    try {
      setLoading(true);
      const res = await getHeroesByRegion(selectedCity.region, brand, selectedCity.placeId);
      if (res.errors?.length > 0)
        return setAlert({
          type: 'error',
          message: "[ERROR] Don't exists heroes response.",
        });

      const { experiencesOffersIds } = res.result;
      const experienceOffersRequests = await Promise.all(
        experiencesOffersIds.map((id) => getExperienceOfferById({ id, brand, curationData: true })),
      );

      if (experienceOffersRequests.some((res) => res.errors?.length))
        return setAlert({
          type: 'error',
          message: "[ERROR] Don't exists heroes response.",
        });
      const experiences = experienceOffersRequests.flatMap((res) => res.result);
      onLoadSelectedExperiences(experiences);
      setLoading(false);
    } catch (error) {
      console.error('ERROR ON FETCH HEROES: ', error);
      if (error.status === 404) return;
      setAlert({
        type: 'error',
        message: `${error.status}: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onLoadSelectedExperiences([]);
    setErrorMessage(undefined);
    if (!selectedCity) return;
    fetchHeroes();
  }, [selectedCity, brand]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    onLoadSelectedExperiences(arrayMove(selectedExperiences, oldIndex, newIndex));
  };

  const [searchCity, setSearchCity] = useState('');

  const debouncedSearch = useMemo(
    () =>
      debounce(async (search: string) => {
        const res = await SearchService.typeahead(search, {
          type: ['city', 'multi_city_vicinity'],
          brand: brand.toLowerCase() as TypeaheadBrand,
          region: 'AU',
          limit: 200,
          priority: 'current',
        }).finally(() => setIsSearchingCities(false));
        const cities = uniqBy(res.result.results, 'c').map((result) => ({
          label: `${result.primary_text} (${result.c || result.b || result.a})`,
          placeId: result.fk,
          region: result.country_code as UpdateRegionHeroesPayload['region'],
        }));
        setCities(cities);
      }, 1000),
    [brand, setCities],
  );

  useEffect(() => {
    if (!searchCity) return;
    setIsSearchingCities(true);
    setCities([]);

    debouncedSearch(searchCity);

    return () => {
      // Cancel the debounced search
      debouncedSearch.clear();
    };
  }, [debouncedSearch, searchCity]);

  return (
    <Paper>
      <PageSubheader title={'Curated list'}></PageSubheader>

      <Box>
        <Autocomplete
          freeSolo
          openOnFocus
          options={cities}
          loading={isSearchingCities}
          onInputChange={(_, value) => {
            setSearchCity(value);
          }}
          onChange={(_, value: CityOption | undefined) => {
            setSelectedCity(value);
          }}
          value={selectedCity}
          renderInput={(params) => (
            <TextField error={!!errorMessage} helperText={errorMessage} {...params} label="Search for a city" />
          )}
          filterOptions={(x) => x}
        />
      </Box>

      <Box>
        {isLoading ? (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Spinner size={32} />
          </Stack>
        ) : (
          selectedCity && (
            <Stack direction="column" spacing={2} marginTop={2}>
              {selectedExperiences.length > 3 && (
                <Alert severity="warning" style={{ textAlign: 'center' }}>
                  In the <strong>hotel flow</strong>, only the 3 best offers available in this list will be shown.
                </Alert>
              )}
              {selectedExperiences.length ? (
                <HeroesListItem
                  distance={1}
                  items={selectedExperiences}
                  onSortEnd={onSortEnd}
                  removeOnclick={onRemoveExperience}
                />
              ) : (
                <Typography>No offers have been added yet for this city</Typography>
              )}

              <Box>
                <Button variant="contained" onClick={editHero} disabled={!selectedCity}>
                  Save
                </Button>
              </Box>
            </Stack>
          )
        )}
      </Box>

      <Box>
        {alert && (
          <Alert severity={alert.type} style={{ textAlign: 'center' }}>
            <strong>{alert.message}</strong>
          </Alert>
        )}
      </Box>
    </Paper>
  );
});

export default HeroesList;
