import React, { useCallback, useMemo } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { Control, RegisterOptions, useController } from 'react-hook-form';

import { DatePicker, DatePickerProps } from '@mui/x-date-pickers-pro';

type Props = Omit<DatePickerProps<Dayjs>, 'value' | 'onChange'> & {
  name: string;
  control: Control<any>;
  fullWidth?: boolean;
  rules?: RegisterOptions;
};

/** For use with react-hook-form */
export default function DateWidget({ name, control, fullWidth, rules, ...props }: Props) {
  const {
    field: { onChange, value, ...field },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
  });

  const dateObj = useMemo(() => value && dayjs(value), [value]);

  const handleChange = useCallback(
    (date: Dayjs | null) => {
      onChange(date?.format('YYYY-MM-DD'));
    },
    [onChange],
  );

  return (
    <DatePicker
      slotProps={{ textField: { fullWidth, error: !!error } }}
      format="DD/MM/YYYY"
      {...props}
      {...field}
      value={dateObj}
      onChange={handleChange}
    />
  );
}
