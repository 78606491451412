import qs from 'qs';

import { GridPaginationModel } from '@mui/x-data-grid';

import { BusinessResponses } from '@luxuryescapes/contract-business';

import { FrequentFlyerItemType } from '~/components/LeBusinessTraveller/pages/BusinessDetailsPage/Tabs/FrequentFlyerTab';

import { json_headers, request } from '~/services/common';

export function businessTravellerBasePath() {
  return window.configs.API_HOST + '/api/business-traveller';
}
interface OkResponse<T> {
  status: number;
  meta?: {
    limit: number;
    page: number;
    total: number;
  };
  result: Array<T>;
}

interface Params {
  pageSize: number;
  page: number;
}

// BUSINESS REQUESTS
export async function getBusiness(id: string): Promise<App.Business> {
  const url = `${businessTravellerBasePath()}/businesses/${id}`;
  const response: BusinessResponses.BusinessIndex = await request(url, { method: 'GET' });
  return response.result;
}

export async function getBusinessList(
  limit: number,
  page: number,
  nameString?: string,
  businessId?: string,
): Promise<OkResponse<App.Business>> {
  const queryStrings = businessId ? { limit, page, businessId } : { limit, page, businessName: nameString };
  const url = `${businessTravellerBasePath()}/businesses?${qs.stringify(queryStrings)}`;
  const response: BusinessResponses.BusinessList = await request(url, { method: 'GET' });
  return response;
}

type BusinessPatch = Omit<App.Business, 'id' | 'email'>;

export async function patchBusiness(id: string, business: App.Business): Promise<void> {
  const url = `${businessTravellerBasePath()}/businesses/${id}`;

  const businessPatch: BusinessPatch = {
    businessIdentifier: business.businessIdentifier,
    country: business.country,
    industry: business.industry,
    name: business.name,
    phone: business.phone,
    employeeCount: business.employeeCount,
    enableMerchantFees: business.enableMerchantFees,
  };

  return request(url, {
    body: JSON.stringify(businessPatch),
    headers: json_headers,
    method: 'PATCH',
  });
}

// EMPLOYEE REQUESTS
export async function getEmployee(customerId: string): Promise<App.BusinessEmployee> {
  const url = `${businessTravellerBasePath()}/employees?${qs.stringify({ customerId })}`;
  const response: BusinessResponses.EmployeeIndex = await request(url, { method: 'GET' });
  return response.result;
}

export async function getEmployeeList(businessId: string, params: Params): Promise<OkResponse<App.BusinessEmployee>> {
  const limit = params.pageSize;
  const page = params.page;

  const url = `${businessTravellerBasePath()}/businesses/${businessId}/employees?${qs.stringify({
    limit,
    page,
  })}`;

  const response: BusinessResponses.EmployeeListIndex = await request(url, { method: 'GET' });
  return response;
}

export async function getEmployeeSearch(
  businessId: string,
  searchString: string,
  params: Params,
): Promise<OkResponse<App.BusinessEmployee>> {
  const limit = params.pageSize;
  const page = params.page;

  const url = `${businessTravellerBasePath()}/businesses/${businessId}/employees?${qs.stringify({
    searchQuery: searchString,
    limit,
    page,
  })}`;

  const response: BusinessResponses.EmployeeListIndex = await request(url, { method: 'GET' });
  return response;
}

export async function getBusinessAdminSpoofingLink(params: { customerId: string }): Promise<string> {
  const response = await request(`${window.configs.AUTH_HOST}/api/obtain-token`, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify({
      customer_id: params.customerId,
      verbose: true, // required to include the access_token
    }),
  });

  const apiUrl = new URL('/redirect', window.configs.LEBUSINESSTRAVELLER_API_DOMAIN);
  apiUrl.searchParams.set('url', window.configs.LEBUSINESSTRAVELLER_ADMIN_PORTAL);
  apiUrl.searchParams.set('token', response.result.access_token);

  return apiUrl.toString();
}

// ORDER ITEM CREDIT REQUESTS
export async function getOrderCreditItem(orderId, orderItemId: string): Promise<BusinessResponses.OrderItemFetch> {
  const url = `${businessTravellerBasePath()}/order/${orderId}/order-items?orderItemId=${orderItemId}`;
  return await request(url, { method: 'GET' });
}

export async function applyOrderCreditItem(orderItemId: string): Promise<BusinessResponses.ItemCreditApplied> {
  const url = `${businessTravellerBasePath()}/order-items/apply-credit`;
  return await request(url, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify({ orderItemId: orderItemId }),
  });
}

export async function getFrequentFlyerList(businessId: string) {
  const url = `${businessTravellerBasePath()}/businesses/${businessId}/frequent-flyer`;

  const response: BusinessResponses.FrequentFlyerListIndex = await request(url, { method: 'GET' });
  return response;
}

export async function createFrequentFlyer(frequentFlyerList: Array<FrequentFlyerItemType>, businessId: string) {
  const url = `${businessTravellerBasePath()}/businesses/${businessId}/frequent-flyer`;

  return await request(url, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify(frequentFlyerList),
  });
}

export async function patchFrequentFlyerList(frequentFlyerList: Array<FrequentFlyerItemType>, businessId: string) {
  const url = `${businessTravellerBasePath()}/businesses/${businessId}/frequent-flyer`;

  return await request(url, {
    method: 'PATCH',
    headers: json_headers,
    body: JSON.stringify(frequentFlyerList),
  });
}

export async function deleteFrequentFlyer(frequentFlyerId: string, businessId: string) {
  const url = `${businessTravellerBasePath()}/businesses/${businessId}/frequent-flyer/${frequentFlyerId}`;

  return await request(url, {
    method: 'DELETE',
    headers: json_headers,
  });
}

// SERVICE FEE REQUESTS

export async function getServiceFee(businessId: string, serviceFeeId: string): Promise<App.BusinessServiceFee> {
  const url = `${businessTravellerBasePath()}/businesses/${businessId}/service-fees/${serviceFeeId}`;
  const response = await request(url, { method: 'GET' });
  return response.result;
}

export async function getServiceFeeList(
  businessId: string,
  paginationModel: GridPaginationModel,
): Promise<OkResponse<App.BusinessServiceFee>> {
  const { page, pageSize: limit } = paginationModel;
  const queryStrings = { limit, page, businessId };
  const url = `${businessTravellerBasePath()}/businesses/${businessId}/service-fees?${qs.stringify(queryStrings)}`;
  const response = await request(url, { method: 'GET' });
  return response;
}

type BusinessServiceFeePayload = Omit<App.BusinessServiceFee, 'id' | 'businessId'>;

export async function createServiceFee(serviceFee: BusinessServiceFeePayload, businessId: string) {
  const url = `${businessTravellerBasePath()}/businesses/${businessId}/service-fees`;

  return await request(url, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify(serviceFee),
  });
}

export async function patchServiceFee(serviceFee: BusinessServiceFeePayload, businessId: string, serviceFeeId: string) {
  const url = `${businessTravellerBasePath()}/businesses/${businessId}/service-fees/${serviceFeeId}`;

  return await request(url, {
    method: 'PATCH',
    headers: json_headers,
    body: JSON.stringify(serviceFee),
  });
}

export async function resetServiceFees(businessId: string) {
  const url = `${businessTravellerBasePath()}/businesses/${businessId}/service-fees/reset`;

  return await request(url, {
    method: 'POST',
    headers: json_headers,
  });
}
