import React from 'react';

import { Box, Card, CardContent, Grid, Typography, useTheme } from '@mui/material';

import { Rate } from '~/services/AccommodationService';

interface RoomFacilitiesModalProps {
  rate: Rate;
  roomName: string;
}

export default function RoomFacilitiesModal({ rate, roomName }: RoomFacilitiesModalProps) {
  const theme = useTheme();

  return (
    <Box>
      {rate?.meta && 'facilities' in rate.meta && (
        <>
          <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
            Room Facilities for {roomName || 'this room'}
          </Typography>

          <Grid container spacing={2}>
            {rate.meta.facilities.map((facility, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card
                  variant="outlined"
                  sx={{
                    mb: 1,
                    bgcolor: theme.palette.success.light,
                  }}
                >
                  <CardContent sx={{ py: 1 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        color: theme.palette.text.primary,
                      }}
                    >
                      ✓ {facility}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
      {(!rate?.meta || !('facilities' in rate.meta)) && (
        <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
          No facilities available for this room
        </Typography>
      )}
    </Box>
  );
}
