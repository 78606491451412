import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';

import { Button, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import PermissionComponent from '~/components/Common/PermissionedComponent';
import BusinessServiceFeesCreateEditModal from '~/components/LeBusinessTraveller/components/serviceFees/BusinessServiceFeesCreateEditModal';
import BusinessServiceFeesResetModal from '~/components/LeBusinessTraveller/components/serviceFees/BusinessServiceFeesResetModal';
import ServiceFeeGridActions from '~/components/LeBusinessTraveller/components/serviceFees/ServiceFeeGridActions';

import useDataGridPagination from '~/hooks/mui/useDataGridPagination';
import useToggleState from '~/hooks/useToggleState';

import { getServiceFeeList } from '~/services/BusinessTraveller/BusinessTravellerService';

interface Params {
  businessId: string;
}

export default function ServiceFeeTab() {
  const { businessId } = useParams<Params>();

  const { enqueueSnackbar } = useSnackbar();
  const { paginationModel, setPaginationModel } = useDataGridPagination({
    pageSize: 25,
    page: 0,
  });

  const [serviceFees, setServiceFees] = useState<Array<App.BusinessServiceFee>>([]);
  const [serviceFeeTotal, setServiceFeeTotal] = useState(0);

  const { isToggled: isCreateDialogOpen, toggleOn: openCreateDialog, toggleOff: closeCreateDialog } = useToggleState();
  const { isToggled: isResetDialogOpen, toggleOn: openResetDialog, toggleOff: closeResetDialog } = useToggleState();

  async function fetchServiceFees() {
    try {
      const result = await getServiceFeeList(businessId, paginationModel);
      setServiceFees(result.result);
      setServiceFeeTotal(result.meta.total);
    } catch (error) {
      const message = error instanceof Error ? error.message : JSON.stringify(error, null, 2);
      enqueueSnackbar(`Error fetching service fees [${message}]`, {
        variant: 'error',
      });
    }
  }

  const columns: Array<GridColDef> = [
    { field: 'source', headerName: 'Source', flex: 1 },
    { field: 'type', headerName: 'Type', flex: 1 },
    { field: 'destination', headerName: 'Destination', flex: 1 },
    { field: 'product', headerName: 'Product', flex: 1 },
    { field: 'amount', headerName: 'Amount', flex: 1 },
    { field: 'conciergeAmount', headerName: 'Concierge Amount', flex: 1 },
    {
      field: 'context-menu',
      type: 'actions',
      headerName: '',
      sortable: false,
      getActions: (params) => [
        <ServiceFeeGridActions serviceFee={params.row} onSuccess={fetchServiceFees} key={params.row.id} />,
      ],
    },
  ];

  useEffect(() => {
    fetchServiceFees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel]);

  const fallback = (
    <Stack gap={2}>
      <Typography variant="h4">Permission required</Typography>
      <Typography variant="body2">You are not authorized to view this page</Typography>
    </Stack>
  );

  return (
    <PermissionComponent fallback={fallback}>
      <Stack gap={2}>
        <Grid2 container spacing={2}>
          <Grid2 xs={12}>
            <Stack direction="row" justifyContent="end" alignItems="center" gap={2}>
              <Button variant="contained" color="primary" onClick={openCreateDialog}>
                Create Service Fee
              </Button>
              <Button variant="contained" color="primary" onClick={openResetDialog}>
                {serviceFees.length > 0 ? 'Reset To Default' : 'Add Default Fees'}
              </Button>
              <BusinessServiceFeesCreateEditModal
                businessId={businessId}
                isOpen={isCreateDialogOpen}
                onClose={closeCreateDialog}
                onSuccess={fetchServiceFees}
                mode="create"
              />
              <BusinessServiceFeesResetModal
                businessId={businessId}
                serviceFeeCount={serviceFees.length}
                isOpen={isResetDialogOpen}
                onClose={closeResetDialog}
                onSuccess={fetchServiceFees}
              />
            </Stack>
          </Grid2>
          <Grid2 xs={12}>
            <DataGrid
              columns={columns}
              onPaginationModelChange={setPaginationModel}
              paginationMode="server"
              paginationModel={paginationModel}
              rows={serviceFees || []}
              rowCount={serviceFeeTotal}
            />
          </Grid2>
        </Grid2>
      </Stack>
    </PermissionComponent>
  );
}
