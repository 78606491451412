import React, { useCallback, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RocketIcon from '@mui/icons-material/Rocket';
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

import ConfirmButton from '~/components/Common/Elements/ConfirmButton';

import { SiteBannerCampaign, getBannerCampaignsList, updateBannerCampaign } from '~/services/SupportService';
import { formatDateSlashes } from '~/services/TimeService';

import { capitalise } from '~/utils/stringUtils';

import PageHeader from '../../Common/Elements/PageHeader';

import StatusChip from './StatusChip';

const STATUS = {
  ACTIVE: 'ACTIVE' as const,
  DRAFT: 'DRAFT' as const,
  ARCHIVED: 'ARCHIVED' as const,
};

function SiteBannerCampaignsPage() {
  const [campaigns, setCampaigns] = useState<Array<SiteBannerCampaign>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const history = useHistory();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const fetchCampaigns = useCallback(() => {
    setLoading(true);
    try {
      getBannerCampaignsList(page + 1, rowsPerPage, brand).then((result) => {
        setCampaigns(result.banners);
        setTotalRows(result.count);
      });
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }, [brand, page, rowsPerPage]);

  const navigateToCreateCampaign = useCallback(() => {
    history.push('/system/targeted-banner-campaigns/create');
  }, [history]);

  useEffect(() => {
    fetchCampaigns();
  }, [fetchCampaigns]);

  const archiveCampaign = async (id: string) => {
    try {
      await updateBannerCampaign(id, { status: STATUS.ARCHIVED });
      fetchCampaigns();
    } catch (e) {
      setError(e.message);
    }
  };

  const launchCampaign = async (id: string) => {
    try {
      await updateBannerCampaign(id, { status: STATUS.ACTIVE });
      fetchCampaigns();
    } catch (e) {
      setError(e.message);
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <>
      <Helmet>
        <title>Targeted banners</title>
      </Helmet>

      <Container maxWidth="xl">
        <PageHeader title="" subtitle="Targeted Banners">
          <Button variant="outlined" onClick={navigateToCreateCampaign}>
            Create new banner
          </Button>
        </PageHeader>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Banner name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Content type</TableCell>
                <TableCell>Start date</TableCell>
                <TableCell>End date</TableCell>
                <TableCell>Manage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {error && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Alert severity="error">{error}</Alert>
                  </TableCell>
                </TableRow>
              )}
              {loading && !campaigns?.length && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
              {campaigns.map((campaign) => (
                <TableRow key={campaign.id}>
                  <TableCell>{campaign.campaignTitle}</TableCell>
                  <TableCell>
                    <StatusChip status={campaign.status} />
                  </TableCell>
                  <TableCell>{capitalise(campaign.contentType)}</TableCell>
                  <TableCell>{formatDateSlashes(campaign.startDate)}</TableCell>
                  <TableCell>{formatDateSlashes(campaign.endDate)}</TableCell>
                  <TableCell>
                    <Link to={`/system/targeted-banner-campaigns/${campaign.id}`}>
                      <IconButton aria-label="edit" title="Edit">
                        <EditIcon />
                      </IconButton>
                    </Link>
                    {campaign.status !== STATUS.ARCHIVED && (
                      <ConfirmButton
                        asIcon
                        aria-label="archive"
                        title="Archive"
                        confirmQuestion="This will archive this banner. Are you sure?"
                        onConfirm={() => archiveCampaign(campaign.id)}
                      >
                        <DeleteIcon />
                      </ConfirmButton>
                    )}
                    {campaign.status === STATUS.DRAFT && (
                      <ConfirmButton
                        asIcon
                        aria-label="launch"
                        title="Launch"
                        confirmQuestion="This will launch this banner. Are you sure?"
                        onConfirm={() => launchCampaign(campaign.id)}
                      >
                        <RocketIcon />
                      </ConfirmButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Container>
    </>
  );
}

export default SiteBannerCampaignsPage;
