import React, { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';

import { Container } from '@mui/material';

import { SiteBannerCampaign, updateBannerCampaign } from '~/services/SupportService';
import { formatDateISO } from '~/services/TimeService';

import PageHeader from '../../Common/Elements/PageHeader';

import BannerEditForm from './BannerEditForm';
import StatusChip from './StatusChip';
import { FormInput, Target } from './common';

interface Props {
  campaign: SiteBannerCampaign;
}

function SiteBannerCampaignEdit({ campaign }: Props) {
  const {
    params: { campaignId },
  } = useRouteMatch<{ campaignId: string }>();

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const methods = useForm<FormInput>({
    defaultValues: {
      campaignTitle: campaign.campaignTitle,
      startDate: formatDateISO(campaign.startDate),
      endDate: formatDateISO(campaign.endDate),
      bannerTitle: campaign.bannerTitle,
      bannerOverview: campaign.bannerOverview,
      bannerAppearance: {
        icon: campaign.settings?.bannerIcon || 'check',
        color: campaign.settings?.bannerColor || 'info',
      },
      modalTitle: campaign.modalTitle,
      modalDescription: campaign.modalDescription,
      targets: campaign.targets as Array<Target>,
    },
  });

  const {
    fields: criteria,
    append,
    remove,
  } = useFieldArray({
    control: methods.control,
    name: 'targets',
  });

  const appendCriterion = useCallback(
    (criterion: '' | Target) => {
      if (criterion !== '') {
        append(criterion);
      }
    },
    [append],
  );

  const onSubmit: SubmitHandler<FormInput> = useCallback(
    async (data: FormInput) => {
      const formattedData = {
        campaignTitle: data.campaignTitle,
        bannerTitle: data.bannerTitle,
        bannerOverview: data.bannerOverview,
        startDate: data.startDate,
        endDate: data.endDate,
        modalTitle: data.modalTitle,
        modalDescription: data.modalDescription,
        targets: data.targets,
        settings: {
          bannerColor: data.bannerAppearance.color,
          bannerIcon: data.bannerAppearance.icon,
        },
      };

      try {
        await updateBannerCampaign(campaignId, formattedData);
        enqueueSnackbar('Successfully updated banner campaign.', { variant: 'success' });
        history.push('/system/targeted-banner-campaigns');
      } catch (error) {
        enqueueSnackbar('Failed to update banner campaign. Please try again!', { variant: 'error' });
      }
    },
    [campaignId, enqueueSnackbar, history],
  );

  return (
    <>
      <Helmet>
        <title>Site banner campaign edit</title>
      </Helmet>

      <Container
        maxWidth="xl"
        sx={{
          paddingBottom: 0,
        }}
      >
        <PageHeader
          title=""
          subtitle={`Edit Banner "${campaign.campaignTitle}"`}
          backButton="/system/targeted-banner-campaigns"
        >
          <StatusChip status={campaign.status} />
        </PageHeader>

        <FormProvider {...methods}>
          <BannerEditForm
            mode="update"
            onSubmit={onSubmit}
            criteria={criteria}
            appendCriterion={appendCriterion}
            removeCriterion={remove}
          />
        </FormProvider>
      </Container>
    </>
  );
}

export default SiteBannerCampaignEdit;
