import React from 'react';

import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import { Box, Container, Tab, Tabs } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import BusinessTab from './Tabs/BusinessTab';
import EmployeesTab from './Tabs/EmployeesTab';
import FrequentFlyerTab from './Tabs/FrequentFlyerTab';
import OrderTab from './Tabs/OrdersTab';
import ServiceFeeTab from './Tabs/ServiceFeeTab';

const tabsMap = {
  business: {
    component: BusinessTab,
    label: 'Business',
  },
  serviceFee: {
    component: ServiceFeeTab,
    label: 'Service Fees',
  },
  employees: {
    component: EmployeesTab,
    label: 'Employees',
  },
  frequentFlyer: {
    component: FrequentFlyerTab,
    label: 'Frequent Flyer',
  },
  orders: {
    component: OrderTab,
    label: 'Orders',
  },
} as const;

const tabsList = Object.keys(tabsMap);

type Tab = keyof typeof tabsMap;

export default function BusinessTabPage(props: RouteComponentProps<{ businessId: string; tab: Tab }>) {
  const businessId = props.match.params.businessId;
  const key = props.match.params.tab || 'business';

  const currentTab = tabsMap[key];

  const title = `Business / ${currentTab.label}`;

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageHeader title={title} />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={key}>
          {tabsList.map((tab) => (
            <Tab
              component={Link}
              key={tab}
              label={tabsMap[tab].label}
              to={`/lebusiness/${businessId}/${tab}`}
              value={tab}
            />
          ))}
        </Tabs>
      </Box>

      <Box mt={4}>
        <Box component={currentTab.component} />
      </Box>
    </Container>
  );
}
