import React, { useCallback } from 'react';

import { SubmitHandler, useFormContext } from 'react-hook-form';
import { MarkdownPreview } from 'react-marked-markdown';
import { Link } from 'react-router-dom';

import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import PlaceLookUp from '~/components/Common/Forms/PlaceLookUp';
import DateWidget from '~/components/Common/Forms/fields/DateWidget';
import Input from '~/components/Common/Forms/fields/Input';
import RichTextEditor from '~/components/Common/Forms/fields/RichTextEditor';
import Select from '~/components/Common/Forms/fields/Select';

import { TypeaheadSearchResult } from '~/services/SearchService';
import { newDate } from '~/services/TimeService';

import {
  TYPEAHEAD_COUNTRY_TYPES_AIRPORT,
  TYPEAHEAD_COUNTRY_TYPES_COUNTRY,
  TYPEAHEAD_COUNTRY_TYPES_REGION,
} from '../PropertyLocationOverride/constants';

import { BannerPreviewIcon } from './BannerPreviewIcon';
import { CRITERIA, FormInput, Target, TargetDictionary } from './common';

interface Props {
  onSubmit: SubmitHandler<FormInput>;
  onSubmitDraft?: SubmitHandler<FormInput>;
  criteria: Array<Target>;
  appendCriterion: (criterion: Target) => void;
  removeCriterion: (index: number) => void;
  mode: 'create' | 'update';
}

function BannerEditForm({ onSubmit, onSubmitDraft, criteria, appendCriterion, removeCriterion, mode }: Props) {
  const { control, handleSubmit, reset, watch, setValue } = useFormContext<FormInput>();

  const handleLookUpChange = useCallback(
    (index: number) => (selection: TypeaheadSearchResult | undefined) => {
      setValue(`targets.${index}.value`, selection?.format.mainText || '');
    },
    [setValue],
  );

  return (
    <Grid container spacing={2}>
      {/* left side - input elements */}
      <Grid
        item
        xs={5}
        sx={{
          overflowY: 'scroll',
          height: 'calc(100vh - 210px)',
        }}
      >
        <Stack direction="column" spacing={2}>
          <Stack direction="column" spacing={1}>
            <Typography variant="h6">Banner Details</Typography>
            <Input
              control={control}
              name="campaignTitle"
              label="Banner Name"
              required
              fullWidth
              rules={{
                required: 'Banner name is required',
              }}
            />
            {/* The start and end date row */}

            <Stack direction="row" spacing={2}>
              <DateWidget
                name="startDate"
                label="Start Date"
                control={control}
                fullWidth
                disablePast
                rules={{
                  required: 'Start date is required',
                  validate: (value) => {
                    if (value && newDate(value).isAfter(newDate(watch('endDate')))) {
                      return 'Start date must be before end date';
                    }
                    return true;
                  },
                }}
              />
              <DateWidget
                name="endDate"
                label="End Date"
                control={control}
                fullWidth
                disablePast
                minDate={watch(`startDate`) ? newDate(watch(`startDate`)) : undefined}
                rules={{
                  required: 'End date is required',
                }}
              />
            </Stack>
          </Stack>
          <Stack direction="column" spacing={1}>
            <Box
              sx={{
                marginTop: 2,
              }}
            >
              <Typography variant="h6">Banner Content</Typography>
            </Box>
            <Input
              control={control}
              name="bannerTitle"
              label="Banner Text Bold"
              inputProps={{
                placeholder: 'Warning: ',
              }}
              rules={{
                required: 'Banner text is required',
              }}
              required
              fullWidth
            />
            <Input
              control={control}
              name="bannerOverview"
              label="Banner Text"
              rows={3}
              inputProps={{
                placeholder: 'Alfredo is coming.',
              }}
              fullWidth
            />
            <Stack direction="row" spacing={2}>
              <Select control={control} name="bannerAppearance.icon" label="Banner Icon">
                <MenuItem value="check">
                  <Stack direction="row" spacing={1}>
                    <CheckIcon fontSize="small" />
                    <span>Check</span>
                  </Stack>
                </MenuItem>
                <MenuItem value="info">
                  <Stack direction="row" spacing={2}>
                    <InfoIcon fontSize="small" />
                    <span>Info</span>
                  </Stack>
                </MenuItem>
                <MenuItem value="warning">
                  <Stack direction="row" spacing={2}>
                    <WarningIcon fontSize="small" />
                    <span>Warning</span>
                  </Stack>
                </MenuItem>
                <MenuItem value="error">
                  <Stack direction="row" spacing={2}>
                    <ErrorIcon fontSize="small" />
                    <span>Error</span>
                  </Stack>
                </MenuItem>
              </Select>
              <Select control={control} name="bannerAppearance.color" label="Banner Color">
                <MenuItem value="info">
                  <span style={{ color: '#0288d1' }}>Info</span>
                </MenuItem>
                <MenuItem value="warning">
                  <span style={{ color: '#ed6c02' }}>Warning</span>
                </MenuItem>
                <MenuItem value="error">
                  <span style={{ color: '#d32f2f' }}>Error</span>
                </MenuItem>
                <MenuItem value="success">
                  <span style={{ color: '#2e7d32' }}>Success</span>
                </MenuItem>
              </Select>
            </Stack>
            <Input control={control} name="modalTitle" label="Modal Title" fullWidth />
            <RichTextEditor control={control} name="modalDescription" label="Modal Description" />
          </Stack>
        </Stack>
        <Stack direction="column" spacing={1} mt={1}>
          <Box
            mt={1}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" display="flex" alignItems="center">
              Targeting Rules
              <Tooltip title="These rules will be used to determine if the banner should be shown to the user. All rules are separated by the OR operator.">
                <InfoIcon fontSize="small" sx={{ marginLeft: 1 }} />
              </Tooltip>
            </Typography>
          </Box>
          {criteria.map((field, index) => (
            <Box key={index}>
              <Select key={index} control={control} name={`targets.${index}.criterion`} label={`Rule ${index + 1}`}>
                <MenuItem value={CRITERIA.hotelDestinationCountry}>Accommodation Country</MenuItem>
                <MenuItem value={CRITERIA.hotelDestinationRegion}>Accommodation Region</MenuItem>
                <MenuItem value={CRITERIA.flightDepartureAirportCode}>Flight Departure Airport</MenuItem>
                <MenuItem value={CRITERIA.flightArrivalAirportCode}>Flight Arrival Airport</MenuItem>
                <MenuItem value={CRITERIA.travelBetweenDates}>Travel Between Dates</MenuItem>
              </Select>
              <Box mt={1}>
                {watch(`targets.${index}.criterion`) === CRITERIA.hotelDestinationCountry && (
                  <PlaceLookUp
                    onCurrentValueChange={handleLookUpChange(index)}
                    types={TYPEAHEAD_COUNTRY_TYPES_COUNTRY}
                    label="Country"
                    required
                    initialValue={watch(`targets.${index}.value`)}
                  />
                )}
                {watch(`targets.${index}.criterion`) === CRITERIA.hotelDestinationRegion && (
                  <PlaceLookUp
                    onCurrentValueChange={handleLookUpChange(index)}
                    types={TYPEAHEAD_COUNTRY_TYPES_REGION}
                    label="Region"
                    required
                    initialValue={watch(`targets.${index}.value`)}
                  />
                )}
                {(watch(`targets.${index}.criterion`) === CRITERIA.flightDepartureAirportCode ||
                  watch(`targets.${index}.criterion`) === CRITERIA.flightArrivalAirportCode) && (
                  <PlaceLookUp
                    onCurrentValueChange={handleLookUpChange(index)}
                    types={TYPEAHEAD_COUNTRY_TYPES_AIRPORT}
                    label="Airport"
                    required
                    initialValue={watch(`targets.${index}.value`)}
                  />
                )}
                {watch(`targets.${index}.criterion`) === CRITERIA.travelBetweenDates && (
                  <Stack direction="row" spacing={2}>
                    <DateWidget
                      control={control}
                      name={`targets.${index}.startDate`}
                      label="Start Date"
                      fullWidth
                      disablePast
                      rules={{
                        required: 'Start date is required',
                        validate: (value) => {
                          if (value && newDate(value).isAfter(newDate(watch('endDate')))) {
                            return 'Start date must be before end date';
                          }
                          return true;
                        },
                      }}
                    />
                    <DateWidget
                      control={control}
                      name={`targets.${index}.endDate`}
                      label="End Date"
                      fullWidth
                      disablePast
                      minDate={watch(`startDate`) ? newDate(watch(`startDate`)) : undefined}
                      rules={{
                        required: 'End date is required',
                      }}
                    />
                  </Stack>
                )}
              </Box>
              <Stack justifyContent="flex-end" mt={1} direction="row">
                <Button
                  variant="outlined"
                  onClick={() => removeCriterion(index)}
                  size="small"
                  color="error"
                  startIcon={<DeleteIcon />}
                >
                  Remove
                </Button>
              </Stack>
            </Box>
          ))}
        </Stack>
        <Button
          variant="outlined"
          onClick={() => appendCriterion({ criterion: CRITERIA.hotelDestinationCountry, value: '' })}
          sx={{ marginTop: 1 }}
        >
          Add Rule
        </Button>
        <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
          <Button variant="contained" type="submit" startIcon="" onClick={handleSubmit(onSubmit)}>
            {mode === 'update' ? 'Update' : 'Create'}
          </Button>
          {onSubmitDraft && (
            <Button variant="contained" type="submit" startIcon="" onClick={handleSubmit(onSubmitDraft)}>
              Save As Draft
            </Button>
          )}
          <Link to="/system/targeted-banner-campaigns">
            <Button
              variant="contained"
              type="submit"
              startIcon=""
              onClick={() => {
                reset();
              }}
              color="error"
            >
              Cancel
            </Button>
          </Link>
        </Box>
      </Grid>
      {/* right side - preview */}
      <Grid
        item
        xs={7}
        sx={{
          overflowY: 'scroll',
          height: 'calc(100vh - 210px)',
        }}
      >
        <Typography variant="h6" display="flex" alignItems="center">
          <IconButton>
            <VisibilityIcon />
          </IconButton>
          Preview
        </Typography>
        <Alert
          icon={<BannerPreviewIcon icon={watch('bannerAppearance.icon')} />}
          severity={watch('bannerAppearance.color') ?? 'info'}
        >
          <Typography fontWeight="bold" component="span">
            {watch('bannerTitle') || 'Banner title preview: '}
          </Typography>
          <Typography component="span">{watch('bannerOverview') || 'Banner overview preview'}</Typography>
        </Alert>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            minHeight: 300,
            paddingTop: '10%',
            paddingBottom: '10%',
          }}
        >
          <Card sx={{ width: '80%', maxWidth: 600 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                {watch('modalTitle') || 'Modal title'}
              </Typography>
              <MarkdownPreview value={watch('modalDescription') || 'Modal description will appear here.'} />
            </CardContent>
          </Card>
        </Box>
        <Box>
          <Typography variant="h6">Targeting Rules Summary:</Typography>
          {watch('targets').length > 0 &&
          watch('targets').some(
            (target) =>
              (target.criterion === CRITERIA.travelBetweenDates && target.startDate && target.endDate) ||
              (target.criterion !== CRITERIA.travelBetweenDates && target.value),
          ) ? (
            <Typography variant="body1">
              When{' '}
              {watch('targets')
                .map((target) => {
                  const targetCriterion = TargetDictionary[target.criterion];

                  if (target.criterion === CRITERIA.travelBetweenDates) {
                    if (target.startDate && target.endDate) {
                      return `${targetCriterion} ${newDate(target.startDate).format('DD/MM/YYYY')} or ${newDate(
                        target.endDate,
                      ).format('DD/MM/YYYY')}`;
                    }
                  } else if (target.value) {
                    return `${targetCriterion} ${target.value}`;
                  }

                  return '';
                })
                .filter(Boolean)
                .join(' or ')}
            </Typography>
          ) : (
            <Typography variant="body1">No targeting rules have been added.</Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default BannerEditForm;
