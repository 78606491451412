import React from 'react';

import { Helmet } from 'react-helmet';

import { Alert, AlertTitle, Box, Container, Grid, Typography } from '@mui/material';

import RestoreButton from './RestoreSearchComponents/RestoreButton';

function RestoreSearchDashboard() {
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Restore Search Dashboard</title>
      </Helmet>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Restore Search Dashboard
        </Typography>

        <Alert severity="info" sx={{ mb: 3 }}>
          <AlertTitle>Administrative Tools</AlertTitle>
          <Typography variant="body1">
            This dashboard is not yet fully functional. If you need any data restored, please contact the Search team
            directly.
          </Typography>
        </Alert>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <RestoreButton
            restoreType="BEDBANK"
            title="Bedbank Offers Index"
            description={[
              'Restores search indices for bedbank offers',
              'Use when bedbank search requires reindexing',
              'Restoration typically completes within 1 to 5 minutes.',
            ]}
            timeRange="1 - 2 minutes"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <RestoreButton
            restoreType="OFFERS"
            title="LE Offers Index"
            description={[
              'Restores search indices for offers',
              'Use when offer search requires reindexing',
              'Restoration typically completes within 1 to 5 minutes.',
            ]}
            timeRange="1 - 5 minutes"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <RestoreButton
            restoreType="PROPERTIES"
            title="Properties Index"
            description={[
              'Restores search indices for properties',
              'Use when property search requires reindexing',
              'Restoration typically completes within 1 to 5 minutes.',
            ]}
            timeRange="1 - 5 minutes"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <RestoreButton
            restoreType="ANALYTICS"
            title="Analytics Index"
            description={[
              'Restores search indices for analytics data',
              'Use when analytics search requires reindexing',
              'Restoration takes between 1 to 5 minutes',
            ]}
            timeRange="1 - 5 minutes"
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default RestoreSearchDashboard;
