import memoize from 'lodash/memoize';
import qs from 'qs';

import { definitions, operations, paths } from '@luxuryescapes/contract-search';

import { RestoreType } from '~/components/Content/RestoreSearchDashboard/RestoreSearchComponents/types';

import { request } from './common';

export type GlobalRanks = Record<string, number>;

export type TypeaheadResponse =
  paths['/api/search/typeahead/v1/query/{query}']['get']['responses']['200']['content']['application/json'];

export type GetPlaceResponse =
  paths['/api/search/admin/v1/place/{id}']['get']['responses']['200']['content']['application/json'];

export type TypeaheadResult = definitions['typeaheadSearchResult'];

export type HotelSearchList = definitions['hotelListOffersResult'];

export type TourSearchList = definitions['tourListOffersResult'];

export type CruiseSearchList =
  paths['/api/search/cruise/v1/list']['get']['responses']['200']['content']['application/json']['result'];

export type UnifiedSearchList =
  paths['/api/search/unified/v1/list']['get']['responses']['200']['content']['application/json']['results'];

export type OrderOffer = UnifiedSearchList[0];

type UnifiedSearchOfferType = OrderOffer['offerType'];

export type HotelScoreResponse =
  paths['/api/search/admin/v1/hotel/scores/{id}']['get']['responses']['200']['content']['application/json'];

export type ScoreSettings =
  paths['/api/search/admin/v1/{vertical}/scoreSettings']['get']['responses']['200']['content']['application/json']['result'];

export type TypeaheadQueryParams = operations['/api/search/typeahead/v1/query/{query}/get']['parameters']['query'];

export type TypeaheadPriority = TypeaheadQueryParams['priority'];

export type TypeaheadType = TypeaheadQueryParams['type'] extends string ? TypeaheadQueryParams['type'] : never;

export type TypeaheadBrand = TypeaheadQueryParams['brand'];

export type ImagePutPayload = operations['/api/search/admin/v1/image/put']['parameters']['body']['payload'];

export type ImageResponse =
  operations['/api/search/admin/v1/image/{placeId}/get']['responses']['200']['content']['application/json'];

export type TrendingDestinationsResponse =
  operations['/api/search/place/v1/trending/get']['responses']['200']['content']['application/json']['result'][number];

export type BlockedOffersListResponse =
  operations['/api/search/admin/v1/offer-blocklist/get']['responses']['200']['content']['application/json'];

export type BlockedOffer = BlockedOffersListResponse['result']['offers'][number];

export type PropertyManualLocationsListResponse =
  operations['/api/search/admin/v1/property-manual-locations/get']['responses']['200']['content']['application/json'];

export type PropertyManualLocationsDeleteResponse =
  operations['/api/search/admin/v1/property-manual-locations/{id}/delete']['responses']['200']['content']['application/json'];

export type PropertyManualLocationsPatchResponse =
  operations['/api/search/admin/v1/property-manual-locations/{id}/patch']['responses']['200']['content']['application/json'];

export type PropertyManualLocationsPutResponse =
  operations['/api/search/admin/v1/property-manual-locations/put']['responses']['200']['content']['application/json'];

export type PropertyManualLocation = PropertyManualLocationsListResponse['result'];

export type TrendingPlacePostResponse =
  operations['/api/search/admin/v1/trending-places/post']['responses']['200']['content']['application/json'];

export type TrendingPlaceGetResponse =
  operations['/api/search/admin/v1/trending-places/get']['responses']['200']['content']['application/json'];

export type UserRecentSearchesResponse =
  operations['/api/search/admin/v1/users/{userId}/recent-searches/get']['responses']['200']['content']['application/json'];

export type UserRecentSearch = UserRecentSearchesResponse['result'][number];

export type UserRecentOfferPageViewsResponse =
  operations['/api/search/admin/v1/users/{userId}/recent-offer-page-views/get']['responses']['200']['content']['application/json'];

export type UserRecentOfferPageView =
  operations['/api/search/admin/v1/users/{userId}/recent-offer-page-views/get']['responses']['200']['content']['application/json']['result'][number];

export const KNOWN_TYPEAHEAD_TYPES: TypeaheadQueryParams['type'] = [
  'airport',
  'bedbank_property_unique_stays',
  'bedbank_property',
  'city',
  'colloquial_area',
  'continent',
  'country',
  'high_level_region',
  'le_property_unique_stays',
  'le_property',
  'metro_station',
  'multi_city_vicinity',
  'neighborhood',
  'point_of_interest',
  'province_state',
  'train_station',
  'le_experience',
  'channel_experience',
  'car_hire_location',
];

export const ANYWHERE_PLACE_ID = 'le_d41d8cd98f00b204e9800998ecf8427e';

export enum EvVariant {
  Current = 'current',
  Next = 'next',
  LuxPlusBoost = 'luxplus_boost',
  LuxPlusBoostNext = 'luxplus_boost_next',
}

const LuxPlusVariants = new Set([EvVariant.LuxPlusBoost, EvVariant.LuxPlusBoostNext]);

export interface PatchHotelScoreEntry {
  region: string;
  overrideScore: number;
  overrideWeight: number;
  overrideExpireDate: string;
}

export interface PutCruiseRecommendation {
  id: string;
  recommendation: number;
}

interface HotelSearchOptions {
  region?: string;
  destinationId?: string;
  offerTypes?: Array<string>;
  evVariant?: EvVariant;
  includeAllBedbankWithSales?: boolean;
  noCache?: boolean;
}

interface EvOptions {
  vertical: string;
  offerId: string;
  region: string;
  variant: string;
}

export const FlashLpcOfferTypes = ['hotel', 'last_minute_hotel', 'tactical_ao_hotel'];

export const AllHotelOfferTypes = [...FlashLpcOfferTypes, 'bundle_and_save', 'rental', 'bedbank_hotel'];

export default class SearchService {
  static typeaheadPath() {
    return `${window.configs.API_HOST}/api/search/typeahead/v1/query`;
  }

  static placePath() {
    return `${window.configs.API_HOST}/api/search/admin/v1/place`;
  }

  static typeaheadDeprioritisationPath() {
    return `${window.configs.API_HOST}/api/search/admin/v1/typeahead/deprioritisations`;
  }

  static lePropertyPath() {
    return `${window.configs.API_HOST}/api/search/admin/v1/le-property`;
  }

  static bedbankPropertyPath() {
    return `${window.configs.API_HOST}/api/search/admin/v1/bedbank-property`;
  }

  static hotelScorePath() {
    return `${window.configs.API_HOST}/api/search/admin/v1/hotel/scores`;
  }

  static scorePath() {
    return `${window.configs.API_HOST}/api/search/admin/v1/scores`;
  }

  static offerScorePath(vertical: string) {
    return `${window.configs.API_HOST}/api/search/admin/v1/${vertical}/scores`;
  }

  static destinationImage(placeId: string) {
    return `${window.configs.API_HOST}/api/search/admin/v1/image/${placeId}`;
  }

  static offerScoreSettingsPath(vertical: string) {
    return `${window.configs.API_HOST}/api/search/admin/v1/${vertical}/scoreSettings`;
  }

  static cruiseRecommendationPath() {
    return `${window.configs.API_HOST}/api/search/admin/v1/cruise/recommendation`;
  }

  static listOrderPath(listName: string) {
    return `${window.configs.API_HOST}/api/search/admin/v1/list-order/${listName}`;
  }

  static unifiedOfferListPath() {
    return `${window.configs.API_HOST}/api/search/unified/v1/list`;
  }

  static typeaheadTemplatePath() {
    return `${window.configs.API_HOST}/api/search/admin/v1/typeahead/template`;
  }

  static typeahead(query: string, queryParams: TypeaheadQueryParams): Promise<TypeaheadResponse> {
    return request(`${this.typeaheadPath()}/${query}?${qs.stringify(queryParams)}`, {
      method: 'GET',
    });
  }

  static getPlace(placeId: string): Promise<GetPlaceResponse> {
    return request(`${this.placePath()}/${placeId}`, {
      method: 'GET',
    });
  }

  static getTypeaheadDeprioritisation(placeId: string) {
    return request(`${this.typeaheadDeprioritisationPath()}/${placeId}`, {
      method: 'GET',
    });
  }

  static putTypeaheadDeprioritisation(placeId: string) {
    return request(`${this.typeaheadDeprioritisationPath()}/${placeId}`, {
      method: 'PUT',
    });
  }

  static deleteTypeaheadDeprioritisation(placeId: string) {
    return request(`${this.typeaheadDeprioritisationPath()}/${placeId}`, {
      method: 'DELETE',
    });
  }

  static getLeProperty(placeId: string) {
    return request(`${this.lePropertyPath()}/${placeId}`, {
      method: 'GET',
    });
  }

  static getAllTypeaheadTemplates() {
    return request(this.typeaheadTemplatePath(), {
      method: 'GET',
    });
  }

  static getTypeaheadTemplate(templateId: string): Promise<App.TypeaheadSearchTemplate> {
    return request(`${this.typeaheadTemplatePath()}/${templateId}`, {
      method: 'GET',
    }).then((response) => response.result);
  }

  static putTypeaheadTemplate(template: App.TypeaheadSearchTemplate) {
    return request(this.typeaheadTemplatePath(), {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(template),
    });
  }

  static hideTypeaheadTemplate(templateId: string) {
    return request(`${this.typeaheadTemplatePath()}/${templateId}/set-hidden`, {
      method: 'PATCH',
    });
  }

  static getHotelOffers({
    region = 'AU',
    offerTypes = AllHotelOfferTypes,
    destinationId = ANYWHERE_PLACE_ID,
    evVariant = EvVariant.Current,
    includeAllBedbankWithSales = false,
    noCache,
  }: HotelSearchOptions): Promise<{
    result: HotelSearchList;
  }> {
    const url = new URL('/api/search/hotel/v1/list', window.configs.API_HOST);
    url.searchParams.set('offerType', offerTypes.join(','));
    url.searchParams.set('searchType', 'destination');
    url.searchParams.set('placeIds', destinationId);
    url.searchParams.set('region', region);
    url.searchParams.set('occupancy[0]', '2');
    url.searchParams.set('brand', 'luxuryescapes');
    url.searchParams.set('distanceEq', '0');

    if (evVariant !== EvVariant.Current) {
      url.searchParams.set('evVersion', evVariant);
    }
    if (LuxPlusVariants.has(evVariant)) {
      // this will make search returns the LuxPlus only offers
      url.searchParams.set('isLuxPlusMember', 'true');
    }
    if (includeAllBedbankWithSales) {
      url.searchParams.set('includeAllBedbankWithSales', 'true');
    }
    if (noCache) {
      url.searchParams.set('noCache', 'true');
    }

    return request(url.toString(), {
      method: 'GET',
    });
  }

  static getHotelGlobalRanks = memoize(
    async (region: string, evVariant: EvVariant) => {
      const anywhereList = await this.getHotelOffers({
        region,
        evVariant,
        includeAllBedbankWithSales: true,
        noCache: true,
      });
      const offerPositions: GlobalRanks = {};
      anywhereList.result.forEach((offer, index) => {
        offerPositions[offer.id] = index + 1;
      });
      return offerPositions;
    },
    (region, evVariant) => `${region}-${evVariant}`,
  );

  static getAllUltraLuxOffers(
    region = 'AU',
    destinationId = ANYWHERE_PLACE_ID,
  ): Promise<{ results: Array<Exclude<OrderOffer, { vertical: 'ev' }>> }> {
    const url = new URL('/api/search/unified/v1/list', window.configs.API_HOST);
    url.searchParams.set('offerTypes', 'hotel,tactical_ao_hotel');
    url.searchParams.set('placeIds', destinationId);
    url.searchParams.set('region', region);
    url.searchParams.set('brand', 'luxuryescapes');
    url.searchParams.set('holidayTypesScoped', 'Ultra%20Lux');

    return request(url.toString(), {
      method: 'GET',
    });
  }

  static async getAllTourOffers(
    region: string,
    destinationId: string,
    sorting: string,
  ): Promise<{
    result: TourSearchList;
  }> {
    return sorting === 'default'
      ? this.getAllTourOffersFromSearchList(destinationId, region)
      : this.getAllTourOffersFromUnifiedEndpoint(destinationId, region, sorting);
  }

  private static async getAllTourOffersFromSearchList(destinationId: string, region: string) {
    const url = new URL('/api/search/tour/v1/list', window.configs.API_HOST);

    url.searchParams.set('offerType', 'tour_v2');
    url.searchParams.set('placeIds[0]', destinationId);
    url.searchParams.set('region', region);
    url.searchParams.set('brand', 'luxuryescapes');
    url.searchParams.set('sortBy', 'vertical');
    url.searchParams.set('isFacet', 'true');

    const response = await request(url.toString(), {
      method: 'GET',
    });
    return {
      result: response.result.map((tourOptionId) => tourOptionId.slice(0, 41)),
    };
  }

  private static async getAllTourOffersFromUnifiedEndpoint(destinationId: string, region: string, sorting: string) {
    const tourOfferTypes = ['tour_v2'];

    const options: UnifiedListProps = {
      brand: 'luxuryescapes',
      region,
      placeIds: destinationId,
      offerTypes: tourOfferTypes.join(','),
      sortBy: sorting,
      limit: 2000,
    };

    return this.getUnifiedList(options).then((response) => ({
      result: response.results.map((tour) => tour.bk),
    }));
  }

  static async getAllCruiseOffers(
    region: string,
    destinationId: string,
    sorting: string,
    algorithmType: string,
  ): Promise<{
    result: Array<OrderOffer>;
  }> {
    return sorting === 'default'
      ? this.getAllCruiseOffersFromSearchList(destinationId, region, algorithmType)
      : this.getAllCruiseOffersFromUnifiedEndpoint(destinationId, region, sorting);
  }

  private static async getAllCruiseOffersFromSearchList(
    destinationId: string,
    region: string,
    algorithmType: string,
  ): Promise<{
    result: Array<OrderOffer>;
  }> {
    const url = new URL('/api/search/cruise/v1/list', window.configs.API_HOST);
    if (algorithmType !== EvVariant.Current) {
      url.searchParams.set('applyNextEvAlgorithm', 'true');
    }

    url.searchParams.set('arrivalPlaceId', destinationId);
    url.searchParams.set('region', region);
    url.searchParams.set('brand', 'luxuryescapes');

    const response = await request(url.toString(), {
      method: 'GET',
    });
    return {
      result: response.result.map((offerId) => ({
        vertical: 'cruise',
        offerType: 'cruise',
        bk: offerId,
      })),
    };
  }

  private static async getAllCruiseOffersFromUnifiedEndpoint(destinationId: string, region: string, sorting: string) {
    const options: UnifiedListProps = {
      brand: 'luxuryescapes',
      region,
      placeIds: destinationId,
      offerTypes: 'cruise,tour,hotel',
      holidayTypesScoped: 'Cruises',
      sortBy: sorting === 'default' ? 'vertical-cruise' : sorting,
      limit: 2000,
    };

    return this.getUnifiedList(options).then((response) => ({
      result: response.results,
    }));
  }

  static async getAllExperienceOffers(
    brand: string,
    region: string,
    destinationId: string,
    sorting: string,
  ): Promise<{
    result: Array<OrderOffer>;
  }> {
    return sorting === 'default'
      ? this.getAllExperienceOffersFromSearchList(destinationId, brand, region)
      : this.getAllExperienceOffersFromUnifiedEndpoint(destinationId, brand, region, sorting);
  }

  private static async getAllExperienceOffersFromSearchList(
    destinationId: string,
    brand: string,
    region: string,
  ): Promise<{
    result: Array<OrderOffer>;
  }> {
    const url = new URL('/api/search/experience/v1/list', window.configs.API_HOST);

    url.searchParams.set('placeId', destinationId);
    url.searchParams.set('region', region);
    url.searchParams.set('brand', brand);
    url.searchParams.set('sortBy', 'type');

    const response = await request(url.toString(), {
      method: 'GET',
    });
    return {
      result: response.result.map((offerId) => ({
        vertical: 'experience',
        offerType: 'flash',
        bk: offerId,
      })),
    };
  }

  private static async getAllExperienceOffersFromUnifiedEndpoint(
    destinationId: string,
    brand: string,
    region: string,
    sorting: string,
  ): Promise<{
    result: Array<OrderOffer>;
  }> {
    const experienceOfferTypes: Array<UnifiedSearchOfferType> = ['flash', 'always_on', 'channel_manager'];

    const options: UnifiedListProps = {
      brand,
      region,
      placeIds: destinationId,
      offerTypes: experienceOfferTypes.join(','),
      sortBy: sorting,
      limit: 2000,
    };

    return this.getUnifiedList(options).then((response) => ({
      result: response.results,
    }));
  }

  static async getAllUnifiedOffers(
    region: string,
    destinationId: string,
    sorting: string,
  ): Promise<{
    results: UnifiedSearchList;
  }> {
    const hotelOfferTypes: Array<UnifiedSearchOfferType> = ['hotel', 'tactical_ao_hotel', 'bedbank_hotel', 'rental'];
    const tourOfferTypes: Array<UnifiedSearchOfferType> = ['tour', 'tour_v2'];
    const cruiseOfferTypes: Array<UnifiedSearchOfferType> = ['cruise'];
    const experienceOfferTypes: Array<UnifiedSearchOfferType> = ['flash', 'always_on', 'channel_manager'];

    const offerTypes: Array<UnifiedSearchOfferType> = [
      ...hotelOfferTypes,
      ...tourOfferTypes,
      ...cruiseOfferTypes,
      ...experienceOfferTypes,
    ];

    const sorts = {
      default: 'home',
      ev: 'ev',
    };

    const options: UnifiedListProps = {
      brand: 'luxuryescapes',
      region,
      placeIds: destinationId,
      offerTypes: offerTypes.join(','),
      limit: 2000,
      sortBy: sorts[sorting],
    };

    return this.getUnifiedList(options);
  }

  static getBedbankProperty(placeId: string) {
    return request(`${this.bedbankPropertyPath()}/${placeId}`, {
      method: 'GET',
    });
  }

  static getHotelScore(offerId: string): Promise<HotelScoreResponse> {
    return request(`${this.hotelScorePath()}/${offerId}`, {
      method: 'GET',
    });
  }

  static refreshHotelScore(offerId: string) {
    return request(`${this.hotelScorePath()}/${offerId}/refresh`, {
      method: 'POST',
    });
  }

  static getHotelScoreForVariant(offerId: string, region: string, variant: string) {
    return request(`${this.hotelScorePath()}/${offerId}/${region}/${variant}`, {
      method: 'GET',
    });
  }

  static patchHotelScore(offerId: string, payload: Array<PatchHotelScoreEntry>) {
    return request(`${this.hotelScorePath()}/${offerId}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }

  static patchScore(offerId: string, payload: Array<PatchHotelScoreEntry>) {
    return request(`${this.scorePath()}/${offerId}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }

  static getOfferScoreForVariant({ vertical, offerId, region, variant }: EvOptions) {
    return request(`${this.offerScorePath(vertical)}/${offerId}/${region}/${variant}`, {
      method: 'GET',
    });
  }

  static getOfferScoreSettings(vertical: string, region: string) {
    return request(`${this.offerScoreSettingsPath(vertical)}?region=${region}`, {
      method: 'GET',
    });
  }

  static putDestinationImage(payload: ImagePutPayload) {
    return request(`${this.destinationImage('')}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }

  static getDestinationImage(placeId: string): Promise<ImageResponse> {
    return request(`${this.destinationImage(placeId)}`, {
      method: 'GET',
    });
  }

  static putCruiseRecommendation(payload: Array<PutCruiseRecommendation>) {
    return request(this.cruiseRecommendationPath(), {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }

  static getCruisesRecommendation() {
    return request(this.cruiseRecommendationPath(), {
      method: 'GET',
    });
  }

  static getListOrder(
    listName: string,
    brand: string,
    region: string,
  ): Promise<{ result: { offers: Array<OrderOffer> } }> {
    return request(`${this.listOrderPath(listName)}?brand=${brand}&region=${region}`, {
      method: 'GET',
    });
  }

  static putListOrder(listName: string, offers: Array<OrderOffer>, brand: string, region: string) {
    return request(this.listOrderPath(listName), {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        brand,
        region,
        offers,
      }),
    });
  }

  static getUnifiedList(
    params: UnifiedListProps,
  ): Promise<{ results: Array<Exclude<OrderOffer, { vertical: 'ev' }>> }> {
    const queryString = qs.stringify(params);

    return request(`${this.unifiedOfferListPath()}?${queryString}`, {
      method: 'GET',
    });
  }

  static getTrendingDestinations(region: string, brand = 'luxuryescapes', limit?: number, withImage = false) {
    return request(
      `${window.configs.API_HOST}/api/search/place/v1/trending?region=${region}&brand=${brand}&includeImage=${withImage}` +
        (limit ? `&limit=${limit}` : ''),
      {
        method: 'GET',
      },
    );
  }

  static getBlockedOffersList(
    brand: string,
    page: number,
    limit: number,
    q?: string,
  ): Promise<BlockedOffersListResponse> {
    return request(
      `${window.configs.API_HOST}/api/search/admin/v1/offer-blocklist?brand=${brand}&page=${page}&pageSize=${limit}&q=${q}`,
      {
        method: 'GET',
      },
    );
  }

  static addToBlockedOfferList(brand: string, offerIds: Array<string>) {
    return request(`${window.configs.API_HOST}/api/search/admin/v1/offer-blocklist?brand=${brand}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        offerIds,
      }),
    });
  }

  static removeBlockedOffer(brand: string, offerIds: Array<string>) {
    const offerIdsString = offerIds.join(',');
    return request(
      `${window.configs.API_HOST}/api/search/admin/v1/offer-blocklist?brand=${brand}&offerIds=${offerIdsString}`,
      {
        method: 'DELETE',
      },
    );
  }

  static getOfferAssociationList(): Promise<PropertyManualLocationsListResponse> {
    return request(`${window.configs.API_HOST}/api/search/admin/v1/property-manual-locations`, {
      method: 'GET',
    });
  }

  static getTypeahead(query: string, queryParams): Promise<TypeaheadSearchResponse> {
    return request(`${window.configs.API_HOST}/api/search/typeahead/${query}?${qs.stringify(queryParams)}`, {
      method: 'GET',
    });
  }

  static putOfferAssociationList(
    propertyId,
    placeId,
    lastModifiedBy,
    notes,
  ): Promise<PropertyManualLocationsPutResponse> {
    return request(`${window.configs.API_HOST}/api/search/admin/v1/property-manual-locations`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        propertyId,
        placeId,
        lastModifiedBy,
        notes,
      }),
    });
  }

  static deleteOfferAssociationList(id: string): Promise<PropertyManualLocationsDeleteResponse> {
    return request(`${window.configs.API_HOST}/api/search/admin/v1/property-manual-locations/${id}`, {
      method: 'DELETE',
    });
  }

  static patchOfferAssociationList(
    id,
    propertyId,
    placeId,
    lastModifiedBy,
    notes,
  ): Promise<PropertyManualLocationsPatchResponse> {
    return request(`${window.configs.API_HOST}/api/search/admin/v1/property-manual-locations/${id}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        propertyId,
        placeId,
        lastModifiedBy,
        notes,
      }),
    });
  }

  static getTrendingPlace(brand: string, region: string): Promise<TrendingPlaceGetResponse> {
    return request(`${window.configs.API_HOST}/api/search/admin/v1/trending-places?region=${region}&brand=${brand}`, {
      method: 'GET',
    });
  }

  static upsertTrendingPlace(
    placeId: string,
    brand: string,
    region: string,
    imageId?: string,
    manualPosition?: number,
  ): Promise<TrendingPlacePostResponse> {
    return request(`${window.configs.API_HOST}/api/search/admin/v1/trending-places`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        placeId,
        manualPosition,
        brand,
        region,
        imageId,
      }),
    });
  }

  static updatePlaceImage(
    placeId: string,
    brand: string,
    region: string,
    imageId: string,
  ): Promise<TrendingPlaceGetResponse> {
    return request(`${window.configs.API_HOST}/api/search/admin/v1/trending-places/${placeId}/image`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        brand,
        region,
        imageId,
      }),
    });
  }

  static getUserRecentSearches(userId: string, brand: string): Promise<UserRecentSearchesResponse> {
    return request(`${window.configs.API_HOST}/api/search/admin/v1/users/${userId}/recent-searches?brand=${brand}`, {
      method: 'GET',
    });
  }

  static getUserRecentOfferPageViews(userId: string, brand: string): Promise<UserRecentOfferPageViewsResponse> {
    return request(
      `${window.configs.API_HOST}/api/search/admin/v1/users/${userId}/recent-offer-page-views?brand=${brand}`,
      {
        method: 'GET',
      },
    );
  }

  static restoreSearch(restoreType: RestoreType): Promise<void> {
    const queryParams = {
      restoreType: [restoreType],
    };

    return request(`${window.configs.API_HOST}/api/search/admin/v1/restore?${qs.stringify(queryParams)}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    });
  }
}

export interface UnifiedListProps {
  brand: string;
  region: string;
  placeIds: string;
  offerTypes: string;
  limit: number;
  holidayTypesScoped?: string;
  sortBy?: string;
  luxPlusOffersOnly?: boolean;
}

export interface TypeaheadSearchResponse {
  result: Array<TypeaheadSearchResult>;
}

export interface TypeaheadSearchResult {
  format: {
    mainText: string;
    secondaryText?: string;
    imageId?: string;
  };
  destinationType?: string;
  id: string;
  name: string;
  searchType: string;
  offerType?: string;
  offerDiscount?: number;
  offerId?: string;
  hasTactical?: boolean;
  bedbankStatus?: string;
  bedbankHasPromotions?: boolean;
  fk_location?: string;
  country_code?: string | null;
}
