import qs from 'qs';

import { operations } from '@luxuryescapes/contract-support/dist/server';

import { Chat } from '~/components/SupportAssistant/types/Chat';

import { json_headers, request } from './common';

const BASE_PATH = `${window.configs.API_HOST}/api/support/v1`;

type queryFilter = {
  from: string;
  to: string;
  customersOnly: boolean;
  agentChatsOnly: boolean;
  hasMessagesOnly: boolean;
};

export const searchChats = (params: any) => {
  const queryParams = qs.stringify(params);
  const uri = `${BASE_PATH}/chats/search?${queryParams}`;

  return request(uri, { method: 'GET' });
};

export const getChatById = (id: string) => {
  const uri = `${BASE_PATH}/chats/${id}?includeMessages=true`;

  return request(uri, { method: 'GET' });
};

export const getArticles = (params: any) => {
  const queryParams = qs.stringify(params);
  const uri = `${BASE_PATH}/articles?${queryParams}`;

  return request(uri, { method: 'GET' });
};

export const getFeedback = (params: any) => {
  const queryParams = qs.stringify(params);
  const uri = `${BASE_PATH}/feedbacks?${queryParams}`;

  return request(uri, { method: 'GET' });
};

export const getDailyStats = (filter: queryFilter) => {
  const query = qs.stringify(filter);

  const uri = `${BASE_PATH}/statistics/daily-stats?${query}`;

  return request(uri, { method: 'GET' });
};

export const getChatStats = (params: any) => {
  const query = qs.stringify(params);

  const uri = `${BASE_PATH}/statistics/chats-time-stats?${query}`;

  return request(uri, { method: 'GET' });
};

export const getFeedbackIssuesStats = (filter: queryFilter) => {
  const query = qs.stringify(filter);

  const uri = `${BASE_PATH}/statistics/feedback-reasons-count?${query}`;

  return request(uri, { method: 'GET' });
};

export const updateChat = (chat: Partial<Chat>) => {
  const uri = `${BASE_PATH}/chats/${chat.id}`;

  return request(uri, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      quality: chat.quality,
      followUp: chat.followUp,
      deflection: chat.deflection,
      topics: chat.topics,
    }),
  });
};

export const getChatsTopicsFrequency = (filter: queryFilter) => {
  const query = qs.stringify(filter);

  const uri = `${BASE_PATH}/statistics/chats-topics-frequency?${query}`;

  return request(uri, { method: 'GET' });
};

export const getChatTopics = () => {
  const uri = `${BASE_PATH}/chats/topics`;

  return request(uri, { method: 'GET' });
};

export const getChristmasMessage = () => {
  const uri = `${BASE_PATH}/christmas-message`;

  return request(uri, { method: 'GET' });
};

export const getSettings = () => {
  const uri = `${BASE_PATH}/settings`;

  return request(uri, { method: 'GET' });
};

export const setSettings = (body) => {
  const uri = `${BASE_PATH}/settings`;

  return request(uri, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify(body),
  });
};

export const getSettingsHistory = () => {
  const uri = `${BASE_PATH}/settings/history`;

  return request(uri, { method: 'GET' });
};

export const getCancellationModalSettings = () => {
  const uri = `${BASE_PATH}/cancellation-modal-settings`;
  return request(uri, {
    method: 'GET',
  });
};

export const setCancellationModalSettings = (data) => {
  const uri = `${BASE_PATH}/cancellation-modal-settings`;
  return request(uri, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify(data),
  });
};

export interface SiteBannerCampaign {
  id: string;
  campaignTitle: string;
  bannerTitle: string;
  bannerOverview: string;
  status: 'DRAFT' | 'ACTIVE' | 'ARCHIVED';
  contentType: 'banner';
  modalTitle: string;
  modalDescription: string;
  startDate: Date;
  endDate: Date;
  targets: Array<any>;
  settings: {
    bannerIcon: 'check' | 'info' | 'warning' | 'error';
    bannerColor: 'info' | 'success' | 'warning' | 'error';
  };
}

type APIGetBannersCampaignListResponse =
  operations['get-targeted-banners']['responses']['200']['content']['application/json'];
type APIGetBannerCampaignResponse =
  operations['get-targeted-banner']['responses']['200']['content']['application/json'];
type APICreateBannerPayload = operations['create-targeted-banner']['parameters']['body']['payload'];
type APIUpdateBannerPayload = operations['update-targeted-banner']['parameters']['body']['payload'];

function mapBannerCampaign(campaign: APIGetBannerCampaignResponse['result']): SiteBannerCampaign {
  return {
    id: campaign.id,
    campaignTitle: campaign.campaignTitle,
    bannerTitle: campaign.bannerTitle,
    bannerOverview: campaign.bannerOverview,
    status: campaign.status,
    contentType: 'banner',
    modalTitle: campaign.modalTitle,
    modalDescription: campaign.modalDescription,
    startDate: new Date(campaign.startDate),
    endDate: new Date(campaign.endDate),
    targets: campaign.targets as unknown as Array<any>,
    settings: campaign.settings as {
      bannerIcon: 'check' | 'info' | 'warning' | 'error';
      bannerColor: 'info' | 'success' | 'warning' | 'error';
    },
  };
}

export async function getBannerCampaignsList(
  page: number,
  rowsPerPage: number,
  brand: string,
): Promise<{ banners: Array<SiteBannerCampaign>; count: number }> {
  const params = new URLSearchParams({ page: `${page}`, limit: `${rowsPerPage}`, brand });
  const uri = `${BASE_PATH}/targeted-banners?${params.toString()}`;
  const response: APIGetBannersCampaignListResponse = await request(uri, { method: 'GET' });
  return {
    banners: response.result.map(mapBannerCampaign),
    count: response.count,
  };
}

export async function getBannerCampaign(id: string): Promise<SiteBannerCampaign> {
  const uri = `${BASE_PATH}/targeted-banners/${id}`;
  const banner = await request(uri, { method: 'GET' });
  return mapBannerCampaign(banner.result);
}

export async function createBannerCampaign(data: APICreateBannerPayload): Promise<void> {
  const uri = `${BASE_PATH}/targeted-banners`;
  return request(uri, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify(data),
  });
}

export async function updateBannerCampaign(id: string, data: APIUpdateBannerPayload): Promise<void> {
  const uri = `${BASE_PATH}/targeted-banners/${id}`;
  return request(uri, {
    method: 'PATCH',
    headers: json_headers,
    body: JSON.stringify(data),
  });
}
