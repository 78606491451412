import React from 'react';

import {
  Cancel as CancelIcon,
  CloudDownload as CloudDownloadIcon,
  Replay as ReplayIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { IconButton, Stack, Tooltip } from '@mui/material';

import { PriceSummaryJobStatus } from '~/types/services/priceParrot';

type ActionHandler = () => Promise<void> | void;
type Props = {
  jobStatus: PriceSummaryJobStatus;
  onCancel?: ActionHandler;
  onDownload: ActionHandler;
  onPreview?: ActionHandler;
  onResubmit?: ActionHandler;
};

const ICON_BUTTON_STYLE = { padding: '4px' };

export default function GridColActions({ jobStatus, onCancel, onDownload, onPreview, onResubmit }: Props) {
  return (
    <Stack direction="row">
      {onCancel &&
        [
          PriceSummaryJobStatus.PENDING,
          PriceSummaryJobStatus.PROCESSING,
          PriceSummaryJobStatus.RETRYING,
          PriceSummaryJobStatus.INITIALISING,
        ].includes(jobStatus) && (
          <Tooltip title="Cancel Job">
            <IconButton onClick={onCancel} sx={ICON_BUTTON_STYLE}>
              <CancelIcon />
            </IconButton>
          </Tooltip>
        )}
      {[PriceSummaryJobStatus.COMPLETED].includes(jobStatus) && (
        <>
          <Tooltip title="Download Job">
            <IconButton onClick={onDownload} sx={ICON_BUTTON_STYLE}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>

          {onPreview && (
            <Tooltip title="Preview Job">
              <IconButton onClick={onPreview} sx={ICON_BUTTON_STYLE}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
      {onResubmit && [PriceSummaryJobStatus.FAILED, PriceSummaryJobStatus.CANCELLED].includes(jobStatus) && (
        <Tooltip title="Resubmit Job">
          <IconButton onClick={onResubmit} sx={ICON_BUTTON_STYLE}>
            <ReplayIcon />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
}
