import React, { useCallback, useEffect, useState } from 'react';

import { MenuItem, Stack, TextField } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import cruiseLineService, { CruiseLine } from '~/services/cruises/CruiseLineService';

interface Props {
  label: string;
  value: string;
  onChange: (cruiseLine: CruiseLine) => void;
  allowEmpty?: boolean;
  required?: boolean;
}

function InputCruiseLineSelect({ label, value, onChange, required, allowEmpty }: Props) {
  const [cruiseLines, setCruiseLines] = useState<Array<CruiseLine>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialCruiseLine, setInitialCruiseLine] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const ITEMS_PER_PAGE = 50;

  const fetchCruiseLines = useCallback(async (pageNumber: number) => {
    setLoading(true);
    const res = await cruiseLineService.listWithPagination({
      take: ITEMS_PER_PAGE,
      skip: (pageNumber - 1) * ITEMS_PER_PAGE,
    });

    setCruiseLines((prev) => [...prev, ...res.result]);
    setHasMore(res.result.length === ITEMS_PER_PAGE);
    setLoading(false);
  }, []);

  const handlerOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === '' && allowEmpty) {
        // We need to pass an empty cruise line to avoid errors
        const emptyCruiseLine: CruiseLine = {
          id: '',
          name: '',
          code: '',
          order: 0,
        };
        onChange(emptyCruiseLine);
        return;
      }

      const cruiseLine = cruiseLines.find((cruiseLine) => cruiseLine.id === e.target.value);
      onChange(cruiseLine);
    },
    [allowEmpty, cruiseLines, onChange],
  );

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const element = e.target as HTMLDivElement;
      const isAtBottom = element.scrollTop + element.clientHeight >= element.scrollHeight - 50;
      if (isAtBottom && !loading && hasMore) {
        setPage((prev) => prev + 1);
      }
    },
    [loading, hasMore],
  );

  useEffect(() => {
    fetchCruiseLines(1);
  }, [fetchCruiseLines]);

  useEffect(() => {
    if (page > 1 && !loading && hasMore) {
      fetchCruiseLines(page);
    }
  }, [page, fetchCruiseLines, loading, hasMore]);

  useEffect(() => {
    if (initialCruiseLine) {
      return;
    }
    const cruiseLine = cruiseLines.find((cruiseLine) => cruiseLine.id === value);
    if (cruiseLine) {
      onChange(cruiseLine);
      setInitialCruiseLine(true);
    }
  }, [cruiseLines, onChange, value, initialCruiseLine]);

  return (
    <Stack spacing={2} direction="row">
      <TextField
        select
        fullWidth
        label={label}
        value={value}
        onChange={handlerOnChange}
        required={required}
        InputLabelProps={{ shrink: true }}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              onScroll: handleScroll,
              style: { maxHeight: 300 },
            },
          },
        }}
      >
        {allowEmpty && (
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
        )}
        {cruiseLines.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      {loading && <Spinner size={20} />}
    </Stack>
  );
}

export default InputCruiseLineSelect;
