import qs from 'qs';

import { LereAbandonedCarts, SurchargeSuggestionDB, SurchargeSuggestionFilters } from '~/components/LERE/types';

import { json_headers, request } from './common';

function basePath() {
  return window.configs.API_HOST + '/api/recommendation';
}

function sendTPFMTestEmail(emailVariant: string, emailRecipient: string, useRecipientData: boolean) {
  return request(`${basePath()}/tpfm_operations/tpfm_test_trigger`, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify({
      email_variant: emailVariant,
      email_recipient: emailRecipient,
      use_recipient_data: useRecipientData,
    }),
  });
}

function getSurchargeSuggestions(page: number, limit = 5) {
  const queryParams = { page, limit };
  return request(`${basePath()}/dynamic_pricing/?${qs.stringify(queryParams)}`, {
    method: 'GET',
    headers: json_headers,
  });
}

function getLatestSurchargeSuggestions(page: number, limit = 10, filters?: SurchargeSuggestionFilters) {
  const queryParams = {
    page,
    limit,
    aggregate: filters?.aggregate,
    offer_id: filters?.offerIdEquals,
    property_name: filters?.propertyNameLike,
    room_type_name: filters?.roomTypeNameLike,
    internal_surcharge: filters?.internalSurchargeEquals,
    sales_gte: filters?.salesGte,
    allocated_gte: filters?.allocatedGte,
    suggestion_type: filters?.suggestionType,
    score_gte: filters?.scoreGte,
    score_lte: filters?.scoreLte,
  };
  return request(`${basePath()}/dynamic_pricing/latest?${qs.stringify(queryParams)}`, {
    method: 'GET',
    headers: json_headers,
  });
}

function createSurchargeSuggestion(body: SurchargeSuggestionDB) {
  return request(`${basePath()}/dynamic_pricing`, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify(body),
  });
}

function sendChangeLogEmail() {
  return request(`${basePath()}/dynamic_pricing/send-changelog`, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify({}),
  });
}

function getAbandonedCarts(params: { userId: string; brand: string; region: string }): Promise<LereAbandonedCarts> {
  const { userId, brand, region } = params;

  const queryParams = {
    region,
    brand,
    limit: 50,
    categories: 'sticky_abandoned_carts',
    aggregation_mode: true,
  };
  return request(`${basePath()}/personalisation/${userId}?${qs.stringify(queryParams)}`, {
    method: 'GET',
    headers: json_headers,
  });
}

export default {
  sendTPFMTestEmail,
  getSurchargeSuggestions,
  getLatestSurchargeSuggestions,
  createSurchargeSuggestion,
  sendChangeLogEmail,
  getAbandonedCarts,
};
