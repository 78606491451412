import React from 'react';

import ExportDetailedBookingsForm from '../Common/Forms/ExportBQDetailedBookingsForm';
import ExportBreakdownNonredemptionForm from '../Common/Forms/ExportBreakdownNonredemptionForm';
import ExportBookingsForm from '../Common/Forms/ExportDetailedBookingsForm';
import ExportDetailedNonredemptionForm from '../Common/Forms/ExportDetailedNonredemptionForm';
import ExportLEDNonredemptionsForm from '../Common/Forms/ExportLEDNonredemptionsForm';
import ExportNonRefundableBookingsForm from '../Common/Forms/ExportNonRefundableBookingsForm';
import ExportSummaryBookingsForm from '../Common/Forms/ExportSummaryBookingsForm';
import ExportSummaryNonredemptionForm from '../Common/Forms/ExportSummaryNonredemptionForm';
import ExportTaxInvoiceForm from '../Common/Forms/ExportTaxInvoiceForm';
import ExportUnpaidBookingsForm from '../Common/Forms/ExportUnpaidBookingsForm';
import ExportUploadedBookings from '../Common/Forms/ExportUploadedBookings';
import ExportVccBookingsForm from '../Common/Forms/ExportVccBookingsForm';
import ExportVendorBookingPaymentsReportForm from '../Common/Forms/ExportVendorBookingPaymentsReportForm';
import ExportVendorHoldbackReportForm from '../Common/Forms/ExportVendorHoldbackReportForm';
import ExportVendorPaymentsReportForm from '../Common/Forms/ExportVendorPaymentsReportForm';

import ContentWithSidebar from './ContentWithSidebar';

const DEFAULT_REPORT = 'detailed-bookings';

const reports = [
  {
    title: 'Detailed Bookings',
    slug: 'detailed-bookings',
    component: ExportBookingsForm,
  },
  {
    title: 'Detailed Bookings - New - Testing',
    slug: 'detailed-bookings-bq',
    component: ExportDetailedBookingsForm,
  },
  {
    title: 'Summary Bookings',
    slug: 'summary-bookings',
    component: ExportSummaryBookingsForm,
  },
  {
    title: 'VCC Bookings',
    slug: 'vcc-bookings',
    component: ExportVccBookingsForm,
  },
  {
    title: 'Uploaded Bookings',
    slug: 'uploaded-bookings',
    component: ExportUploadedBookings,
  },
  {
    title: 'Non-refundable Bookings',
    slug: 'non-refundable-bookings',
    component: ExportNonRefundableBookingsForm,
  },
  {
    title: 'Breakdown Nonredemption Bookings',
    slug: 'breakdown-nonredemption-bookings',
    component: ExportBreakdownNonredemptionForm,
  },
  {
    title: 'LED nonredemption bookings',
    slug: 'led-nonredemption-bookings',
    component: ExportLEDNonredemptionsForm,
  },
  {
    title: 'Detailed Nonredemption Bookings',
    slug: 'detailed-nonredemption-bookings',
    component: ExportDetailedNonredemptionForm,
  },
  {
    title: 'Summary Nonredemption Bookings',
    slug: 'summary-nonredemption-bookings',
    component: ExportSummaryNonredemptionForm,
  },
  {
    title: 'Tax Invoice report',
    slug: 'tax-invoice',
    component: ExportTaxInvoiceForm,
  },
  {
    title: 'Vendor Offer Payments report',
    slug: 'vendor-offer-payments',
    component: ExportVendorPaymentsReportForm,
  },
  {
    title: 'Vendor Booking Payments report',
    slug: 'vendor-booking-payments',
    component: ExportVendorBookingPaymentsReportForm,
  },
  {
    title: 'Vendor Holdback report (from live data)',
    slug: 'vendor-holdback',
    component: ExportVendorHoldbackReportForm,
  },
  {
    title: 'Unpaid Bookings Report',
    slug: 'unpaid-bookings',
    component: ExportUnpaidBookingsForm,
  },
];

export default function APReportsPage() {
  return <ContentWithSidebar items={reports} defaultItem={DEFAULT_REPORT} />;
}
