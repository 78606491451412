import { CruisesContract as API, definitions } from '@luxuryescapes/contract-svc-cruise';

import cruisesAPI from './CruisesApiService';

const resource = 'vendors';

export type CruiseLine = definitions['VENDOR_BASE'];
type CruiseLineListParams = definitions['GET_VENDORS_QUERY'];
type UpdateCruiseLineBody = definitions['UPDATE_VENDOR_BY_ID_BODY'];
type SortCruiseLines = definitions['SORT_VENDORS'];

const listWithPagination = async (paginationParams: CruiseLineListParams): Promise<API.Response<Array<CruiseLine>>> => {
  return await cruisesAPI.http.get({ resource, paginationParams });
};

const updateCruiseLine = async (
  cruiseLineId: string,
  body: UpdateCruiseLineBody,
): Promise<API.Response<CruiseLine>> => {
  const response = await cruisesAPI.http.put<CruiseLine>({
    resource: `${resource}/${cruiseLineId}`,
    body,
  });

  return response;
};

const sortCruiseLines = async (cruiseLines: SortCruiseLines['vendors']): Promise<API.Response<SortCruiseLines>> => {
  return cruisesAPI.http.put({
    resource: `${resource}/sort`,
    body: { vendors: cruiseLines },
  });
};

const getCruiseLineById = async (cruiseLineId: string): Promise<CruiseLine> => {
  const response = await cruisesAPI.http.get<CruiseLine>({
    resource: `${resource}/${cruiseLineId}`,
  });

  return response.result;
};

export default {
  listWithPagination,
  updateCruiseLine,
  sortCruiseLines,
  getCruiseLineById,
};
