import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';

import { useUpdateExperienceValues } from '~/components/Experiences/hooks';

import { ORIGINAL_EXPERIENCE_CONTENT_TAB } from '~/consts/experiences';

import { CategoryItem, CurationFeatureManager, ExperienceOffer } from '~/services/ExperiencesService';

interface Props {
  categories: CategoryItem[];
  experience: ExperienceOffer;
  curationFeatures?: CurationFeatureManager;
}

const ExperienceCurationCategories: React.FC<Props> = (props) => {
  const [selectedCategories, setSelectedCategories] = React.useState<Array<CategoryItem>>([]);

  const { updateValues, contentTab } = useUpdateExperienceValues();

  const { experience, categories, curationFeatures } = props;

  React.useEffect(() => {
    setSelectedCategories(experience.categories);
  }, [experience.categories]);

  const categoriesOptions = React.useMemo(() => {
    return categories
      ?.filter(
        (category) =>
          category.level === 0 &&
          !curationFeatures?.categories?.categoriesDisabled.some(
            (disabledCategoryId) => disabledCategoryId === category.id,
          ),
      )
      ?.map((category) => {
        return {
          parent: category,
          children: categories.filter(
            (categoryChild) =>
              categoryChild.parent === category.id &&
              !curationFeatures?.categories?.categoriesDisabled.some(
                (disabledCategoryId) => disabledCategoryId === categoryChild.id,
              ),
          ),
        };
      });
  }, [categories, curationFeatures]);

  const handleCategory = (event) => {
    const { value, checked } = event.target;

    let updatedCategories: Array<CategoryItem> = [];

    if (checked) {
      updatedCategories = [...selectedCategories, categories.find((category) => category.value === value)];
    } else {
      updatedCategories = selectedCategories.filter((selectedCategory) => selectedCategory.value !== value);
    }

    setSelectedCategories(updatedCategories);
    updateValues({
      categories: updatedCategories,
    });
  };

  return (
    <>
      <Box>
        {categoriesOptions?.map((category, index) => (
          <Accordion id={category.parent.label} key={category.parent.value} variant="outlined">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>{category.parent.label}</AccordionSummary>
            <AccordionSummary>
              <FormGroup>
                {category.children.map((child) => {
                  const checked = selectedCategories.some((category) => category.value === child.value);

                  return (
                    <FormControlLabel
                      key={child.value}
                      control={
                        <Checkbox
                          checked={checked}
                          value={child.value}
                          onChange={handleCategory}
                          disabled={contentTab === ORIGINAL_EXPERIENCE_CONTENT_TAB}
                        />
                      }
                      label={child.label}
                    />
                  );
                })}
              </FormGroup>
            </AccordionSummary>
          </Accordion>
        ))}
      </Box>
    </>
  );
};

export default ExperienceCurationCategories;
