export const LE_BUSINESS_TRAVELLER = 'lebusinesstraveller';

export const BUSINESS_TRAVELLER_SERVICE_FEE_SOURCE_OPTIONS = {
  ONLINE: 'Online',
  CALL_CENTRE: 'Call Centre',
  AFTER_HOURS_CALL_CENTRE: 'After Hours Call Centre',
} as const;

export const BUSINESS_TRAVELLER_SERVICE_FEE_TYPE_OPTIONS = {
  BOOKING: 'Booking',
  BOOKING_CHANGES: 'Booking Changes',
  TICKET_REFUND: 'Ticket Refund',
} as const;

export const BUSINESS_TRAVELLER_SERVICE_FEE_DESTINATION_OPTIONS = {
  DOMESTIC: 'Domestic',
  INTERNATIONAL: 'International',
} as const;

export const BUSINESS_TRAVELLER_SERVICE_FEE_PRODUCT_OPTIONS = {
  HOTELS: 'Hotels',
  SINGLE_RETURN_FLIGHTS: 'Single Return Flights',
  MULTI_CITY_FLIGHTS: 'Multi City Flights',
  CAR_HIRE: 'Car Hire',
} as const;
