import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import {
  BUSINESS_TRAVELLER_SERVICE_FEE_DESTINATION_OPTIONS,
  BUSINESS_TRAVELLER_SERVICE_FEE_PRODUCT_OPTIONS,
  BUSINESS_TRAVELLER_SERVICE_FEE_SOURCE_OPTIONS,
  BUSINESS_TRAVELLER_SERVICE_FEE_TYPE_OPTIONS,
} from '~/consts/businessTraveller';

import { createServiceFee, patchServiceFee } from '~/services/BusinessTraveller/BusinessTravellerService';

interface Props {
  businessId: string;
  serviceFee?: App.BusinessServiceFee; // Optional for create, required for edit
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  mode: 'create' | 'edit';
}

const defaultServiceFee: App.BusinessServiceFee = {
  id: '',
  businessId: '',
  source: 'ONLINE',
  type: 'BOOKING',
  destination: null,
  product: null,
  amount: 0,
  conciergeAmount: 0,
};

export default function BusinessServiceFeesCreateEditModal({
  businessId,
  serviceFee: initialServiceFee,
  isOpen,
  onClose,
  onSuccess,
  mode,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [serviceFee, setServiceFee] = useState<App.BusinessServiceFee>(initialServiceFee || defaultServiceFee);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setServiceFee((prevState) => ({
      ...prevState,
      [name]: name === 'amount' || name === 'conciergeAmount' ? Number(value) : value,
    }));
  };

  async function handleSubmission(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);

    const { source, type, destination, product, amount, conciergeAmount } = serviceFee;
    const payload = {
      source,
      type,
      destination,
      product,
      amount: Number(amount),
      conciergeAmount: Number(conciergeAmount),
    };

    try {
      if (mode === 'create') {
        await createServiceFee(payload, businessId);
      } else {
        await patchServiceFee(payload, businessId, serviceFee.id);
      }

      enqueueSnackbar(`Service fee ${mode === 'create' ? 'created' : 'updated'} successfully`, {
        variant: 'success',
      });
      onSuccess();
      onClose();
    } catch (error) {
      const message = error instanceof Error ? error.message : JSON.stringify(error, null, 2);
      enqueueSnackbar(`Error ${mode === 'create' ? 'creating' : 'updating'} service fee [${message}]`, {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (isOpen && mode === 'create') {
      setServiceFee(defaultServiceFee);
    }
  }, [isOpen, mode]);

  return (
    <Dialog open={isOpen} onClose={isLoading ? undefined : onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmission}>
        <DialogTitle>{mode === 'create' ? 'Create' : 'Edit'} Service Fee</DialogTitle>
        <DialogContent>
          <Stack gap={2} alignItems="start">
            <Grid2 container spacing={3}>
              <Grid2 xs={12} md={6}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel shrink>Source</InputLabel>
                  <Select
                    value={serviceFee.source}
                    id="business_source"
                    label="Source"
                    name="source"
                    onChange={handleChange}
                  >
                    {Object.entries(BUSINESS_TRAVELLER_SERVICE_FEE_SOURCE_OPTIONS).map(([value, label]) => (
                      <MenuItem value={value} key={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid2>

              <Grid2 xs={12} md={6}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel shrink>Type</InputLabel>
                  <Select value={serviceFee.type} id="business_type" label="Type" name="type" onChange={handleChange}>
                    {Object.entries(BUSINESS_TRAVELLER_SERVICE_FEE_TYPE_OPTIONS).map(([value, label]) => (
                      <MenuItem value={value} key={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid2>

              <Grid2 xs={12} md={6}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel shrink>Destination</InputLabel>
                  <Select
                    value={serviceFee.destination ?? ''}
                    id="business_destination"
                    label="Destination"
                    name="destination"
                    placeholder="Select a destination"
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <Typography fontStyle="italic">Select a destination</Typography>
                    </MenuItem>
                    {Object.entries(BUSINESS_TRAVELLER_SERVICE_FEE_DESTINATION_OPTIONS).map(([value, label]) => (
                      <MenuItem value={value} key={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid2>

              <Grid2 xs={12} md={6}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel shrink>Product</InputLabel>
                  <Select
                    value={serviceFee.product ?? ''}
                    id="business_product"
                    label="Product"
                    name="product"
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <Typography fontStyle="italic">Select a product</Typography>
                    </MenuItem>
                    {Object.entries(BUSINESS_TRAVELLER_SERVICE_FEE_PRODUCT_OPTIONS).map(([value, label]) => (
                      <MenuItem value={value} key={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid2>

              <Grid2 xs={12} md={6}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel shrink>Amount</InputLabel>
                  <TextField
                    id="business_amount"
                    name="amount"
                    label="Amount"
                    onChange={handleChange}
                    placeholder="Enter amount"
                    value={serviceFee.amount}
                    type="number"
                    variant="outlined"
                  />
                </FormControl>
              </Grid2>

              <Grid2 xs={12} md={6}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel shrink>Concierge Amount</InputLabel>
                  <TextField
                    id="business_concierge_amount"
                    name="conciergeAmount"
                    label="Concierge Amount"
                    onChange={handleChange}
                    placeholder="Enter amount"
                    value={serviceFee.conciergeAmount}
                    type="number"
                    variant="outlined"
                  />
                </FormControl>
              </Grid2>
            </Grid2>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} variant="text" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton loading={isLoading} type="submit" variant="contained" color="primary">
            {mode === 'create' ? 'Create' : 'Update'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
