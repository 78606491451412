import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import { Stack } from '@mui/material';

import { createFlightCampaign, deleteFlightCampaign } from '~/services/FlightsService';

import { useFlightCampaigns } from '../hooks/useFlightCampaigns';

import { FlightsCampaignsForm, FormState } from './FlightsCampaignsForm';
import { FlightsCampaignsTable } from './FlightsCampaignsTable';

export function FlightsCampaings() {
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const history = useHistory();
  const { error: fetchingDataError, loading: loadingData, data, revalidate } = useFlightCampaigns();

  const handleDelete = useCallback(
    async (id: string) => {
      try {
        await deleteFlightCampaign(id);
        revalidate();
        enqueueSnackbar('Campaign was deleted', { variant: 'success' });
      } catch {
        enqueueSnackbar('Something went wrong, please try again', { variant: 'error' });
      }
    },
    [enqueueSnackbar, revalidate],
  );

  const handleSubmit = useCallback(
    async (data: FormState) => {
      setError(undefined);
      setSaving(true);

      try {
        const { result } = await createFlightCampaign({
          brand: data.brand.join(','),
          type: data.type,
          title: data.title,
          region: data.region,
          header: data.header,
          description: data.description,
          start_date: data.startDate,
          end_date: data.endDate,
          hero_image_public_id: data.heroImage,
          terms: data.terms,
          airline_code: data.airlineCode,
        });

        history.push(`/flights/campaigns/${result.id}`);
        enqueueSnackbar('Campaign was created', { variant: 'success' });
      } catch {
        setError('Something went wrong, please try again');
      }

      setSaving(false);
    },
    [enqueueSnackbar, history],
  );

  return (
    <Stack gap={6}>
      <FlightsCampaignsForm onSubmit={handleSubmit} error={error} loading={saving} />
      <FlightsCampaignsTable data={data} onDelete={handleDelete} error={fetchingDataError} loading={loadingData} />
    </Stack>
  );
}
