export type Target =
  | {
      criterion:
        | Criteria['hotelDestinationCountry']
        | Criteria['hotelDestinationRegion']
        | Criteria['flightDepartureAirportCode']
        | Criteria['flightArrivalAirportCode'];
      value: string;
    }
  | {
      criterion: Criteria['travelBetweenDates'];
      startDate: string;
      endDate: string;
    };

export type FormInput = {
  campaignTitle: string;
  startDate: string;
  endDate: string;
  bannerTitle: string;
  bannerOverview: string;
  bannerAppearance: {
    icon: 'check' | 'info' | 'warning' | 'error';
    color: 'info' | 'warning' | 'error' | 'success';
  };
  modalTitle: string;
  modalDescription: string;
  targets: Array<Target>;
};

export const TargetDictionary: Record<string, string> = {
  hotelDestinationCountry: 'the accommodations country is',
  hotelDestinationRegion: 'the accommodations region is',
  flightDepartureAirportCode: 'a flight in the order departs from airport',
  flightArrivalAirportCode: 'a flight in the order arrives at airport',
  travelBetweenDates: 'the check-in or check-out date is between',
};

export const CRITERIA = {
  hotelDestinationCountry: 'hotelDestinationCountry',
  hotelDestinationRegion: 'hotelDestinationRegion',
  flightDepartureAirportCode: 'flightDepartureAirportCode',
  flightArrivalAirportCode: 'flightArrivalAirportCode',
  travelBetweenDates: 'travelBetweenDates',
} as const;

export type Criteria = typeof CRITERIA;
