import React, { useCallback } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

import BusinessServiceFeesCreateEditModal from './BusinessServiceFeesCreateEditModal';

interface Props {
  serviceFee: App.BusinessServiceFee;
  onSuccess: () => void;
}

export default function ServiceFeeGridActions({ serviceFee, onSuccess }: Props) {
  const { isToggled: isEditDialogOpen, toggleOn: openEditDialog, toggleOff: closeEditDialog } = useToggleState();

  const handleServiceFeeEditClick = useCallback(() => {
    openEditDialog();
  }, [openEditDialog]);

  return (
    <>
      <IconButton onClick={handleServiceFeeEditClick}>
        <EditIcon />
      </IconButton>
      <BusinessServiceFeesCreateEditModal
        businessId={serviceFee.businessId}
        serviceFee={serviceFee}
        isOpen={isEditDialogOpen}
        onSuccess={onSuccess}
        onClose={closeEditDialog}
        mode="edit"
      />
    </>
  );
}
