import React from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { Rate } from '~/services/AccommodationService';

interface CancellationPolicyModalProps {
  rate: Rate;
}

export default function CancellationPolicyModal({ rate }: CancellationPolicyModalProps) {
  const theme = useTheme();

  if (rate.fullRefundableUntil) {
    return (
      <Box>
        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold', color: theme.palette.success.main }}>
          Full refund available until {new Date(rate.fullRefundableUntil).toLocaleDateString()}
        </Typography>

        {rate.cancellationPolicies && rate.cancellationPolicies.length > 0 ? (
          <>
            <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
              Cancellation Penalties:
            </Typography>
            {rate.cancellationPolicies.map((policy, index: number) => (
              <Box key={index} sx={{ mb: 1, p: 1, bgcolor: theme.palette.warning.light, borderRadius: 1 }}>
                <Typography>
                  <Typography component="span" fontWeight="bold">
                    {policy.percent}% penalty
                  </Typography>{' '}
                  applies from {new Date(policy.start).toLocaleDateString()} to{' '}
                  {new Date(policy.end).toLocaleDateString()}
                </Typography>
              </Box>
            ))}
          </>
        ) : (
          <Typography>No additional cancellation penalties specified.</Typography>
        )}
      </Box>
    );
  } else if (rate.cancellationPolicies && rate.cancellationPolicies.length > 0) {
    return (
      <Box>
        <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
          Cancellation Penalties:
        </Typography>
        {rate.cancellationPolicies.map((policy, index: number) => (
          <Box key={index} sx={{ mb: 1, p: 1, bgcolor: theme.palette.warning.light, borderRadius: 1 }}>
            <Typography>
              <Typography component="span" fontWeight="bold">
                {policy.percent}% penalty
              </Typography>{' '}
              applies from {new Date(policy.start).toLocaleDateString()} to {new Date(policy.end).toLocaleDateString()}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  } else {
    return <Typography color="error">This rate is non-refundable. No cancellations or changes are allowed.</Typography>;
  }
}
