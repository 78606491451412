import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { formatDateLong } from '~/services/TimeService';

import { titleCase } from '~/utils/stringUtils';

const orderDateFormatter = (params: GridRenderCellParams<App.Order>) => {
  const order = params.row;

  const orderItemsAggregate: Array<{
    name: string;
    status: string;
    type: string;
    date: Date | undefined;
  }> = [];

  if (order.has_bedbank) {
    order.bedbank_items.forEach((bedbankItem) => {
      orderItemsAggregate.push({
        name: bedbankItem.name,
        type: bedbankItem.type,
        status: bedbankItem.status,
        date: new Date(bedbankItem.check_in),
      });
    });
  }

  if (order.accommodation_items.length > 0) {
    order.accommodation_items.forEach((accomodationItem) => {
      orderItemsAggregate.push({
        name: accomodationItem.name,
        status: accomodationItem.status,
        type: accomodationItem.type,
        date: new Date(accomodationItem.reservation?.check_in || accomodationItem.reservation?.start_date),
      });
    });
  }

  if (order.has_experience) {
    order.experience_items.forEach((exp) => {
      orderItemsAggregate.push({
        name: exp.title,
        type: exp.type,
        status: exp.status,
        date: exp?.ticket?.date ? new Date(exp.ticket.date) : undefined,
      });
    });
  }

  if (order.has_flight) {
    order.flight_items.forEach((flightItem) => {
      // TODO: export appropriate types from backend service and use it here
      if (flightItem.journey?.departing?.flights?.length) {
        orderItemsAggregate.push({
          name: '',
          type: flightItem.type,
          status: flightItem.status,
          // there could be multiple flights, hence select the earliest one.
          date: new Date(
            Math.min(
              ...flightItem.journey.departing.flights.map((flight) => new Date(flight.departing_date).getTime()),
            ),
          ),
        });
      }
    });
  }

  if (order.has_insurance) {
    order.insurance_items.forEach((insurance) => {
      orderItemsAggregate.push({
        name: '',
        type: insurance.type,
        status: insurance.status,
        date: insurance.start_date ? new Date(insurance.start_date) : undefined,
      });
    });
  }

  if (order.has_tour) {
    order.tour_items.forEach((tour) => {
      orderItemsAggregate.push({
        name: tour.tour_name,
        type: tour.type,
        status: tour.status,
        date: tour.start_date ? new Date(tour.start_date) : undefined,
      });
    });
  }

  if (order.cruise_items.length > 0) {
    order.cruise_items.forEach((cruiseItem) => {
      orderItemsAggregate.push({
        name: cruiseItem.offer_name,
        type: cruiseItem.type,
        status: cruiseItem.status,
        date: cruiseItem.departure_date ? new Date(cruiseItem.departure_date) : undefined,
      });
    });
  }

  if (order.car_hire_items.length > 0) {
    order.car_hire_items.forEach((carHire) => {
      orderItemsAggregate.push({
        name: '',
        type: carHire.type,
        status: carHire.status,
        date: new Date(carHire.reservation.pickUp.date),
      });
    });
  }

  if (orderItemsAggregate.length === 0) {
    return <Typography>No dates</Typography>;
  }

  return (
    // TODO: Refractor this to be better if this grows.
    // TODO: Export the status type and backend service, and use it here.
    <Stack
      direction="column"
      key={`${order.id_orders}-check-in`}
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
    >
      {orderItemsAggregate.map((orderItem, index) => (
        <Box
          key={`${orderItem.name}-${orderItem.type}-${index}`}
          sx={{
            marginBottom: 1,
          }}
        >
          {orderItem.type !== 'accommodation' && orderItem.type !== 'experience' && (
            <Typography fontWeight="bold" component="span">
              {`${titleCase(orderItem.type.replace(/_/g, ' '))}:`}
            </Typography>
          )}
          {(orderItem.type === 'accommodation' || orderItem.type === 'experience') && (
            <Typography
              fontWeight="bold"
              title={orderItem.name}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {orderItem.name}
            </Typography>
          )}
          {orderItem.date === undefined ? (
            <Typography>No Date</Typography>
          ) : orderItem.status === 'cancelled' ? (
            <Typography sx={{ textDecoration: 'line-through' }}>{formatDateLong(orderItem.date)}</Typography>
          ) : (
            <Typography>{formatDateLong(orderItem.date)}</Typography>
          )}
        </Box>
      ))}
    </Stack>
  );
};

export default orderDateFormatter;
