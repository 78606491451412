import React, { useCallback } from 'react';

import { Helmet } from 'react-helmet';
import { Route, Switch, useParams } from 'react-router';

import { Alert, AlertTitle, Box, Button, Card, CircularProgress, Container, Stack } from '@mui/material';

import AccommodationPropertyRatesSection from '~/components/Accommodation/Pages/PropertyDetails/sections/AccommodationPropertyRates/AccommodationPropertyRatesSection';

import { getProperty } from '~/services/AccommodationService';

import { useAccommodationServiceFetch } from '../../hooks/useAccommodationServiceFetch';

import AccommodationPropertyDetailsNav from './AccommodationPropertyDetailsNav';
import AccommodationPropertyDetailsOverviewPane from './AccommodationPropertyDetailsOverviewPane';
import AccommodationPropertyDetailsContextProvider from './context/AccommodationPropertyDetailsContext';
import AccommodationPropertyDetailsSection from './sections/AccommodationPropertyDetails/AccommodationPropertyDetailsSection';
import AccommodationPropertyRoomMappingSection from './sections/AccommodationPropertyRoomMapping/AccommodationPropertyRoomMappingSection';
import AccommodationPropertyRoomsSection from './sections/AccommodationPropertyRooms/AccommodationPropertyRoomsSection';
import AccommodationPropertySuppliersSection from './sections/AccommodationPropertySuppliers/AccommodationPropertySuppliersSection';

const ROUTES: Array<{ path: string; component: JSX.Element }> = [
  { path: '/accommodation/properties/:propertyId', component: <AccommodationPropertyDetailsSection /> },
  { path: '/accommodation/properties/:propertyId/suppliers', component: <AccommodationPropertySuppliersSection /> },
  { path: '/accommodation/properties/:propertyId/rooms', component: <AccommodationPropertyRoomsSection /> },
  {
    path: '/accommodation/properties/:propertyId/room-mapping',
    component: <AccommodationPropertyRoomMappingSection />,
  },
  { path: '/accommodation/properties/:propertyId/rates', component: <AccommodationPropertyRatesSection /> },
];

export default function AccommodationPropertyDetailsPage() {
  const { propertyId } = useParams<{ propertyId: string }>();

  const getPropertyFetch = useCallback(() => getProperty({ propertyId }), [propertyId]);

  const { fetchReqState } = useAccommodationServiceFetch({
    fetchFn: getPropertyFetch,
  });

  if (fetchReqState.status === 'pending' || fetchReqState.status === 'uninitiated') {
    return (
      <Box p={2} display="flex" justifyContent="center">
        <CircularProgress variant="indeterminate" />
      </Box>
    );
  }

  if (fetchReqState.status === 'failed') {
    return (
      <Alert
        severity="error"
        action={
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => {
              fetch(propertyId);
            }}
          >
            Retry
          </Button>
        }
      >
        <AlertTitle>Failed to fetch the property.</AlertTitle>
        {JSON.stringify(fetchReqState.error)}
      </Alert>
    );
  }

  return (
    <AccommodationPropertyDetailsContextProvider details={fetchReqState.result}>
      <Helmet>
        <title>Property | {fetchReqState.result.name || ''}</title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction="column" gap={4}>
          <AccommodationPropertyDetailsOverviewPane />

          <Box flexShrink={0}>
            <Card sx={{ position: 'sticky', top: 16 }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <AccommodationPropertyDetailsNav />
              </Box>
            </Card>
          </Box>
          <Stack direction="column" flexGrow={1}>
            <Switch>
              {ROUTES.map(({ path, component }) => (
                <Route exact path={path} key={path}>
                  {component}
                </Route>
              ))}
            </Switch>
          </Stack>
        </Stack>
      </Container>
    </AccommodationPropertyDetailsContextProvider>
  );
}
