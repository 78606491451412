import React, { useEffect, useState } from 'react';

import { isEqual } from 'lodash';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Save } from '@mui/icons-material';
import { Box, Button, Grid, MenuItem, Stack, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  getGridNumericOperators,
  getGridStringOperators,
} from '@mui/x-data-grid';

import { SCORE_TIER_THRESHOLDS } from '~/consts/lere';

import LereService from '~/services/LereService';
import ReservationService from '~/services/ReservationService';
import { formatDateISO, formatDateSlashes, formatDateSlashesWithClock, unixToDate } from '~/services/TimeService';

import currencyFormatter from '~/utils/currencyFormatter';
import { presentSurchargeDateBlock } from '~/utils/reservationUtils';

import GridPagination from '../Common/Elements/GridPagination';
import { Checkbox, Select } from '../Common/Forms/fields';
import Input from '../Common/Forms/fields/Input';
import LoadingButton from '../Common/LoadingButton';
import { useConfirmDialog } from '../Common/Providers/ConfirmDialogProvider';

import NameAndIdFormatter from './formatters/NameAndIdFormatter';
import StatisticsFormatter from './formatters/StatisticsFormatter';
import {
  ConfidenceScoreTier,
  LerePaginatedResponse,
  LereTabComponentProps,
  SuggestionType,
  SurchargeSuggestionBQ,
  SurchargeSuggestionFilters,
} from './types';

const ACCEPTED = 'accepted';
const INTERNAL_SURCHARGE_TYPE = 'internal';
const REJECTED = 'rejected';
const equalsOnlyFilter = getGridStringOperators().filter((operator) => operator.value === 'equals');
const containsOnlyFilter = getGridStringOperators().filter((operator) => operator.value === 'contains');
const gteOnlyFilter = getGridNumericOperators().filter((operator) => operator.value === '>=');
const numericEqualsOnlyFilter = getGridNumericOperators().filter((operator) => operator.value === '=');

type FormInput = {
  offerId: string;
  propertyName: string;
  roomTypeName: string;
  aggregate: boolean;
  noRMSurchargeApplied: boolean;
  hasRecentSales: boolean;
  hasFiveOrMoreRooms: boolean;
  suggestionType: SuggestionType;
  scoreTier: ConfidenceScoreTier;
};

export default function RMSurchargeSuggestions({ onLereError }: LereTabComponentProps) {
  const showConfirmDialog = useConfirmDialog();
  const { control, handleSubmit, reset } = useForm<FormInput>({
    defaultValues: {
      offerId: '',
      propertyName: '',
      roomTypeName: '',
      aggregate: false,
      noRMSurchargeApplied: false,
      hasRecentSales: false,
      hasFiveOrMoreRooms: false,
      suggestionType: 'increase',
      scoreTier: ConfidenceScoreTier.ANY,
    },
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [surchargeSuggestionData, setSurchargeSuggestionData] = useState<LerePaginatedResponse<SurchargeSuggestionBQ>>({
    result: [],
    total: 0,
  });
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 5,
    page: 0,
  });
  const [filters, setFilters] = useState<undefined | SurchargeSuggestionFilters>(undefined);
  const [actionedRows, setActionedRows] = useState<Array<string>>([]);
  const visbleColumns: Array<GridColDef<SurchargeSuggestionBQ>> = [
    {
      field: 'offer_id',
      headerName: 'Offer Id',
      display: 'flex',
      flex: 1,
      filterOperators: equalsOnlyFilter,
      renderCell: ({ row }) => <NameAndIdFormatter id={row.offer_id} to={`/offers/${row.offer_id}`} />,
    },
    {
      field: 'property_name',
      headerName: 'Property Name',
      display: 'flex',
      flex: 1,
      filterable: true,
      filterOperators: containsOnlyFilter,
      renderCell: ({ row }) => (
        <NameAndIdFormatter
          name={row.property_name}
          id={row.property_id}
          to={`/vendors/${row.vendor_id}/properties/${row.property_id}`}
        />
      ),
    },
    {
      field: 'room_type_name',
      headerName: 'Room Type Name',
      display: 'flex',
      flex: 1,
      filterable: true,
      filterOperators: containsOnlyFilter,
      renderCell: ({ row }) => (
        <NameAndIdFormatter
          name={row.room_type_name}
          id={row.room_type_id}
          to={`/vendors/${row.vendor_id}/properties/${row.property_id}/room-types/${row.room_type_id}/room-rates/${row.room_rate_id}`}
        />
      ),
    },
    {
      field: 'actioned_start_date',
      headerName: 'Start Date',
      width: 120,
      type: 'date',
      editable: true,
      filterable: false,
      valueGetter: (value, row) => value ?? row.start_date,
      valueSetter: (value, row) => ({ ...row, actioned_start_date: formatDateISO(value) }),
      valueFormatter: formatDateSlashes,
    },
    {
      field: 'actioned_end_date',
      headerName: 'End Date',
      width: 120,
      type: 'date',
      editable: true,
      filterable: false,
      valueGetter: (value, row) => value ?? row.end_date,
      valueSetter: (value, row) => ({ ...row, actioned_end_date: formatDateISO(value) }),
      valueFormatter: formatDateSlashes,
    },
    {
      field: 'statistics',
      headerName: 'Statistics',
      display: 'flex',
      flex: 2,
      filterable: false,
      renderCell: StatisticsFormatter,
    },
    {
      field: 'actioned_surcharge',
      headerName: 'Suggested Surcharge',
      width: 100,
      editable: true,
      filterable: false,
      type: 'number',
      valueGetter: (value, row) => value ?? row.after_price - row.before_price,
      valueFormatter: (value: number) => currencyFormatter('AUD', value, 2),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      type: 'singleSelect',
      valueOptions: [ACCEPTED, REJECTED],
      editable: true,
      filterable: false,
      valueGetter: (value) => value ?? 'pending',
      valueFormatter: (value: string) => value.toUpperCase(),
    },
    {
      field: 'note',
      headerName: 'Note',
      flex: 1,
      display: 'flex',
      type: 'string',
      filterable: false,
      editable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      filterable: false,
      width: 80,
      renderCell: ({ row }) => {
        return (
          <LoadingButton
            DefaultIcon={Save}
            onSave={() => handleCreateSurcharge(row)}
            disabled={
              !(row.status === ACCEPTED || row.status === REJECTED) ||
              actionedRows.includes(row.id) ||
              // disable this suggestion type for now
              row.suggestion_type === 'remove'
            }
          />
        );
      },
    },
  ];
  const invisibleColumns: Array<GridColDef<SurchargeSuggestionBQ>> = [
    {
      field: 'internal_surcharge_total',
      headerName: 'Internal Surcharge',
      type: 'number',
      filterOperators: numericEqualsOnlyFilter,
    },
    { field: 'sales_last_3_days', headerName: 'Sales Last 3 Days', type: 'number', filterOperators: gteOnlyFilter },
    { field: 'booked', headerName: 'Booked', type: 'number', filterable: false },
    { field: 'allocated', headerName: 'Allocated', type: 'number', filterOperators: gteOnlyFilter },
    { field: 'vendor_surcharge_total', headerName: 'Vendor Surcharge', type: 'number', filterable: false },
  ];

  async function handleCreateSurcharge(row: SurchargeSuggestionBQ): Promise<void> {
    const {
      property_id: propertyId,
      room_type_id: roomTypeId,
      room_rate_id: roomRateId,
      actioned_start_date: startDate,
      actioned_end_date: endDate,
      actioned_surcharge: surchargeAmount,
    } = row;

    if (row.status === ACCEPTED) {
      const userConfirmed = await showConfirmDialog({
        title: 'Confirm Surcharge Update',
        description:
          'This change will modify the surcharge and overwrite any existing dates within the specified range. Do you wish to continue?',
      });

      if (!userConfirmed) {
        throw new Error('Surcharge update was cancelled by the user.');
      }
      await ReservationService.createSurchargeDateBlock(
        await presentSurchargeDateBlock({
          propertyId,
          roomTypeId,
          roomRateId,
          surchargeAmount,
          surchargeCurrency: 'AUD',
          ranges: [{ startDate, endDate }],
          surchargeType: INTERNAL_SURCHARGE_TYPE,
        }),
        propertyId,
        roomTypeId,
        roomRateId,
        true,
      );
    }
    await LereService.createSurchargeSuggestion(row);
    setActionedRows((prev) => [...prev, row.id]);
  }

  const onSubmit: SubmitHandler<FormInput> = (data) => {
    const newFilters = {
      aggregate: data.aggregate,
      offerIdEquals: data.offerId,
      propertyNameLike: data.propertyName,
      roomTypeNameLike: data.roomTypeName,
      internalSurchargeEquals: data.noRMSurchargeApplied ? 0 : undefined,
      salesGte: data.hasRecentSales ? 1 : undefined,
      allocatedGte: data.hasFiveOrMoreRooms ? 5 : undefined,
      suggestionType: data.suggestionType,
      scoreGte: SCORE_TIER_THRESHOLDS[data.scoreTier]?.[0],
      scoreLte: SCORE_TIER_THRESHOLDS[data.scoreTier]?.[1],
    };

    if (isEqual(newFilters, filters)) {
      return;
    }
    setFilters(newFilters);
  };

  useEffect(() => {
    setIsLoading(true);
    setActionedRows([]);
    LereService.getLatestSurchargeSuggestions(paginationModel.page + 1, paginationModel.pageSize, filters)
      .then(setSurchargeSuggestionData)
      .catch(onLereError)
      .finally(() => setIsLoading(false));
  }, [paginationModel, setSurchargeSuggestionData, onLereError, filters]);

  return (
    <Box>
      <Typography variant="h2" fontSize="2em" fontWeight="medium">
        Search Surcharge Suggestions
      </Typography>
      <Typography variant="body1">
        Last BigQuery sync: <i>{formatDateSlashesWithClock(unixToDate(surchargeSuggestionData.timestamp))}</i>
      </Typography>
      <Stack direction="column" my={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Input disabled={isLoading} fullWidth control={control} muiLabel="Offer Id" name="offerId" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input disabled={isLoading} fullWidth control={control} muiLabel="Property Name" name="propertyName" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input disabled={isLoading} fullWidth control={control} muiLabel="Room Type Name" name="roomTypeName" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Select disabled={isLoading} control={control} muiLabel="Surcharge Suggestion Type" name="suggestionType">
              <MenuItem key="increase" value="increase">
                INCREASE
              </MenuItem>
              <MenuItem key="remove" value="remove">
                REMOVE
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Select disabled={isLoading} control={control} muiLabel="Confidence Score" name="scoreTier">
              {Object.entries(ConfidenceScoreTier).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Stack direction="row" alignItems="center">
          <Checkbox disabled={isLoading} control={control} label="Aggregate" name="aggregate" />
          <Checkbox
            disabled={isLoading}
            control={control}
            label="No RM Surcharge applied"
            name="noRMSurchargeApplied"
          />
          <Checkbox disabled={isLoading} control={control} label="Has sales in last 3 days" name="hasRecentSales" />
          <Checkbox disabled={isLoading} control={control} label="Has 5+ Rooms allocated" name="hasFiveOrMoreRooms" />
        </Stack>
        <Stack direction="row" justifyContent="end" spacing={2}>
          <Button variant="text" disabled={isLoading} onClick={() => reset()}>
            Clear all filters
          </Button>
          <Button variant="contained" type="submit" disabled={isLoading} onClick={handleSubmit(onSubmit)}>
            Search
          </Button>
        </Stack>
      </Stack>
      <DataGrid
        autoHeight
        columns={[...visbleColumns, ...invisibleColumns]}
        rows={surchargeSuggestionData.result}
        rowCount={surchargeSuggestionData.total}
        loading={isLoading}
        pagination
        paginationMode="server"
        pageSizeOptions={[5, 10, 15]}
        paginationModel={paginationModel}
        onPaginationModelChange={(model) => setPaginationModel(model)}
        getRowHeight={() => 'auto'}
        slots={{
          pagination: GridPagination,
        }}
        columnVisibilityModel={invisibleColumns.reduce((acc, col) => ({ ...acc, [col.field]: false }), {})}
        disableRowSelectionOnClick
        disableColumnSorting
        disableColumnMenu
      />
    </Box>
  );
}
