import React from 'react';

import WarningIcon from '@mui/icons-material/Warning';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

import { RestoreDialogProps, RestoreType } from './types';

function getRestoreTypeName(type: RestoreType): string {
  switch (type) {
    case 'BEDBANK':
      return 'Bedbank Offers';
    case 'OFFERS':
      return 'LE Offers';
    case 'PROPERTIES':
      return 'LE Properties';
    case 'ANALYTICS':
      return 'Analytics Data';
    default:
      return '';
  }
}

function RestoreDialog({ open, restoreType, timeRange, onClose, onConfirm }: RestoreDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <WarningIcon color="warning" fontSize="large" />
        Dangerous Action Warning
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Alert severity="warning" sx={{ mb: 2 }}>
            <AlertTitle>Warning: High Server Impact</AlertTitle>
            Restoring {getRestoreTypeName(restoreType)} will place{' '}
            <Typography component="span" sx={{ fontWeight: 'bold' }} display="inline">
              significant load
            </Typography>{' '}
            on the server and may take {timeRange} to complete.
          </Alert>

          <Typography variant="body1" gutterBottom>
            This action should{' '}
            <Typography component="span" sx={{ fontWeight: 'bold' }} display="inline">
              only
            </Typography>{' '}
            be performed in an emergency situation when:
          </Typography>

          <Box component="ul" sx={{ mb: 2 }}>
            <Typography component="li">All search functionality is completely down</Typography>
            <Typography component="li">The website is currently unusable</Typography>
            <Typography component="li">All other recovery options have been exhausted</Typography>
          </Box>

          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            If all of these conditions are not met, you should NOT proceed with this action.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="contained" color="error">
          I Understand the Risks - Proceed with Restore
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RestoreDialog;
