import sum from 'lodash/sum';

import { Order } from '@luxuryescapes/contract-svc-order';

import { ITEM_STATUS_CANCELLED, ITEM_STATUS_COMPLETED } from '~/consts/order';

import { formatDateOrdinalShortMonth } from '~/services/TimeService';

interface ItemWithStatus {
  status?: string;
}

export function isAllCancelled(items: Array<ItemWithStatus>): boolean {
  return items ? items.every((item) => item.status == ITEM_STATUS_CANCELLED) : true;
}

//credits do not exist at the line item level, they cannot be broken down
export function calculateCredit(order: Order.Order) {
  return order?.business_credit_items?.length > 0 ? sum(order.business_credit_items.map((c) => c.total)) : 0;
}

export function calculateDepositTotal(order: Order.Order) {
  const customOfferItems = order.custom_offer_items;
  if (customOfferItems?.length > 0) {
    let depositTotal = 0;

    for (const item of customOfferItems) {
      const paymentMetadata = item?.custom_offer?.payment_metadata;
      if (paymentMetadata?.customer_deposit_1_amount) {
        depositTotal += Math.round(paymentMetadata.customer_deposit_1_amount / 100);
      }
    }
    return depositTotal;
  }
  return 0;
}

export function getSuccessfulItemsTotal(order: Order.Order): number {
  const sumOfSuccessfulItems = (items) => {
    return items
      ? items.reduce((acc, { status, total }) => {
          return status === ITEM_STATUS_COMPLETED ? acc + parseFloat(total) : acc;
        }, 0)
      : 0;
  };

  return [
    order.accommodation_items,
    order.addon_items,
    order.bedbank_items,
    order.flight_items,
    order.offline_flight_items,
    order.gift_card_items,
    order.insurance_items,
    order.tour_items,
    order.experience_items,
    order.service_fee_items,
    order.cruise_items,
    order.subscription_items,
    order.custom_offer_items,
    order.car_hire_items,
  ].reduce((acc, items) => acc + sumOfSuccessfulItems(items), 0);
}

export function formatOrderDate(cell) {
  const date = new Date(cell);

  return formatDateOrdinalShortMonth(date);
}

export function getCustomerPhoneNumberFromOrder(order: App.Order) {
  if (!order.customer_phone_prefix || !order.customer_phone) {
    return '';
  }

  return '+' + order.customer_phone_prefix + order.customer_phone;
}

export function getRebookedCustomOrderIdFromOrder(order: Order.Order) {
  const filteredItems = order.bedbank_items.filter((row) => {
    return row.fk_rebooked_custom_order_id !== null;
  });

  if (filteredItems.length > 0) {
    return filteredItems[0].fk_rebooked_custom_order_id;
  }
  return null;
}

export const TYPE_ACCOMMODATION = 'accommodation';
export const TYPE_INSURANCE = 'insurance';
export const TYPE_FLIGHT = 'flight';
export const TYPE_OFFLINE_FLIGHT = 'offline_flight';
export const TYPE_GIFT_CARD = 'gift_card';
export const TYPE_BEDBANK = 'bedbank';
export const TYPE_ADDON = 'addon';
export const TYPE_EXPERIENCE = 'experience';
export const TYPE_HOTEL = 'hotel';
export const TYPE_TOUR_V2 = 'tour';
export const TYPE_TOUR = 'tour';
export const TYPE_SERVICE_FEE = 'service_fee';
export const TYPE_CRUISE = 'cruise';
export const TYPE_CAR_HIRE = 'car_hire';
export const TYPE_CUSTOM_OFFER = 'custom_offer';
export const TYPE_SUBSCRIPTION = 'subscription';
export const TYPE_BOOKING_PROTECTION = 'booking_protection';
export const TYPE_TOUR_V2_OPTIONAL_EXPERIENCE = 'tour_optional_experience';
export const TYPE_TRANSFER = 'transfer';
export const TYPE_BUSINESS_CREDIT = 'business_credit';

export const ITEM_TYPE_ACCOMMODATION_ITEMS = 'items';
export const ITEM_TYPE_FLIGHT_ITEMS = 'flight_items';
export const ITEM_TYPE_OFFLINE_FLIGHT_ITEMS = 'offline_flight_items';
export const ITEM_TYPE_INSURANCE_ITEMS = 'insurance_items';
export const ITEM_TYPE_GIFT_CARD_ITEMS = 'gift_card_items';
export const ITEM_TYPE_ADDON_ITEMS = 'addon_items';
export const ITEM_TYPE_EXPERIENCE_ITEMS = 'experience_items';
export const ITEM_TYPE_BEDBANK_ITEMS = 'bedbank_items';
export const ITEM_TYPE_TOUR_V2 = 'tour_items';
export const ITEM_TYPE_TOUR_V2_OPTIONAL_EXPERIENCE_ITEMS = 'tour_optional_experience_items';
export const ITEM_TYPE_SERVICE_FEE = 'service_fee_items';
export const ITEM_TYPE_CRUISE_ITEMS = 'cruise_items';
export const ITEM_TYPE_CAR_HIRE_ITEMS = 'car_hire_items';
export const ITEM_TYPE_BUSINESS_CREDIT_ITEMS = 'business_credit_items';
export const ITEM_TYPE_CUSTOM_OFFER_ITEMS = 'custom_offer_items';
export const ITEM_TYPE_SUBSCRIPTION_ITEMS = 'subscription_items';
export const ITEM_TYPE_BOOKING_PROTECTION_ITEMS = 'booking_protection_items';

export const ITEM_TYPES = [
  ITEM_TYPE_ACCOMMODATION_ITEMS,
  ITEM_TYPE_FLIGHT_ITEMS,
  ITEM_TYPE_INSURANCE_ITEMS,
  ITEM_TYPE_GIFT_CARD_ITEMS,
  ITEM_TYPE_ADDON_ITEMS,
  ITEM_TYPE_EXPERIENCE_ITEMS,
  ITEM_TYPE_BEDBANK_ITEMS,
  ITEM_TYPE_OFFLINE_FLIGHT_ITEMS,
  ITEM_TYPE_TOUR_V2,
  ITEM_TYPE_SERVICE_FEE,
  ITEM_TYPE_CRUISE_ITEMS,
  ITEM_TYPE_CAR_HIRE_ITEMS,
  ITEM_TYPE_BUSINESS_CREDIT_ITEMS,
  ITEM_TYPE_SUBSCRIPTION_ITEMS,
  ITEM_TYPE_CUSTOM_OFFER_ITEMS,
  ITEM_TYPE_BOOKING_PROTECTION_ITEMS,
  ITEM_TYPE_TOUR_V2_OPTIONAL_EXPERIENCE_ITEMS,
] as const;

export function getAllItemsMap(order: App.Order) {
  const allItems = {};

  ITEM_TYPES.forEach((itemType) => {
    if (order[itemType]) {
      order[itemType].forEach((item) => {
        if (item.id) {
          allItems[item.id] = item;
        }
      });
    }
  });

  return allItems;
}

export function getItemDescription(item: App.AnyItem, itemType: string): string {
  switch (itemType) {
    case TYPE_ACCOMMODATION:
    case ITEM_TYPE_ACCOMMODATION_ITEMS:
      return item.offer_package.name;
    case TYPE_ADDON:
      return item.name;
    case TYPE_CUSTOM_OFFER:
      return item.custom_offer.name;
    case TYPE_CAR_HIRE:
      return `${item.reservation?.vehicle.model}: ${item.reservation?.pickUp.locationName} - ${item.reservation?.return.locationName}`;
    case TYPE_CRUISE:
      return item.offer_name;
    case TYPE_EXPERIENCE:
      return item.title;
    case TYPE_BEDBANK:
      return item.offer.room.name;
    case TYPE_INSURANCE:
      return item.product_name;
    case TYPE_SUBSCRIPTION:
      return item.sub_type === 'joining_fee' ? 'Lux Plus Joining Fee' : 'Lux Plus Subscription';
    case TYPE_TOUR:
    case TYPE_TOUR_V2:
      return item.tour_name;
    case TYPE_SERVICE_FEE:
      return 'Service Fee Item';
    case TYPE_OFFLINE_FLIGHT:
      return 'Offline Flight';
    case TYPE_BUSINESS_CREDIT:
      return 'Business Credit';
    case TYPE_FLIGHT:
      return `${item.journey.fareType} Flight`;
    default:
      return itemType;
  }
}

export function getBookingNumber(item: App.AnyItem): string {
  switch (item.type) {
    case TYPE_OFFLINE_FLIGHT:
      return item.pnr_ids.join(',');
    case TYPE_FLIGHT:
      return item.pnr_id;
    case TYPE_INSURANCE:
      return item.contract_number;
    case TYPE_CAR_HIRE:
      return item?.reservation?.reference?.id;
    default:
      if ('fk_custom_offer_id' in item) {
        // custom offers have no booking number
        return null;
      }
      return item?.booking_number;
  }
}

export function itemTypeToDataType(itemType: string) {
  switch (itemType) {
    case TYPE_ACCOMMODATION:
      return ITEM_TYPE_ACCOMMODATION_ITEMS;
    case TYPE_FLIGHT:
      return ITEM_TYPE_FLIGHT_ITEMS;
    case TYPE_BEDBANK:
      return ITEM_TYPE_BEDBANK_ITEMS;
    case TYPE_TRANSFER:
    case TYPE_EXPERIENCE:
      return ITEM_TYPE_EXPERIENCE_ITEMS;
    case TYPE_ADDON:
      return ITEM_TYPE_ADDON_ITEMS;
    case TYPE_INSURANCE:
      return ITEM_TYPE_INSURANCE_ITEMS;
    case TYPE_SUBSCRIPTION:
      return ITEM_TYPE_SUBSCRIPTION_ITEMS;
    case TYPE_TOUR:
    case TYPE_TOUR_V2:
      return ITEM_TYPE_TOUR_V2;
    case TYPE_SERVICE_FEE:
      return ITEM_TYPE_SERVICE_FEE;
    case TYPE_OFFLINE_FLIGHT:
      return ITEM_TYPE_OFFLINE_FLIGHT_ITEMS;
    case TYPE_BUSINESS_CREDIT:
      return ITEM_TYPE_BUSINESS_CREDIT_ITEMS;
    case TYPE_CRUISE:
      return ITEM_TYPE_CRUISE_ITEMS;
    case TYPE_CAR_HIRE:
      return ITEM_TYPE_CAR_HIRE_ITEMS;
    case TYPE_CUSTOM_OFFER:
      return ITEM_TYPE_CUSTOM_OFFER_ITEMS;
    default:
      console.error('Could not map', itemType);
      return null;
  }
}
