export type SurchargeSuggestionDB = {
  id: string;
  offer_id: string;
  vendor_id: string;
  property_id: string;
  room_type_id: string;
  room_rate_id: string;
  offer_name: string;
  property_name: string;
  room_type_name: string;
  start_date: string;
  end_date: string;
  before_price: number;
  after_price: number;
  booked: number;
  allocated: number;
  created_at?: string;

  actioned_start_date: string;
  actioned_end_date: string;
  actioned_surcharge: number;
  status: 'accepted' | 'rejected';
  note?: string;
  score?: number;
};

export type SuggestionType = 'increase' | 'remove';

export type SurchargeSuggestionBQ = SurchargeSuggestionDB & {
  days_passed: number;
  days_remaining: number;
  sales_last_3_days: number;
  vendor_surcharge_total: number;
  internal_surcharge_total: number;
  nightly_price_aud: number;
  multiplier: number;
  suggestion_type: SuggestionType;
};

export type LerePaginatedResponse<T> = {
  result: Array<T>;
  timestamp?: number;
  total: number;
};

export type LereTabComponentProps = {
  onLereError: (error: Error | unknown) => void;
};

export type SurchargeSuggestionFilters = {
  aggregate?: boolean;
  offerIdEquals?: string;
  propertyNameLike?: string;
  roomTypeNameLike?: string;
  internalSurchargeEquals?: number;
  salesGte?: number;
  allocatedGte?: number;
  suggestionType?: SuggestionType;
  scoreGte?: number;
  scoreLte?: number;
};

export enum ConfidenceScoreTier {
  ANY = 'ANY',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export type LereAbandonedCarts = {
  result: Array<LereAbandonedCart>;
};

export interface LereAbandonedCart {
  cartId?: string;
  regionCode?: string;
  currencyCode?: string;
  items?: Array<LereAbandonedCartItem>;
  offer_id?: string;
  offer_type?: string;
  creation_time?: number;
  total?: number;
  suggestionType?: SuggestionType;
}

export interface LereAbandonedCartItem {
  offerId?: string;
  checkIn?: string;
  checkOut?: string;
  duration?: number;
  occupancy?: {
    adults: number;
    children: number;
    childrenAge: Array<number>;
  };
}
