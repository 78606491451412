import React, { useState } from 'react';

import { useSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import { Box, Button, CircularProgress, IconButton, Paper, Typography } from '@mui/material';

import SearchService from '~/services/SearchService';

import RestoreDialog from './RestoreDialog';
import { RestoreButtonProps } from './types';

function RestoreButton({ restoreType, title, description, timeRange }: RestoreButtonProps) {
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClick = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleConfirm = async () => {
    setDialogOpen(false);
    setLoading(true);

    try {
      await SearchService.restoreSearch(restoreType);

      enqueueSnackbar(`Restore started for ${title}`, {
        variant: 'success',
        persist: true,
        action: (key) => (
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackbar(key)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    } catch (err) {
      enqueueSnackbar(`Failed to restore ${title}`, {
        variant: 'error',
        autoHideDuration: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Paper
        elevation={2}
        sx={{
          p: 2,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderLeft: '3px solid',
          borderColor: 'info.main',
          '&:hover': {
            elevation: 3,
            backgroundColor: 'action.hover',
          },
        }}
      >
        <Typography variant="subtitle1" gutterBottom fontWeight="medium">
          {title}
        </Typography>
        <Box component="ul" sx={{ m: 0, pl: 2, mb: 1.5, flexGrow: 1 }}>
          {description.map((point, index) => (
            <Typography
              component="li"
              variant="body2"
              key={index}
              sx={{
                fontSize: '0.875rem',
                '&:not(:last-child)': { mb: 0.5 },
              }}
            >
              {point}
            </Typography>
          ))}
        </Box>

        <Button
          variant="outlined"
          color="info"
          onClick={handleClick}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={16} color="inherit" /> : <UpdateIcon />}
          size="small"
          fullWidth
        >
          {loading ? 'Restoring...' : 'Restore'}
        </Button>
      </Paper>

      <RestoreDialog
        open={dialogOpen}
        restoreType={restoreType}
        timeRange={timeRange}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </>
  );
}

export default RestoreButton;
