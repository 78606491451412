import React, { useCallback, useMemo, useReducer } from 'react';

import clsx from 'clsx';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import ImageUploadField from '~/components/Common/Forms/ImageUploadField';
import MarkdownEditor from '~/components/Common/Forms/widgets/MarkdownEditor';

import { allRegions } from '~/consts/allRegions';
import { brands } from '~/consts/brands';

import { toDayJs } from '~/services/TimeService';

const HeroCard = styled(Card)`
  &.error {
    border-color: red;
  }
`;

const dealTypes: Array<{ id: string; label: string; value: string }> = [
  {
    id: '1',
    label: 'Airline',

    value: 'airline',
  },
  {
    id: '2',
    label: 'Destination',
    value: 'destination',
  },
  {
    id: '3',
    label: 'Departure Aiport',
    value: 'departure_airport',
  },
];

export interface FormState {
  formId: string;
  title: string;
  type: string;
  header: string;
  region: string;
  brand: Array<string>;
  startDate: string;
  endDate: string;
  terms: string;
  description: string;
  heroImage: string | undefined;
  formErrors: Partial<Record<FormKeys, string>>;
  airlineCode: string;
}

export const initialState: FormState = {
  formId: uuid(),
  title: '',
  type: 'airline',
  header: '',
  region: 'AU',
  brand: ['luxuryescapes'],
  startDate: new Date(Date.now()).toISOString(),
  endDate: new Date(Date.now()).toISOString(),
  terms: '',
  description: '',
  heroImage: undefined,
  formErrors: {},
  airlineCode: '',
};

export enum FormKeys {
  TITLE = 'title',
  TYPE = 'type',
  HEADER = 'header',
  REGION = 'region',
  BRAND = 'brand',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  TERMS = 'terms',
  DESCRIPTION = 'description',
  HERO_IMAGE = 'heroImage',
  AIRLINE_CODE = 'airlineCode',
}

export enum ActionTypes {
  CHANGE_VALUE = 'changeValue',
  SET_ERRORS = 'setErrors',
  CLEAR_ERRORS = 'clearErrors',
  SET_LOADING = 'setLoading',
  SET_GLOBAL_ERROR = 'setGlobalError',
  CLEAR_FORM_DATA = 'clearFormData',
}

type ChangeValueAction = {
  type: ActionTypes.CHANGE_VALUE;
  payload: {
    value: string | Array<string> | boolean;
    key: FormKeys;
  };
};

type SetErrorAction = {
  type: ActionTypes.SET_ERRORS;
  payload: Partial<Record<FormKeys, string>>;
};

type ClearErrorAction = {
  type: ActionTypes.CLEAR_ERRORS;
};

type ClearFormDataAction = {
  type: ActionTypes.CLEAR_FORM_DATA;
};

export type FormActions = ChangeValueAction | SetErrorAction | ClearErrorAction | ClearFormDataAction;

export function formReducer(state: FormState, action: FormActions) {
  switch (action.type) {
    case ActionTypes.CHANGE_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
        formErrors: {
          ...state.formErrors,
          ...(action.payload.value !== state[action.payload.key] && { [action.payload.key]: undefined }),
        },
      };

    case ActionTypes.SET_ERRORS:
      return {
        ...state,
        formErrors: action.payload,
      };

    case ActionTypes.CLEAR_ERRORS:
      return {
        ...state,
        formErrors: {},
      };

    case ActionTypes.CLEAR_FORM_DATA:
      return {
        ...initialState,
        formId: uuid(),
      };

    default:
      return state;
  }
}

function changeValue(key: FormKeys, value: string | Array<string> | boolean): ChangeValueAction {
  return {
    type: ActionTypes.CHANGE_VALUE,
    payload: {
      key,
      value,
    },
  };
}

function setErrors(errors: Partial<Record<FormKeys, string>>): SetErrorAction {
  return {
    type: ActionTypes.SET_ERRORS,
    payload: errors,
  };
}

function clearErrors(): ClearErrorAction {
  return {
    type: ActionTypes.CLEAR_ERRORS,
  };
}

function validateFormState(formState: FormState): {
  errors: Partial<Record<FormKeys, string>>;
  isValid: boolean;
} {
  const errors: Partial<Record<FormKeys, string>> = {};
  let isValid = true;

  if (!formState.description.trim()) {
    isValid = false;
    errors[FormKeys.DESCRIPTION] = 'Description is required';
  }

  if (!formState.terms.trim()) {
    isValid = false;
    errors[FormKeys.TERMS] = 'Terms & Conditions are required';
  }

  if (!formState.heroImage) {
    isValid = false;
    errors[FormKeys.HERO_IMAGE] = 'Hero image is required';
  }

  return {
    isValid,
    errors,
  };
}

export type OnSaveFormData = Omit<FormState, 'formErrors' | 'formId'>;

interface Props {
  onSubmit: (data: FormState) => void;
  loading?: boolean;
  error?: string;
  initialValues?: OnSaveFormData;
}

export function FlightsCampaignsForm({ onSubmit, loading, error, initialValues }: Props) {
  const [formState, dispatch] = useReducer(formReducer, initialState, () => ({ ...initialState, ...initialValues }));

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      dispatch(clearErrors());

      const { isValid, errors } = validateFormState(formState);

      if (!isValid) {
        dispatch(setErrors(errors));
        return;
      }

      onSubmit(formState);
    },
    [formState, onSubmit],
  );

  const startDate = useMemo(() => {
    return toDayJs(formState.startDate);
  }, [formState.startDate]);

  const endDate = useMemo(() => {
    return toDayJs(formState.endDate);
  }, [formState.endDate]);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4} alignItems="end">
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                id="title"
                label="Title"
                variant="outlined"
                value={formState.title}
                onChange={(event) => dispatch(changeValue(FormKeys.TITLE, event.target.value))}
                required
                fullWidth
              />
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel htmlFor="type">Type</InputLabel>

              <Select
                labelId="type"
                id="type"
                value={formState.type}
                label="Type"
                required
                onChange={(event) => dispatch(changeValue(FormKeys.TYPE, event.target.value))}
              >
                {dealTypes.map((deal) => (
                  <MenuItem key={deal.id} value={deal.value}>
                    {deal.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                id="header"
                label="Header"
                variant="outlined"
                value={formState.header}
                onChange={(event) => dispatch(changeValue(FormKeys.HEADER, event.target.value))}
                required
                fullWidth
              />
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel htmlFor="region">Region</InputLabel>

              <Select
                labelId="region"
                id="region"
                value={formState.region}
                label="Region"
                onChange={(event) => dispatch(changeValue(FormKeys.REGION, event.target.value))}
                required
              >
                {allRegions.map((region) => (
                  <MenuItem key={region.code} value={region.code}>
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <DateTimePicker
                label="Start date *"
                value={startDate}
                onChange={(value) => dispatch(changeValue(FormKeys.START_DATE, value.toISOString()))}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <DateTimePicker
                label="End date *"
                value={endDate}
                onChange={(value) => dispatch(changeValue(FormKeys.END_DATE, value.toISOString()))}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="brand">Brand</InputLabel>

              <Select
                labelId="brand"
                id="brand"
                multiple
                value={formState.brand}
                onChange={(event) => dispatch(changeValue(FormKeys.BRAND, event.target.value))}
                input={<OutlinedInput label="Name" />}
                required
              >
                {brands.map((brand) => (
                  <MenuItem key={brand.value} value={brand.value}>
                    {brand.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                id="airlineCode"
                label="Airline Code"
                variant="outlined"
                value={formState.airlineCode}
                onChange={(event) => dispatch(changeValue(FormKeys.AIRLINE_CODE, event.target.value))}
                InputProps={{ inputProps: { maxlength: 2 } }}
                fullWidth
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography fontWeight={500}>Hero Image</Typography>

              <HeroCard
                variant="outlined"
                className={clsx({
                  error: formState.formErrors.heroImage,
                })}
              >
                <CardContent>
                  <ImageUploadField
                    label="Hero Image"
                    value={formState.heroImage}
                    multiple={false}
                    onUpload={(_, newValue) => dispatch(changeValue(FormKeys.HERO_IMAGE, newValue))}
                  />
                </CardContent>
              </HeroCard>

              {formState.formErrors.heroImage && <Typography color="red">{formState.formErrors.heroImage}</Typography>}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography fontWeight={500}>Description</Typography>

              <MarkdownEditor
                key={`${formState.formId}-desc`}
                value={formState.description}
                onChange={(value) => dispatch(changeValue(FormKeys.DESCRIPTION, value))}
                error={formState.formErrors.description}
              />

              {formState.formErrors.description && (
                <Typography color="red">{formState.formErrors.description}</Typography>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography fontWeight={500}>Terms & Conditions</Typography>

              <MarkdownEditor
                key={`${formState.formId}-terms`}
                value={formState.terms}
                onChange={(value) => dispatch(changeValue(FormKeys.TERMS, value))}
                error={formState.formErrors.terms}
              />

              {formState.formErrors.terms && <Typography color="red">{formState.formErrors.terms}</Typography>}
            </Stack>
          </Grid>

          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}

          <Grid item xs={12}>
            <Button type="submit" variant="contained" disabled={loading}>
              {loading ? 'Loading...' : 'Submit'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
