import React from 'react';

import { theme } from '~/theme';

import FastfoodIcon from '@mui/icons-material/Fastfood';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import HotelIcon from '@mui/icons-material/Hotel';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import { Chip, Icon, Typography } from '@mui/material';

import { Rate } from '~/services/AccommodationService';

type BoardCode = Rate['boardCode'];

interface MealPlanChipProps {
  boardCode: BoardCode;
  openModal: (title: string, content: React.ReactNode) => void;
}

const BOARDCODE_DETAILS: Record<BoardCode, { icon: typeof Icon; label: string; description: string }> = {
  RO: {
    icon: HotelIcon,
    label: 'Room Only',
    description: 'No meals included. Guests are responsible for their own meals.',
  },
  BB: {
    icon: FreeBreakfastIcon,
    label: 'Bed & Breakfast',
    description: 'Includes breakfast each morning of your stay.',
  },
  HB: {
    icon: RestaurantIcon,
    label: 'Half Board',
    description: 'Includes breakfast and dinner each day of your stay.',
  },
  FB: {
    icon: FastfoodIcon,
    label: 'Full Board',
    description: 'Includes breakfast, lunch, and dinner each day of your stay.',
  },
  AI: {
    icon: LocalBarIcon,
    label: 'All Inclusive',
    description: 'Includes all meals, snacks, and selected beverages throughout your stay.',
  },
};

const DEFAULT_DETAILS = {
  icon: RoomServiceIcon,
  label: 'Meal Plan',
  description: 'Specific meal plan details not available.',
};

export default function MealPlanChip({ boardCode, openModal }: MealPlanChipProps) {
  const boardCodeDetails = BOARDCODE_DETAILS[boardCode] ?? DEFAULT_DETAILS;

  const handleMealPlanClick = () => {
    const content = (
      <Typography>
        <Typography component="span" fontWeight="bold">
          {boardCodeDetails.label}
        </Typography>{' '}
        - {boardCodeDetails.description}
      </Typography>
    );

    openModal('Meal Plan Details', content);
  };

  return (
    <Chip
      icon={React.createElement(boardCodeDetails.icon)}
      label={boardCodeDetails.label}
      sx={{
        bgcolor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        fontWeight: 'bold',
        '& .MuiChip-icon': { color: theme.palette.primary.contrastText, marginRight: '4px' },
      }}
      size="small"
      onClick={handleMealPlanClick}
    />
  );
}
