import React, { useCallback, useEffect, useState } from 'react';

import TypeaheadSearchForm from '~/components/Common/Forms/TypeaheadSearchForm';

import SearchService, { TypeaheadSearchResult } from '~/services/SearchService';

const MINIMUM_SEARCH_STRING = 2;

interface Props {
  types: string[];
  label: string;
  required?: boolean;
  initialValue?: string;
  onCurrentValueChange: (value: TypeaheadSearchResult | undefined) => void;
  onItemSelected?: (selection: TypeaheadSearchResult) => void;
}

const PlaceLookUp = ({ types, label, onCurrentValueChange, required = true, initialValue }: Props) => {
  const [value, setValue] = useState<string>(initialValue || '');
  const [open, setOpen] = useState<boolean>(false);
  const [results, setResults] = useState<TypeaheadSearchResult[]>([]);
  const priority = 'current';

  const search = useCallback(async () => {
    if (value.length < MINIMUM_SEARCH_STRING) {
      return;
    }

    if (types.length === 0) {
      return;
    }

    const res = await SearchService.getTypeahead(value, {
      type: types,
      brand: 'luxuryescapes',
      region: 'AU',
      limit: 20,
      priority,
    });

    setResults(res.result);
  }, [value, types, priority]);

  useEffect(() => {
    const t = setTimeout(search, 500);

    return () => {
      clearTimeout(t);
    };
  }, [search]);

  const onSelect = async (selection: TypeaheadSearchResult) => {
    onCurrentValueChange(selection);
  };

  return (
    <>
      <TypeaheadSearchForm
        setValue={setValue}
        value={value}
        setOpen={setOpen}
        open={open}
        onSelect={onSelect}
        results={results}
        label={label}
        required={required}
        onCurrentValueChange={onCurrentValueChange}
      />
    </>
  );
};

export default PlaceLookUp;
