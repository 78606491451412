export const TYPEAHEAD_PLACE_TYPES = [
  'airport',
  'province_state',
  'neighborhood',
  'city',
  'high_level_region',
  'country',
  'multi_city_vicinity',
  'metro_station',
  'continent',
  'train_station',
  'point_of_interest',
  'colloquial_area',
];

export const TYPEAHEAD_PROPERTY_TYPES = [
  'le_property',
  'le_property_unique_stays',
  'bedbank_property',
  'bedbank_property_unique_stays',
];

export const TYPEAHEAD_COUNTRY_TYPES_COUNTRY = ['country'];

export const TYPEAHEAD_COUNTRY_TYPES_AIRPORT = ['airport'];

export const TYPEAHEAD_COUNTRY_TYPES_REGION = [
  'airport',
  'province_state',
  'neighborhood',
  'city',
  'high_level_region',
  'multi_city_vicinity',
  'metro_station',
  'continent',
  'train_station',
  'point_of_interest',
  'colloquial_area',
];
