import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import fileDownload from 'react-file-download';
import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import {
  AccessTime as AccessTimeIcon,
  AttachMoney as AttachMoneyIcon,
  BedtimeOutlined as BedtimeOutlinedIcon,
  DateRange as DataRangeIcon,
  Delete as DeleteIcon,
  Language as LanguageIcon,
  SignalCellularAlt as SignalCellularAltIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { COUNTRIES } from '~/consts/priceParrot';

import PriceParrotService from '~/services/PriceParrotService';
import { formatDateSlashesWithClock } from '~/services/TimeService';

import {
  ScheduleInterval,
  ScheduledPriceSummaryJob,
  ScheduledPriceSummaryJobRun,
  ScheduledPriceSummaryJobStatus,
  ScheduledPriceSummaryJobVariation,
} from '~/types/services/priceParrot';

import getAllCurrencyCodes from '~/utils/getAllCurrencyCodes';
import { errorToString } from '~/utils/stringUtils';

import PageHeader from '../Common/Elements/PageHeader';
import Spinner from '../Common/Spinner';

import GridColActions from './PriceParrotShopper/GridColActions';
import GridColStatus from './PriceParrotShopper/GridColStatus';

type FormInput = {
  numberOfNights: number;
  offsetDays: number;
  totalDays: number;
  currencyCode: string;
  countryCode: string;
};

const SNACKBAR_STYLE = { maxWidth: '500px' };

export default function ScheduledPriceSummaryJobDetails() {
  const { jobId } = useParams<{ jobId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [job, setJob] = useState<ScheduledPriceSummaryJob | undefined>(undefined);
  const [jobRuns, setJobRuns] = useState<Array<ScheduledPriceSummaryJobRun>>([]);
  const scheduledJobVariationColumns: Array<GridColDef<ScheduledPriceSummaryJobVariation>> = [
    { field: 'id', headerName: 'Variation ID', flex: 1, display: 'flex' },
    { field: 'numberOfNights', headerName: 'No. of Nights', flex: 1, display: 'flex' },
    { field: 'offsetDays', headerName: 'Offset Days', flex: 1, display: 'flex' },
    { field: 'totalDays', headerName: 'Total Days', flex: 1, display: 'flex' },
    { field: 'currencyCode', headerName: 'Currency', flex: 1, display: 'flex' },
    { field: 'countryCode', headerName: 'Country', flex: 1, display: 'flex' },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      display: 'flex',
      valueFormatter: formatDateSlashesWithClock,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      display: 'flex',
      renderCell: ({ row: { id: variationId } }) => (
        <Stack direction="row">
          <Tooltip title="Delete Variation">
            <IconButton onClick={async () => await handleDeleteJobVariation(variationId)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];
  const scheduledJobRunColumns: Array<GridColDef<ScheduledPriceSummaryJobRun>> = [
    { field: 'jobId', headerName: 'Job ID', flex: 2, display: 'flex' },
    {
      field: 'variation.id',
      headerName: 'Variation ID',
      flex: 2,
      display: 'flex',
      valueGetter: (_, row) => row.variation.id,
    },
    { field: 'errorMessage', headerName: 'Error Message', flex: 4, display: 'flex' },
    {
      field: 'status',
      headerName: 'Status',
      flex: 2,
      display: 'flex',
      renderCell: ({ row: { status, progress, days, updatedAt, retries } }) => (
        <GridColStatus status={status} progress={progress} days={days} updatedAt={updatedAt} retries={retries} />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 2,
      display: 'flex',
      valueFormatter: formatDateSlashesWithClock,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      display: 'flex',
      renderCell: ({ row }) => (
        <GridColActions jobStatus={row.status} onDownload={() => handleDownloadJobRun(+jobId, row.jobId)} />
      ),
    },
  ];
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting, isValid },
  } = useForm<FormInput>({
    defaultValues: {
      numberOfNights: 3,
      offsetDays: 1,
      totalDays: 365,
      currencyCode: 'AUD',
      countryCode: 'AU',
    },
  });

  const title = job?.property.name ? `${job.property.name} Job` : `Scheduled Price Summary Job ${jobId}`;

  const handleDownloadJobRun = async (jobId: number, runId: number): Promise<void> => {
    try {
      const response = await PriceParrotService.downloadScheduledPriceSummaryJobRun(jobId, runId);
      const contentDisposition: string | null = response.headers.get('Content-Disposition');

      const blob = await response.blob();
      const fileName = contentDisposition?.match(/filename="?([^";]+)"?/)?.[1] ?? `price-parrot-${jobId}-${runId}.csv`;

      fileDownload(blob, fileName);
    } catch (error) {
      enqueueSnackbar('Failed to download job run', { variant: 'error' });
      console.error(error);
    }
  };
  const handleUpdateJobStatus = async (newStatus: ScheduledPriceSummaryJobStatus): Promise<void> => {
    try {
      await PriceParrotService.patchScheduledPriceSummaryJob(+jobId, {
        scheduleInterval: job.scheduleInterval,
        status: newStatus,
      });
      setJob((prev) => ({ ...prev, status: newStatus }));
      enqueueSnackbar('Job status updated', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to update job schedule interval', { variant: 'error' });
      console.error(error);
    }
  };

  const handleUpdateJobScheduleInterval = async (newScheduleInterval: ScheduleInterval): Promise<void> => {
    try {
      await PriceParrotService.patchScheduledPriceSummaryJob(+jobId, {
        scheduleInterval: newScheduleInterval,
        status: job.status,
      });
      setJob((prev) => ({ ...prev, scheduleInterval: newScheduleInterval }));
      enqueueSnackbar('Job schedule interval updated', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to update job schedule interval', { variant: 'error' });
      console.error(error);
    }
  };

  const handleDeleteJobVariation = async (variationId: number): Promise<void> => {
    try {
      await PriceParrotService.deleteScheduledPriceSummaryJobVariation(+jobId, variationId);
      setJob((prev) => ({ ...prev, variations: prev.variations.filter((v) => v.id !== variationId) }));
      enqueueSnackbar('Job variant deleted', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to delete job variant', { variant: 'error' });
      console.error(error);
    }
  };

  const handleCreateJobVariation = async (data: FormInput): Promise<void> => {
    try {
      const { data: variation } = await PriceParrotService.postScheduledPriceSummaryJobVariation(+jobId, data);
      setJob((prev) => ({ ...prev, variations: [variation, ...prev.variations] }));
      enqueueSnackbar('Job variation created', { variant: 'success' });
      reset();
    } catch (e) {
      enqueueSnackbar(`Failed to create variation. Reason: ${errorToString(e)}`, {
        variant: 'error',
        style: SNACKBAR_STYLE,
      });
      console.error(e);
    }
  };

  useEffect(() => {
    if (jobId) {
      PriceParrotService.getScheduledPriceSummaryJob(jobId)
        .then(({ data }) => setJob(data))
        .catch(console.error);
      PriceParrotService.getScheduledPriceSummaryJobRuns(jobId)
        .then(({ data }) => setJobRuns(data))
        .catch(console.error);
    }
  }, [jobId]);

  return job ? (
    <Container maxWidth="xl">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageHeader title={title} />

      <Stack rowGap={3}>
        {/* Start Overview Section */}
        <Stack direction="column" rowGap={1}>
          <Typography variant="h4">Overview</Typography>
          <Grid container spacing={3}>
            <Grid item md={3}>
              <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                Property
              </Typography>
              <Link variant="body1" href={job.property.url} target="_blank" rel="noopener noreferrer">
                {job.property.name ?? job.property.url}
              </Link>
            </Grid>
            <Grid item md={3}>
              <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                Schedule Interval
              </Typography>
              <TextField
                select
                fullWidth
                label="Schedule Interval"
                defaultValue={job.scheduleInterval}
                onChange={async (e) => await handleUpdateJobScheduleInterval(e.target.value as ScheduleInterval)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccessTimeIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: false,
                  style: { display: 'none' },
                }}
              >
                {Object.values(ScheduleInterval)
                  .filter((i: ScheduleInterval) => i !== ScheduleInterval.ADHOC)
                  .map((interval: ScheduleInterval) => (
                    <MenuItem key={interval} value={interval}>
                      {interval}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item md={3}>
              <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                Status
              </Typography>
              <TextField
                select
                fullWidth
                label="Status"
                defaultValue={job.status}
                onChange={async (e) => await handleUpdateJobStatus(e.target.value as ScheduledPriceSummaryJobStatus)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SignalCellularAltIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: false,
                  style: { display: 'none' },
                }}
              >
                {Object.values(ScheduledPriceSummaryJobStatus).map((status: ScheduledPriceSummaryJobStatus) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={3}>
              <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                Created At
              </Typography>
              <Typography variant="body1">{formatDateSlashesWithClock(job.createdAt)}</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                Next Run At
              </Typography>
              <Typography variant="body1">{formatDateSlashesWithClock(job.nextRunAt)}</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                Updated At
              </Typography>
              <Typography variant="body1">{formatDateSlashesWithClock(job.updatedAt)}</Typography>
            </Grid>
          </Grid>
        </Stack>
        {/* End Overview Section */}

        {/* Start Variations Section */}
        <Stack direction="column" rowGap={1}>
          <Typography variant="h4">Variations</Typography>

          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item xs={6} md={2}>
              <Controller
                name="numberOfNights"
                rules={{
                  required: 'Number of Nights is required',
                  min: { value: 1, message: 'Value must be positive' },
                }}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(e) => field.onChange(+e.target.value)}
                    fullWidth
                    label="No. of Nights"
                    type="number"
                    disabled={isSubmitting}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <BedtimeOutlinedIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <Controller
                name="offsetDays"
                rules={{ required: 'offsetDays is required', min: { value: 0, message: 'Value must be positive' } }}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(e) => field.onChange(+e.target.value)}
                    fullWidth
                    label="Offset Days"
                    type="number"
                    disabled={isSubmitting}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DataRangeIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <Controller
                name="totalDays"
                rules={{ required: 'totalDays is required', min: { value: 1, message: 'Value must be positive' } }}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(e) => field.onChange(+e.target.value)}
                    fullWidth
                    label="Total Days"
                    type="number"
                    disabled={isSubmitting}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DataRangeIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <Controller
                name="currencyCode"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(e) => {
                      // i.e. AUD -> AU
                      const matchedCountry: string | undefined = COUNTRIES.find(
                        (c) => c.code === e.target.value.substring(0, 2),
                      )?.code;
                      matchedCountry && setValue('countryCode', matchedCountry);
                      field.onChange(e.target.value);
                    }}
                    select
                    fullWidth
                    label="Currency"
                    disabled={isSubmitting}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoneyIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  >
                    {getAllCurrencyCodes().map((currenyCode: string) => (
                      <MenuItem key={currenyCode} value={currenyCode}>
                        {currenyCode}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <Controller
                name="countryCode"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    label="Country"
                    disabled={isSubmitting}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LanguageIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  >
                    {COUNTRIES.map(({ name, code }) => (
                      <MenuItem key={code} value={code}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={2} display="flex" alignItems="center">
              <Button
                variant="contained"
                type="submit"
                disabled={!isValid || isSubmitting}
                onClick={handleSubmit(handleCreateJobVariation)}
              >
                Submit
              </Button>
            </Grid>
          </Grid>

          <DataGrid
            autoHeight
            columns={scheduledJobVariationColumns}
            rows={job.variations}
            getRowHeight={() => 'auto'}
            disableRowSelectionOnClick
          />
        </Stack>
        {/* End Variations Section */}

        {/* Start Job Runs Section */}
        <Stack direction="column" rowGap={1}>
          <Typography variant="h4">Job Runs</Typography>
          <DataGrid
            autoHeight
            columns={scheduledJobRunColumns}
            rows={jobRuns}
            getRowId={({ jobId }) => jobId}
            getRowHeight={() => 'auto'}
            disableRowSelectionOnClick
          />
        </Stack>
        {/* End Job Runs Section */}
      </Stack>
    </Container>
  ) : (
    <Box sx={{ height: '80vh' }}>
      <Spinner />
    </Box>
  );
}
