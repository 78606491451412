import { useCallback, useState } from 'react';

import { GridPaginationModel } from '@mui/x-data-grid';

export default function useDataGridPagination(initialModel: GridPaginationModel = { page: 0, pageSize: 10 }) {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(initialModel);

  const setPage = useCallback((pageNumber: number) => {
    setPaginationModel((prevModel) => ({ ...prevModel, page: pageNumber }));
  }, []);

  return { paginationModel, setPaginationModel, setPage };
}
